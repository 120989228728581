.p-multi-account{
  padding: 13px 20px 0 20px;
  width: 360px;
  color: #666666;
  .multi{
    &__input{
      flex: 1;
      height: 38px;
      border: solid 1px #dddddd;
      margin-left: 10px;
      color: #888888;
      padding-left: 8px;
    }
    &__row{
      position: relative;
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      .Select__type{
        flex: 1;
        .Select-value-label{
          color: #666666 !important;
        }

      }
      &--img{
        width: 50px;
        height: 50px;
        margin-left: 10px;
      }
      &--select{
        flex: 1;
        margin-left: 10px;
      }
      .image-selector{
        width: 240px;
        height: 45px;
      }
      .label__row{
        width: 90px;
      }

    }
    &__code{
      position: absolute;
      right: 8px;
      top: 9px;
      cursor: pointer;
    }
    &__label{
      width: 50px;
      display: block;
    }
  }
  .modal__footer--id{
    margin-left: 5px;
  }
}
.p-multi-contacts{
  .multi{
    &__input{
      flex: 1;
      height: 38px;
      border: solid 1px #dddddd;
      margin-left: 10px;
      color: #888888;
      padding-left: 8px;
    }
    &__row{
      position: relative;
      margin-top: 20px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }
  }
}

.p-multi-accountrecharge{
  width: 460px;
  padding: 0 10px;
  color: #666666;
  font-size: 14px;
  margin-top: 27px;
  .multi__account{
    &--row{
      margin-bottom: 17px;
      display: flex;
      align-items: center;
      .pay__money--color{
      font-size: 20px;
      color: #ff7a11;
    }
      .account__input{
        margin-right: 9px;
        width: 16px;
        height: 16px;
      }
    }
    &--input{
      width: 120px;
      height: 34px;
      border: solid 1px #d2d8dd;
      padding-left: 5px;
      color: #666666;
    }
    &--small{
      color: #888888;
      font-size: 12px;
      margin-left: 15px;
    }
    &--bottom{
      border-top: solid 1px #aeb5bb;
      margin-top: 29px;
      margin-bottom: 25px;
    }
    &--bottom2{
      margin-top: 18px;
    }
    &--row2{
      margin-top: 17px;
      margin-bottom: 14px;
    }
    &--pay{
      display: flex;
      .account__bordercolor{
        border: 1px solid #ffa01e !important;
      }
    }
    &--block{
      cursor: pointer;
      width: 118px;
      height: 44px;
      border: solid 1px #d2d8dd;
      display: flex;
      align-items: center;
      padding: 0 21px;
      margin-right: 8px;
    }

  }
}
.p-multi-payconfirm{
  padding: 0 10px;
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  color: #666666;
  .payconfirm{
    &__title{
      color: #ffac3b;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon__pay{
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
    }
    &__text{
      margin: 20px 0px 25px 0px;
      &--color{
        color: #ffac3b;
      }
    }
  }
}
.p-multi-voucher{
  width: 500px;
  padding: 0 20px;
  color: #666666;
  .voucher__title{
    &--row{
      ul{
        display: flex;
        justify-content: center;
      }

    }
    &--li{
      padding: 10px 30px;
      cursor: pointer;
    }
    &--active{
      color: #299feb;
      border-bottom: 3px solid #299feb;
    }
  }
  .voucher__tab{
    &--main{
      max-height: 300px;
      height: 300px;
      overflow-y: auto;
    }
    &--row{
      display: flex;
      margin-top: 15px;
      .backcolor{
        background: #cccccc;
      }
    }
    &--left{
      width:120px;
      height: 80px;
      line-height: 80px;
      background: #40b5ff;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
    }
    &--size{
      font-size: 24px;
      font-weight: 800;
    }
    &--right{
      width: 320px;
      height: 80px;
      border: 1px solid #cccccc;
      border-left: none;
      padding: 15px 20px;
    }
    &--span{
      background: #40b5ff;
      padding: 3px 5px;
      border-radius: 20px;
      color: #ffffff;
      margin-right: 5px;
    }
    &--p{
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
    &--btn{
      border: 1px solid #40b5ff;
      border-radius: 20px;
      color: #40b5ff;
      background: #ffffff;
      margin-left: 30px;
    }
    &--btncolor{
      border: 1px solid #cccccc;
      color: #cccccc;
    }
    &--color{
      background: #cccccc;
    }
  }
}