.g-qtn {
  .ql-indent{
    &-1{
      padding-left: 3em;
      display: inline;
    }
    &-2{
      padding-left: 6em;
      display: inline;
    }
    &-3{
      padding-left: 9em;
      display: inline;
    }
    &-4{
      padding-left: 12em;
      display: inline;
    }
    &-5{
      padding-left: 15em;
      display: inline;
    }
    &-6{
      padding-left: 18em;
      display: inline;
    }
    &-7{
      padding-left: 21em;
      display: inline;
    }
    &-8{
      padding-left: 24em;
      display: inline;
    }
  }
  &.qtn {
    &__canvas {
      min-height: 300px;// bigger than dropdown
      padding: 0 20px;
      .qtn {
        &__main {
          padding-bottom: 20px;
          &--wrap {
            height: 100%;
            overflow-y: auto;
          }
          .question {
            &__item {
              margin-top: 50px;
              &--custom12651{
                .matrix__head{
                  &:first-child {
                    text-align: left;
                    padding-left: 10px;
                  }
                }
              }
              &--custom11523{
                .matrix__head{
                  font-size: 12px;
                  &:first-child {
                    div{width: 60px !important;}
                  }
                  div{
                    width: 50px !important;
                  }
                }
              }
              &--custom11529{
                .question__sub--wrap{
                  padding: 0px 0px;
                  border: none;
                }
                .question__choice{
                  display: flex;
                }
                .choice__item{
                  border-left: none !important;
                  width: 20%;
                  &:first-child {
                    border-left: 1px solid #e5e7e9 !important;
                  }
                  &--selected {
                    border: solid 1px #40b5ff;
                    background-color: #fafdff;
                    border-left: 1px solid #40b5ff !important;
                    position: relative;
                    .choice__image--layer{
                      position: absolute;
                      width: 150px;
                      height: 150px;
                      top:0px;
                      left: 0px;
                      background: #ffffff;
                      opacity: 0.4;
                    }
                    &:first-child {
                      border-left: 1px solid #40b5ff !important;
                    }
                  }
                }
              }
              &--custom12029{
                .matrix__head{
                  font-size: 12px;
                }
                .matrix__head--custom2 {
                  text-align: left !important;
                  div{
                    width: 170px !important;
                    text-align: left !important;
                  }
                }
                .matrix__head--custom3 {
                  div{
                    width: 35px !important;
                  }
                }
              }
              &--custom12087{
                .quesion__opt--width{
                  width: 64px !important;
                  max-width: 64px !important;
                }
              }
              &--mobile12087{
                .quesion__opt--width{
                  width: 25px !important;
                  max-width: 25px !important;
                }
                .matrix__head{
                  width: 25px !important;
                }
              }
              &--custom{
                .matrix__head{
                  vertical-align:bottom;
                }
                .quesion__opt--width{
                  width: 58px !important;
                  max-width: 58px !important;
                }
                .singlescale-table--moblie{
                  .quesion__item--scale2{
                    .quesion__opt--width{
                      width: 32px !important;
                      max-width: 32px !important;
                    }
                  }
                }
                tbody{
                  td{
                      .scale__opt--image{
                        background: url(assets/images/icons/score/yuan2.png) center center/contain no-repeat !important;
                        width: 29px !important;
                        height: 29px !important;
                        &.active {
                          background: url("assets/images/icons/score/yuan.png") center center /contain no-repeat !important;
                          width: 29px;
                          height: 29px;
                        }
                      }

                  }
                }
              }
              &--760{
                .choice__image{
                  width: 115px !important;
                  height: 115px !important;
                }
              }
              &--1200{
                .choice__image{
                  width: 200px !important;
                  height: 200px !important;
                }
              }
              .scale-table--view2{
                overflow-x: auto;
                overflow-y: hidden;
                .quesion__item--scale3{
                  color: #666666;
                  table{ border-collapse:collapse;}
                  td{
                    padding: 0px !important;
                  }
                  .quesion__opt{
                    &--left{
                      float: left;
                      width: 50%;
                      max-width: 50%;
                      color: #666666;
                    }
                    &--right{
                      float: right;
                      width: 50%;
                      max-width: 50%;
                      text-align: end;
                      color: #666666;
                    }
                  }

                }
                .question__item--opt6{
                  display: flex;
                  align-items: center;
                  .quesion__opt{
                    &--left{
                      width: 70px;
                      color: #666666;
                    }
                    &--right{
                      width: 70px;
                      text-align: right;
                      color: #666666;
                    }
                    &--slider{
                      flex: 1;
                    }
                  }
                }
              }
              /*打分题样式*/
              .scale-table--view{

                .question__scale--row{
                  &:not(:first-child) {
                    margin-top: 13px;
                  }
                }
                .scale{
                  &__image{
                    &--1{
                      background: url("assets/images/icons/score/score-start2.svg") center center /contain no-repeat;
                      width: 30px;
                      height: 30px;
                      &.active {
                        background: url("assets/images/icons/score/score-start.svg") center center /contain no-repeat;
                      }
                    }
                    &--2{
                      background: url("assets/images/icons/score/score-smile2.svg") center center /contain no-repeat;
                      width: 30px;
                      height: 30px;
                      &.active {
                        background: url("assets/images/icons/score/score-smile.svg") center center /contain no-repeat;
                      }
                    }
                    &--3{
                      background: url("assets/images/icons/score/score-heart2.svg") center center /contain no-repeat;
                      width: 30px;
                      height: 30px;
                      &.active {
                        background: url("assets/images/icons/score/score-heart.svg") center center /contain no-repeat;
                      }
                    }
                    &--4{
                      background: url("assets/images/icons/score/score-good2.svg") center center /contain no-repeat;
                      width: 30px;
                      height: 30px;
                      &.active {
                        background: url("assets/images/icons/score/score-good.svg") center center /contain no-repeat;
                      }
                    }
                    &--5{
                      background: url("assets/images/icons/score/score-sorry2.png") center center /contain no-repeat;
                      width: 33px;
                      height: 33px;
                      &.active {
                        background: url("assets/images/icons/score/score-sorry.png") center center /contain no-repeat;
                      }
                    }
                  }
                }
                .scale__opt--image{
                  margin: auto;
                }
                .quesion{
                  &__item{
                    &--scale-1,
                    &--scale1{
                      .quesion{
                        &__item{
                          &--top{
                            float: left;
                            margin: 0 20px;
                          }
                        }
                      }
                      .question__item--left{
                        float: left;
                        line-height: 30px;
                        max-width: 70px;
                        color: #666666;
                        width: 70px;
                        text-align: center;
                      }
                      .question__item--right{
                        float: left;
                        line-height: 30px;
                        color: #666666;
                        width: 70px;
                        max-width: 70px;
                        text-align: center;
                      }

                    }
                    &--scale2{
                      table{
                        border-collapse: collapse;
                      }
                      th{
                        width: 70px;
                        max-width:70px;
                      }
                      td{
                        padding: 0px;
                      }
                      .quesion__opt--width{
                        width: 69px;
                        max-width: 69px;
                        color: #666666;
                        text-align: center;
                      }
                      .scale__opt--image{
                        margin: auto;
                      }
                    }
                  }
                }
              }
              .scale-table--view4{
                .quesion__opt{
                  &--left{
                    width: 50%;
                    float: left;
                    text-align: left;
                  }
                  &--right{
                    width: 50%;
                    float: right;
                    text-align: right;
                  }
                }
                table{
                  border-collapse: collapse;
                  td{
                    padding: 0;
                  }
                }
                .question__scale{
                  &--row{
                    color: #666666;
                  }
                  &--title{
                    font-size: 16px;
                    margin-bottom: 5px;
                  }
                }
                overflow-x: auto;
                overflow-y: hidden;
                .matrix__head{
                  text-align: center;
                  width: 70px;
                  max-width: 70px;
                }
              }
              .scale-tabl--mobile{
                .question__scale--row{
                  .matrix__head{
                    width: 48px !important;
                    max-width: 48px !important;
                  }
                  /*.matrix__head6{
                    width: 30px !important;
                    max-width: 30px !important;
                  }*/
                  .question__scale--td{
                    width: 48px !important;
                    max-width: 48px !important;
                  }
                  /*.question__scale--td6{
                    width: 30px !important;
                    max-width: 30px !important;
                  }*/
                }
                .scale__opt--image{
                  width: 23px !important;
                  height: 23px !important;
                  margin: auto;
                }
              }
              .singlescale-table--moblie{
                .quesion__item--top{
                  margin: 0 10px !important;
                }
                .quesion{
                  &__item{
                    &--scale2{
                      overflow-x: auto;
                      .matrix__head{
                        width: 48px !important;
                        max-width: 48px !important;
                      }
                      .quesion__opt--width{
                        width: 48px;
                        max-width: 48px;
                        margin: auto;
                      }
                    }
                    &--scale1{
                      .question{
                        &__item{
                          &--left{
                            margin-right: 4px;
                            line-height: 23px;
                            width: 48px !important;
                            max-width: 48px !important;
                          }
                          &--right{
                            line-height: 23px;
                            max-width: 48px;
                          }
                        }
                      }
                    }
                  }
                }
                .scale__opt--image{
                  width: 23px !important;
                  height: 23px !important;
                }

              }
              .question__upload{
                display: flex;
                align-items: center;
                img{
                  max-width: 50%;
                  float: left;
                }

                &--delete{
                  margin-left: 10px;
                }
              }
              .image-selector{
                width: 100%;
                height: 70px;
              }
              &:first-child {
                margin-top: 18px;
              }
              .question{
                &__choice{
                  &--1,
                  &--2{
                    .question__open{
                      &--opt{
                        display: flex;
                        .question{
                          &__span{
                            &--title{
                              max-width: 30%;
                              padding: 3px 5px;
                              color: #666666;
                            }
                          }
                        }
                      }
                      &--width{
                        max-width: 100% !important;
                      }
                    }
                  }
                  &--2{
                    .question{
                      &__choice{
                        &--c{
                          display: block;
                          &:not(:first-child) {
                            margin-top: 10px;
                          }
                          .choice{
                            &__top{
                              margin-bottom: 5px;
                              color: #666666;
                            }
                          }
                        }
                      }
                      &__open{
                        &--opt{
                          display: flex;
                        }
                      }
                    }
                  }
                }
              }
            }
            &__h1 {
              line-height: 24px;
              font-size: 16px;
              color: #666666;
              word-break: break-all;
              margin-bottom: 15px;
              & .question__span--limit{
                font-size: 14px;
              }
              img {
                max-width: 100%
              }
            }
            &__opt {
              line-height: 24px;
              font-size: 14px;
              color: #666666;
              margin-top: 19px;
              .opt {
                &__label {
                  margin-left: 15px;
                }
              }
            }

            // 不同题型
            &__describe {
              line-height: 28px;
              font-size: 16px;
              color: #666666;
              & img{
                max-width: 100%;
              }
              &--img {
                text-align: center;
                img {
                  max-width: 100%;
                }
              }
            }
            &__choice {
              .choice {
                &__item {
                  background-color: #ffffff;
                  border: solid 1px #e5e7e9;
                  margin-top: 10px;
                  padding: 7px 11px 7px 15px;
                  cursor: pointer;
                  img {
                    max-width: 100%;
                  }
                  &--selected {
                    border: solid 1px #40b5ff;
                    background-color: #fafdff;
                    position: relative;
                    .choice__image--layer{
                      position: absolute;
                      width: 150px;
                      height: 150px;
                      top:0px;
                      left: 0px;
                      background: #ffffff;
                      opacity: 0.4;
                    }
                  }
                }
                &__top {
                  display: flex;
                  align-items: center;
                  min-height: 28px;
                }
                &__label {
                  flex: 1;
                  word-break: break-all;
                  color: #666666;
                }
              }

              // 多列显示
              &--c {
                .choice__item {
                  float: left;
                  clear: none;
                  margin-right: 1%;
                }
              }
              &--c2 {
                .choice__item {
                  width: 49%;
                  &:nth-child(2n+1) {
                    clear: both;
                  }
                }
              }
              &--c3 {
                .choice__item {
                  width: 32%;
                  &:nth-child(3n+1) {
                    clear: both;
                  }
                }
              }
              &--c4 {
                .choice__item {
                  width: 24%;
                  &:nth-child(4n+1) {
                    clear: both;
                  }
                }
              }

              // 图片选择题
              &--image {
                justify-content: space-between;
                .choice {
                  &__image {
                    width: 150px;
                    height: 150px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    justify-content: center;
                    position: relative;
                    &--img{
                      max-width: 150px !important;
                    }
                    &--item {
                      width: 24%;
                      margin-right: 1%;
                      float: left;
                      position: relative;
                      .image__large{
                        position: absolute;
                        bottom: 0px;
                        right: 25px;
                      }
                      .modal__content{
                        background: none !important;
                      }
                      .modal__content--open{
                        background: none !important;
                      }
                      &:nth-child(4n+1) {
                        clear: both;
                      }
                      .choice {
                        &__item {
                          margin-top: 10px;
                        }
                      }
                    }
                    & .choice__status--image{
                      width: 20px;
                      height: 20px;
                      position: absolute;
                      left: 0px;
                      right: 0px;
                      margin: auto;
                      top: 0px;
                      bottom: 0px;
                      z-index: 10;
                    }
                  }
                }
              }
              &--mobile{
                & .choice__image--item{
                  width:50%;
                  margin-right: 0px;
                  &:nth-child(2n+1) {
                    clear: both;
                    .choice__image{
                      float: left;
                    }
                    .image__large{
                      right: 5px !important;
                    }
                  }
                }
                .choice__image{
                  width: 130px;
                  height: 130px;
                  float: right;
                }
              }

              // 下拉单选题
              &--dropdown {
                margin-top: 16px;
              }

              // 读写选项 required
              &--input {
                margin-top: 10px;
              }
            }

            &__matrix {
            }
            &__sub {
              margin-top: 22px;
              &--title {
                line-height: 28px;
                color: #666666;
              }
              &--wrap {
                padding: 20px 15px;
                border: 1px solid #C3C6C9;
                margin-top: 12px;
              }
            }

            // 成功结束页
            &__over {
              padding: 109px 0 0 0;
              text-align: center;
              .over {
                &__icon {
                  margin: 0 auto;
                  width: 50px;
                  height: 50px;
                }
                &__title {
                  line-height: 37px;
                  font-size: 26px;
                  color: #666666;
                  margin-top: 24px;
                }
                &__desc {
                  margin-top: 10px;
                  font-size: 14px;
                  color: #999999;
                  img {max-width: 100%}
                  &--redpacket{
                    font-size: 20px;
                    color: red;
                    margin-top: 20px;
                  }
                }
              }
            }
          }
        }
        &__footer {
          height: 46px;
          margin-bottom: 20px;
          display: flex;
          &--hide {
            display: none;
          }

          .btn {
            height: 100%;
            flex: 1;
            padding: 0 16px;
            line-height: 46px;
            text-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-radius: 4px;
            background-color: #40b5ff;
            color: white;
            font-size: 16px;
            cursor: pointer;
            border: none;
            &:not(:first-child) {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}

.main__mobile{
  font-size: 16px;
  .question{
    &__describe{
      font-size: 18px !important;
    }
    &__h1{
      font-size: 18px !important;
    }
    &__span--limit{
      font-size: 16px !important;
    }
  }
}
.question__over {
  padding: 109px 0 0 0;
  text-align: center;
  .over {
    &__icon {
      margin: 0 auto;
      width: 50px;
      height: 50px;
    }
    &__title {
      line-height: 37px;
      font-size: 26px;
      color: #666666;
      margin-top: 24px;
    }
    &__desc {
      margin-top: 10px;
      font-size: 14px;
      color: #999999;
      img {max-width: 100%}
    }
  }
}
.redpacket__service{
  text-align: center;
  width: 100%;
  height: 100vh;
  background: #ede6d3;
  padding-top: 50px;
  &--row{
    width: 310px;
    height: 550px;
    background: #fcf7eb;
    margin: auto;
    padding-top: 20px;
    position: relative;
  }
  .icon__logo{
    margin: auto;
    padding-top: 20px;
  }
  .redpacket{
    &__title{
      color: #d9c7a7;
      font-size: 16px;
      margin-top: 15px;
    }
    &__sum{
      font-size: 54px;
      margin-top: 56px;
      color: red;
      &--span{
        font-size: 14px;
      }
    }
    &__text{
      margin-top: 10px;
      color: red;
    }
  }
  .icon__bottom{
    position: absolute;
    bottom: 0px;
    left: -7px;
  }
}
.prize__info{
  width: 360px;
  margin: auto;
  margin-top: 10px;
  color: #999999;
  text-align: left;
  color: #ffac3b;
}
@media screen and (min-width: 320px) and (max-width: 640px) {
  .prize__info{
    width: 100%;
    margin: auto;
    margin-top: 10px;
  }
  .redpacket__service{
    padding-top: 20px !important;
  }
  .redpacket__service--row{
    height: calc(100vh - 20px) !important ;
  }
  .redpacket__num{
    padding-top: 100px !important;
  }
  .question__over{
    padding: 50px 0 0 0 !important
  }
}
@media screen and(max-width: 320px) {
  .icon-redpacket4{
    width: 314px !important;
  }
  .redpacket__service--row{
    width: 295px;
  }
  .prize__info{
    width: 100%;
    margin: auto;
    margin-top: 10px;
  }
}
.color-yello{
  color: #ffac3b;
}
.question__matrix--custom{
  .matrix-table{
    &:first-child {
      border-top: 1px solid #D5D5D5;
    }
  }

  /*.matrix-table--view{
    overflow: hidden;
    overflow-x: auto;
    width: 100%;
    padding-bottom: 20px;
  }
  .matrix-table--view::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
  }
  .matrix-table--view::-webkit-scrollbar {
    width: 5px;
    height: 15px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  .matrix-table--view::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-height: 15px;
  }
  .matrix-table--view::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }*/
  /*.table__tbody {
    overflow: hidden;
    overflow-y: auto;
  }
  .table__tbody::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
  }
  .table__tbody::-webkit-scrollbar {
    width: 5px;
    height: 13px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
  .table__tbody::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    min-height: 28px;
  }
  .table__tbody::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }*/

}
.target_p{
  margin-left:25px;
}
.check_optExplain{
  margin-top:7px;
}
.upload_check{
  margin-top:7px;
}