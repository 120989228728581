p-multi-image{

}
.p-multi-logic{
  .logic__mapping--tables{
    padding: 20px;
    max-height: 362px;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  .logic__mapping--table{
    border-spacing: 0;
    table-layout: fixed;
    width: 250px;
    border-top: 1px solid #cccccc;
    .thead__td{
      font-size: 16px;
    }
    tr{
      line-height: 30px;
    }
    td{
      padding: 0 10px;
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
    }
  }
}
.p-multi-score{
  width: 500px;
  min-height: 300px;
  height: 300px;
  overflow-y: auto;
  .score{
    &__row{
      display: flex;
      align-items: center;
      margin-top: 10px;
      &--label{
        margin-right: 40px;
      }
      &--input{
        margin-right: 5px;
      }
      &--input2{
        width: 90px;
        height: 30px;
      }
      &--left{
        display: flex;
        align-items: center;
        .score__row--input2{
          margin-right: 30px;
        }
      }
      &--right{
        display: flex;
        align-items: center;
      }

    }
    &__title{
      width: 100px;
    }
    &__info{
      margin-left: 90px;
      margin-top: 5px;
      color: red;
    }
    &__select{
      width: 390px;
    }
  }
  .Select-control{
    height: 35px;
  }
  .Select-value{
    line-height: 34px;
  }
}



