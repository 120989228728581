$grey-1: #333333;
$grey-2: #666666;
$grey-3: #999999;
$grey-4: #cccccc;
$grey-5: #dddddd;
$grey-6: #fafafa;
$grey-7: #e6e6e6;
$grey-8: #f3f3f3;

$primary-color: #01b0ec;
$yellow-color: #eb5312;
$red-light: #ec623e;
$red-color: #e94a20;
$blue-color: #00afec;
$baby-blue-color: #6dc7f4;

$success-color: rgb(56, 189, 58);
$warning-color: rgb(252,201,37);
$error-color: rgb(222, 60, 75);
$info-color: rgb(138, 243, 255);

$call-to-action-color: rgb(81,191,221);
$hover-color: rgb(90, 213, 243);
$dark-gray: rgb(102, 102, 102);

$works-nav-height: 51px;
$works-edit-tabs-height: 50px;
$container-width: 1000px;

/** z-index **/
$body-index: 0;
$zindex-notification: 1200;
$works-nav-index: 100;
$works-types-index: 95;
$works-edit-tabs-index: 90;