.check_global{
  .check_global_box{
    width:164px;
    float:left;
    margin-top:51px;
    .check_global_div{
      width:164px;
      height:71px;
      border-bottom: 1px solid #ccc;
      font-size:14px;
      line-height:71px;
      text-align:center;
    }
  }
  .check_global_tab{
    width:500px;
    float:left;
    margin-left:50px;
    margin-top:55px;
    .check_global_h4{
      font-size:20px;
      line-height:80px;
    }
    .check_global_span{
    margin-right:20px;
    }
  }
  .check_global_fileTab{
    width:1010px;
    float:left;
    margin-left:50px;
    margin-top:55px;
     .check_global_h4{
      font-size:20px;
      line-height:80px;
    }
    .check_global_file{
      margin-bottom: 20px;
    }
    .check_glbal_input{
      margin-right:3px;
    }
    .check_global_file_span{
      margin-top:20px;
      margin-bottom: 20px;
    }
    .check_global_save{
      position: absolute;
      left:1000px;
      top:60px;
      width:70px;
      height:28px;
      font-size:16px;
      text-align:center;
      line-height: 28px;
      background:rgba(18, 150, 219, 1);
      color:#fff;
      border-radius: 9px;
    }
  }
}
