.p-multi-add {
  padding: 13px 20px 0 20px;
  width: 460px;
  .multi {
    &__p1 {
      font-size: 12px;
      line-height: 24px;
      color: #888888;
      margin-top: 13px;
    }
    &__input {
      width: 100%;
      height: 180px;
      background-color: #ffffff;
      border: solid 1px #dddddd;
      margin: 4px 0 20px 0;
    }
  }
}
.p-multi-sub{
  .multi__p1{
    margin-top: 0px !important;
  }
  .multi__p2{
    text-indent: 25px;
  }
}
.p-multi-status{
  .multi__p1{
    margin-top: 13px !important;
    font-size: 14px;
    &--span{
      color: #00afec;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .modal__footer{
    margin-top: 20px;
  }
  .multi__input{
    height: 30px;
    width: 250px;
    margin: 10px 0px;
    &--row{
      width: 250px;
      margin: auto;
    }
  }
}
.p-multi-fail{
  width: 600px;
  .fail__record{
    display: flex;
    align-items: center;
    &--span{
      margin-left: 20px;
    }
  }
  .record__table{
    max-height: 300px;
    overflow-y: auto;
  }
}
.p-multi-money{
  width: 500px;
  padding: 20px;
  .p-multi--row{
    padding-top: 10px;
  }
  .p-multi--last{
    padding-bottom: 30px;
  }
  .multi__table{
    padding-bottom: 30px;
    max-height: 300px;
    overflow-y: auto;
    td{
      text-align: center;
    }
  }
  .multi__text{
    padding-bottom: 30px;
  }
  .add__money--title{
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
  }
  .add__money{
    border-bottom: 1px solid #cccccc;
    padding-bottom: 20px;
    &--info{
      font-size: 12px;
      color: red;
      margin-top: 10px;
    }
    &--type{
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
    &--list{
      display: flex;
      align-items: center;
      padding-right: 50px;
      input{
        margin-right: 5px;
      }
    }
    &--row{
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
    &--one{
      padding-right: 20px;
    }
    .multi__input{
      width: 80px;
      height: 30px;
      margin: 0 5px;
    }
  }
}
.p-multi-addMoney{
  width: 800px;
  .money__target{
    &--row{
      display: flex;
      border-bottom: 1px solid #cccccc;
      padding: 20px 0px;
    }
    &--one{
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 150px;
      .check__input{
        margin-right: 5px;
      }
    }
    &--text{
      position: absolute;
      top: -13px;
      left: 95px;
      background: #4C4C4C;
      color: #ffffff;
      width: 150px;
      padding: 3px 5px;
      display: none;
    }
  }
  .icon-help{
    font-style:normal;
    margin-left: 5px;
    &:hover{
      .money__target--text{
        display: block;
      }
    }
  }
  .money__channel{
    &--row{
      border-bottom: 1px solid #cccccc;
      padding: 20px 0px;
      display: flex;
      input{
        margin-right: 5px;
      }
    }
    &--one{
      display: flex;
      align-items: center;
      margin-right: 50px;
    }
  }
  .money__answer{
    margin-bottom: 20px;
    &--row{
      display: flex;
      align-items: center;
      margin-top: 15px;
      input{
        margin-right: 5px;
      }
      .multi__input{
        width: 80px;
        height: 30px;
        margin: 0 5px;
      }
    }
  }
  .money__order{
    &--title{
      text-align: center;
      font-size: 18px;
    }
    &--title2{
      text-align: center;
      margin-top: 15px;
    }
    &--row{
      width: 500px;
      border: 1px dashed #cccccc;
      margin: auto;
      margin-top: 10px;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    &--list{
      margin-left: 185px;
      margin-top: 20px;
    }
    &--text{
      text-align: center;
      margin-top: 2px;
      color: #cccccc;
    }
  }
}
.p-multi-redpacket{
  .label__redpacket{
    display: flex;
    align-items: center;
    line-height: 55px;
    &--row{
      margin: 10px 20px;
      margin-bottom: 40px;
    }
  }
  .label__input{
    width: 250px;
    height: 35px;
    margin-left: 10px;
    padding-left: 10px;
    margin-right: 10px;
  }
}
.p-multi-redpacket2{
  width: 500px;
  .label__redpacket{
    margin-left: 30px;
  }
}
.p-multi-lottery{
  width: 500px;
  margin-top:10px;
  .lottery__label{
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  .lottery__input{
    width: 200px;
    height: 35px;
  }
  .lottery__span{
    width: 88px;
  }
  p{
    line-height: 24px;
  }
  .modal__footer{
    margin-top: 20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .p-multi-lottery{
    width: 100%;
  }
}
@media screen and(max-width: 320px) {
  .p-multi-lottery{
    width: 100%;
  }
}
