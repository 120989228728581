.p-modal-suggest{
  width: 860px;
  height: 430px;
  padding: 10px 10px;
  td{
    padding: 0px 0px !important;
  }
  .tab{
    &__row{
      display: flex;
    }
    &__list{
      margin-right: 30px;
      height: 30px;
      &--active{
        color: #40b5ff;
        border-bottom: 2px solid #40b5ff;
      }
    }
  }
  .suggest__table{
    td{
      text-align: center;
      padding: 0px 0px !important;
    }
    .tr__td{
      color: #c0c0c0;
      div, a{
        color: #c0c0c0 !important;
      }
    }
    min-height: 330px;
    max-height: 330px;
    margin-bottom: 20px;
    overflow-y: auto;
  }
}