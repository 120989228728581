.bdsharebuttonbox{
  .bds_weixin{background: url("assets/images/icons/share/share_weixin.png");
    width: 26px;
    height: 26px;
    background-size: cover;
    background-position: 0 -104px !important;
  }
  .bds_tsina{background: url("assets/images/icons/share/share_sina.png");
    width: 26px;
    height: 26px;
    background-size: cover;
  }
  .bds_sqq{background: url("assets/images/icons/share/share_qq.png");
    width: 26px;
    height: 26px;
    background-size: cover;
    background-position: 0 -104px !important;
  }
}