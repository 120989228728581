$top-height: $works-nav-height + $works-edit-tabs-height + 10px;

body {
  &.is-edit-qtn {
    overflow: hidden;
  }
}

//编辑页面头部导航
.works-edit-nav {
  display: flex;
  background-color: #2a70f3;
  font-size: 14px;
  position: relative;
  min-width: $container-width;
  .nav-logo {
    margin-left: 25px;
    margin-top: 14px;
  }
  .nav {
    margin: 0 auto;
    &__item {
      display: inline-block;
      height: 50px;
      line-height: 50px;
      padding:0 30px;
      color: white;
      &:hover,
      &.active {
        background-color: #666666;
      }
    }
    &__back {
      width: 100px;
      line-height: 50px;
      text-align: center;
    }
  }
  .user {
    width: 240px;
    display: flex;
    align-items: center;
    .liuyan {
      width: 28px;
      height: 28px;
      background: #ccc;
    }
    .touxiang {
      width: 22px;
      height: 22px;
      background: #ccc;
      margin-left: 20px;
    }
    &__item {
      color: #fff;
      font-size: 14px;
      margin-left: 5px;
    }
  }
}

//编辑页面头部二级导航
.works-edit-tabs {
  justify-content: space-between;
  background-color: white;
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #dfe0e2;
  position: fixed;
  top: $works-nav-height;
  left: 0px;
  width: 100%;
  z-index: $works-edit-tabs-index;
  .tab {
    &__left {
      flex: 1;
    }
    &__qtn {
      padding-left: 30px;
      color: #7b7b7b;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
      width: 250px;
      &--sidebar {
        padding-left: 210px;
        color: #7b7b7b;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        width: 520px;
      }
    }

    &__center {
      display: flex;
      justify-content: center;
    }
    &__right {
      flex: 1;
      justify-content: flex-end;
    }
    &__item {
      position: relative;
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      cursor: pointer;
      color: #6c6c6c;
      &:hover,
      &.active {
        color: #40b5ff;
        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0px;
          top: 0px;
          left: -8px;
          margin: auto;
          transform: translate(-50%, 0);
          width: 3px;
          height: 3px;
          background-color: #40b5ff;
          border-radius: 3px;
        }
      }
      &--color{
        color: #cccccc;
      }
    }
    &__title {
      margin-left: 20px;
    }
    &__right {
      display: flex;
      align-items: center;
      padding-right: 20px;
    }
    &__buttons {
      display: flex;
      align-items: center;
      .btn {
        margin-left: 15px;
        padding: 0 13px;
        height: 30px;
        &:hover{
          color: #ffffff !important;
        }
      }
      .btn__back--none{
        &:hover{
          color: #ffac3b !important;
        }
      }

    }
    &__status {
      font-size: 12px;
      color: #999999;
      &__save{
        color: #20BB39;
      }
    }
  }
}
.p-works-edit {
  .editor {
    &--wrap {
      padding: 106px 0 0 0;
      background-color: #dfe0e2;
    }
    &__left {
      &--wrap {
        width: 180px;
        background-color: #2a70f3;
        position: fixed;
        left: 0px;
        top: 49px;
        height: calc(100vh - #{$works-nav-height} + 2px);
        overflow-y: auto;
        z-index: $works-types-index;
      }
      .left {
        &__category {
          cursor: pointer;
          color: #fff;
          line-height: 40px;
          background-color: #1860e7;
          display: flex;
          align-items: center;
          justify-content: center;
          .icon-qtn-category {
            margin-left: 5px;
          }
        }
        &__items {
          background-color: #2a70f3;
          &--hide {
            display: none;
          }
        }
        &__item {
          background-color: #2a70f3;
          padding: 2px 0 2px 39px;
          cursor: pointer;
          color: #fff;
          line-height: 30px;
          display: flex;
          align-items: center;
          &:hover {
            background-color: #1b61f3;
          }
        }
        &__type {
          margin-left: 9px;
          font-size: 12px;
        }
      }
      .item-10-1{
        display: none;
      }
    }
    &__main {
      margin: 0px 206px 0 186px;
      background-color: white;
      height: calc(100vh - #{$top-height} - -5px);
      overflow-y: auto;
      .qtn {
        &__header {
          display: flex;
          background-color: white;
          padding: 10px 171px 10px 60px;
          min-height: 58px;
          .header {
            &__title {
              /*color: #333333;*/
              font-size: 26px;
              text-align: center;
              margin-top: 30px;
              width: 100%;
              .editor{
                color: #7b7b7b;
              }
            }
            &__desc {
              margin: 18px 0 0 0;
            }
          }
        }
        &__questions {
          .question {
            &__wrap {
              margin-top: 49px;
              display: flex;
              position: relative;
              border-top: 1px solid white;
              border-bottom: 1px solid white;
              .question__opts{
                &--target9{
                  .target__explain{
                    display: flex;
                    align-items: center;
                    &--input{
                      width: 450px;
                      height: 40px;
                      margin-right: 10px;
                    }
                  }
                }
              }
              &:hover,
              &--active{
                .question__bottom{
                  visibility: visible;
                  .bottom__action{
                    display: -webkit-inline-box;
                  }
                }
                background-color: #f8fafc;
                border-top: solid 1px #e0e3e6;
                border-bottom: solid 1px #e0e3e6;
                .question {
                  &__main {
                    background-color: #f8fafc;
                  }
                  &__toolbar {
                    display: flex;
                  }
                }
                .control__item{
                  .control__edit{
                    visibility: visible;
                    color: #666666;
                  }
                }
              }
            }
            &__sort {
              width: 40px;
              margin-right: 5px;
              background-color: white;
              text-align: center;
              .icon {
                margin-top: 10px;
              }
            }
            &__sn {
              min-width: 30px;
              height: 35px;
              line-height: 35px;
              border: none;
              background-color: transparent;
              font-size: 16px;
              color: #666666;
              &--hide {
                visibility: hidden;
              }
            }
            &__main {
              flex: 1;
              background-color: white;
              padding: 8px 30px;
            }
            &__title {
              flex: 1;
              font-size: 16px;
              &--wrap {
                display: flex;
              }
            }
            &__restrict {
              min-width: 140px;
              padding-top: 6px;
              font-size: 12px;
              color: #9da0a5;
            }
            &__subs {
              @import "./works-edit/question__subs";
            }

            &__upload {
              margin-right: 164px;
              height: 70px;
              background-color: #ffffff;
              border: dashed 1px #a8aeb5;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &__control {
              display: flex;
              align-items: center;
              padding-top: 15px;
              padding-bottom: 10px;
              .control {
                &__item {
                  &:not(:first-child) {
                    margin-left: 20px;
                  }
                  &--select {
                    width: 160px;
                  }
                }
                &__edit {
                  cursor: pointer;
                  visibility: hidden;
                }
              }
            }
            &__items {
              padding: 10px 0;
              .target__list{
                display: flex;
                align-items: center;
                margin-top: 10px;
                &--text{
                  width: 430px;
                  height: 40px;
                }
                &--span{
                  margin-left: 10px;
                }
                &--upload{
                  width: 430px;
                  height: 40px;
                  line-height: 40px;
                  text-align: center;
                  border: 1px dashed #cccccc;
                }
              }
              &--c1{
                .question__label{
                  margin-top: -1px;
                }
              }
              &--score3{
                .opt__text{
                  &--left{
                    float: left;
                    width: 50%;
                    max-width: 50%;
                  }
                  &--right{
                    float: right;
                    width: 50%;
                    max-width: 50%;
                    text-align: end;
                    padding-right: 30px;
                  }
                }
              }
              .quesion__item--opt6{
                display: flex;
                align-items: center;
                .opt__text--left{
                  width: 70px;
                }
                .opt__text--right{
                  width: 70px;
                  text-align: right;
                }
              }
              /*打分题公共样式*/
              &--score{
                .question{
                  &__opt{
                    &--image1{
                      background: url("assets/images/icons/score/score-start.svg") center center /contain no-repeat;
                      width: 30px;
                      height: 30px;
                    }
                    &--image2{
                      background: url("assets/images/icons/score/score-smile.svg") center center /contain no-repeat;
                      width: 30px;
                      height: 30px;
                    }
                    &--image3{
                      background: url("assets/images/icons/score/score-heart.svg") center center /contain no-repeat;
                      width: 30px;
                      height: 30px;
                    }
                    &--image4{
                      background: url("assets/images/icons/score/score-good.svg") center center /contain no-repeat;
                      width: 30px;
                      height: 30px;
                    }
                    &--image5{
                      background: url("assets/images/icons/score/score-sorry.png") center center/contain no-repeat;
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
              }
              /*单项打分题样式*/
              &--score1{
                .question{
                  &__item{
                    float: left;
                    margin-right: 30px;
                    cursor: pointer;
                    .editor{
                      float: left;
                      min-width: 60px;
                      max-width: 83px;
                      &:first-child{
                        margin-right: 30px;
                      }
                    }
                  }
                  &__opt{
                    &--image{
                      float: left;
                      &:first-child{

                      }
                    }
                    &--right{
                      margin-right: 30px;
                    }
                  }
                }
              }
              &--score2{
                th{
                  font-weight: 100;
                }
                .question{
                  &__item{
                    float: left;
                    .editor{
                      min-height: 27px;
                      max-width: 70px;
                      text-align: center;
                    }
                    .quesion__item--top{
                      /*width: 70px;*/
                      margin: auto;
                    }
                    .quesion__item--opt{
                      width:60px;
                    }
                  }
                }
              }
              &--score-1{
                .question{
                  &__item{
                    float: left;
                    margin-right: 30px;
                  }
                }
              }
              &--score4{
                th{
                  font-weight: 100;
                }
                .matrix-table--wrap{
                  max-width: none;
                  .editor{
                    min-height: 27px;
                  }
                  .matrix__td{
                    margin: auto;
                  }
                  .toolbar__bottom{
                    display: none;
                  }
                }

              }
              &--c {
                .question {
                  &__item {
                    flex-wrap: wrap;
                    align-items: flex-start;
                    float: left;
                    clear: none;
                  }
                  &__label {
                    max-width: 83%;
                  }
                  &__required {
                    margin: 5px 0 0 31px;
                  }
                }
              }
              &--c2 {
                .question {
                  &__item {
                    width: 50%;
                    &:nth-child(2n+1) {
                      clear: both;
                    }
                  }
                }
              }
              &--c3 {
                .question {
                  &__item {
                    width: 33.33%;
                    &:nth-child(3n+1) {
                      clear: both;
                    }
                  }
                }
              }
              &--c4 {
                .question {
                  &__item {
                    width: 25%;
                    &:nth-child(4n+1) {
                      clear: both;
                    }
                  }
                }
              }
            }
            &__item {
              display: flex;
              min-height: 30px;
              input[type=radio],
              input[type=checkbox]{
                margin-top: 5px;
              }
              &--9,
              &--10 {
                .question--image--last{
                  cursor: pointer;
                  background: url("assets/images/add-image.png") no-repeat center 35px;
                  position: relative;
                  .add__image{
                    margin: auto;
                    color: #999999;
                    font-size: 12px;
                    margin-top: 110px;
                  }
                }
                flex-wrap: wrap;
                float: left;
                width: 20%;
                margin-top: 10px;
                &:nth-child(5n+1) {
                  clear: both;
                }
                & .question__label{
                  max-width: 134px;
                  margin-left: 0px;
                  min-width: 134px;
                }
                & .question__image--bottom{
                  width:150px;
                  height: 150px;
                  border: 1px solid #d1d1d1;
                  background: url("assets/images/add-image.png") no-repeat center center;
                }
                .question__item{
                  &--bottom{
                    width: 100%;
                    height: 30px;
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    color: #ffffff;
                    display: none;
                  }
                  &--span{
                    width: 50%;
                    background-color:rgba(0,0,0,0.6);
                    text-align: center;
                    display: inline-block;
                    line-height: 30px;
                    cursor: pointer;
                    &:hover{
                      background-color:rgba(0,0,0,0.2);
                    }
                  }
                  &--mutex {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    color: #ffffff;
                    display: none;
                    height: 30px;
                    background: rgba(0, 0, 0, 0.6);
                    > label {
                      display: flex;
                      align-items: center;
                      line-height: 30px;
                      input[type=checkbox] {
                        margin-top: 0
                      }
                    }
                  }
                }
                .image-selector{
                  img {
                    max-width: 150px;
                  }
                  &:hover{
                    .question__item--bottom{
                      display: block;
                    }
                    .question__item--mutex {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }
              &--2{
                .question{
                  &__item{
                    clear: both;
                    margin-bottom: 10px;
                    display: flex;
                    .editor{
                      padding: 4px 5px;
                      float: left;
                    }
                    .select__sep{
                      margin: 0px 5px;
                      height: 27px;
                      &--width{
                        width: 70px !important;
                      }
                    }
                    .field__row{
                      margin-top: 10px;
                      & input{
                        margin-top: 0px;
                        vertical-align: middle;
                      }
                    }
                    .opt__open--text{
                      float: left;
                    }
                    .more__wrap{
                      font-size: 12px;
                    }
                  }

                }
              }
              &--open2{
                .question{
                  &__item{
                    .editor{
                      min-width: 220px;
                      max-width: 220px;
                    }
                  }
                  &__label{
                    float: left;
                    min-width: 220px;
                    max-width: 220px;
                    .editor{
                      min-width: 220px;
                      max-width: 220px;
                    }
                  }
                }
              }
              &--open1{
                .question{
                  &__item{
                    .editor{
                      min-width: 220px;
                      max-width: 220px;
                      padding: 4px 5px;
                    }
                  }
                }
              }
            }

            &__label {
              margin-left: 10px;
              max-width: 70%;
              min-width: 45%;
            }
            &__required {
              margin-top: -1px;
            }
            &__value {
              margin-left: 10px;
            }
            &__bottom {
              display: flex;
              margin-bottom: 2px;
              visibility: hidden;
              .bottom {
                &__action {
                  margin-right: 10px;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  .icon {
                    margin-right: 8px;
                  }
                  .add {
                    margin-top: 3px;
                    color: #818e9e;
                  }
                }
              }

              &--column {
                flex-direction: column;
                margin-left: 10px;
                .bottom {
                  &__action {
                    &:not(:first-child) {
                      margin-top: 10px;
                    }
                  }
                }
              }
            }

            //矩阵题
            &__matrix {
              display: flex;
            }

            &__toolbar {
              display: none;
              position: absolute;
              right: 15px;
              top: -40px;
              width: 166px;
              padding: 0 16px;
              justify-content: space-between;
              align-items: center;
              background-color: #f8fafb;
              border: solid 1px #dfe3e6;
              border-bottom: 1px solid #f8fafb;
              height: 40px;
            }

            &__tips {
              color: #999999;
            }
          }
        }

        &__page {
          margin-top: 10px;
          text-align: center;
          height: 35px;
          line-height: 35px;
          position: relative;
          background-color: #f8fafc;
          border-top: solid 1px #e0e3e6;
          border-bottom: solid 1px #e0e3e6;
          .page {
            &__text {
              color: #40b5ff;
              font-size: 14px;
              &--strong {
                font-size: 18px;
                font-weight: normal;
              }
            }
            &__toolbar {
              position: absolute;
              right: 26px;
              top: 6px;
              bottom: 0px;
              width: 20px;
              justify-content: space-between;
              flex-direction: column;
              display: none;
            }
          }

          &:hover {
            .page {
              &__toolbar {
                display: block;
              }
            }
          }
        }

        &__footer {
          background-color: white;
          padding: 10px;
          margin-top: 10px;
          padding-bottom: 25px;
          .over {
            &__item {
              display: flex;
              align-items: center;
              &:not(:last-child) {
                margin-bottom: 10px;
              }
            }
            &__text {
              margin-left: 20px;
              flex: 1;
              text-align: center;
            }
          }
        }
      }
    }
    &__right {
      width: 200px;
      background-color: #f4f7f8;
      position: fixed;
      right: 0px;
      top: calc(#{$top-height} - 5px);
      height: calc(100vh - #{$top-height} + 4px);
      overflow-y: auto;
      z-index: $works-types-index;
      .panel {
        &__fields {
          padding: 15px 15px 0 15px;
          margin-bottom: 15px;
          .field {
            &__item {}
            &__row {
              display: flex;
              align-items: center;
              margin-top: 10px;
              font-size: 12px;
              .icon__left{
                margin-left: 5px;
              }
              &--level{
                margin-right: 5px;
              }
              .select {
                &__sep {
                  margin: 0 5px;
                  height: 27px;
                }
              }
              @import "../mixin/transform-group";
              .select__target{
                width: 60px;
              }
            }
          }
        }
        &__wrap {}
        &__title {
          height: 42px;
          line-height: 42px;
          background: #c4c9cf;
          color: #666b73;
          text-align: center;
        }
      }
    }
  }

  .empty-question {
    display: flex;
    align-items: flex-end;
    padding-left: 64px;
    margin: 75px 0 119px 0;
    .empty {
      &__icon {
        width: 160px;
        height: 72px;
        background: url("assets/images/empty-arrow.svg") 0 0 /cover no-repeat;
        margin-right: 28px;
      }
      &__text {
        color: #A8ABB0;
        font-size: 24px;
      }
    }
  }

  .tab-logic {
    &--wrap {
      margin: calc(#{$works-nav-height} + #{$works-edit-tabs-height}) auto 0 auto;
      padding-top: 16px;
      width: 1200px;
    }
    &__header {
      position: relative;
      z-index: 10;
    }
    &__types2 {
      .type {
        &__dropdown2 {
              .type {
                &__button {
                  &--border{
                    border: none;
                  }
                }
              }
        }
        &__button {
          display: flex;
          align-items: center;
          font-size: 22px;
          height: 50px;
          justify-content: center;
          padding: 10px 0;
          &--top{
            display: flex;
            align-items: center;
          }
        }
        &__plus {
          &--text {
            margin-right: 10px;
            color: #333333;
          }
        }

        &__items {
          position: absolute;
          background: white;
          top: 50px;
          right: -52px;
          border: 1px solid #B2B9C3;
          border-top: none;
          width: 120px;
          /*display: none;*/
          padding-bottom: 10px;
          z-index: 10;
        }
        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          cursor: pointer;
          margin: 2px 3px;
          &:hover {
            background-color: #eef0f2;
          }
          &--right{
            height: 15px;
          }
        }
        &__text {
          display: flex;
          align-items: center;
          color: #666666;
          &--color{
            color:#00afec !important;
          }
        }
        &__help {
          margin-left: 8px;
          position: relative;
          &--text {
            display: none;
            position: absolute;
            left: 30px;
            top: -20px;
            width: 248px;
            background-color: #4C4C4C;
            color: white;
            font-size: 12px;
            line-height: 20px;
            padding: 8px 12px;
            border-radius: 2px;
            &:before {
              display: block;
              content: "";
              width: 9px;
              height: 16px;
              position: absolute;
              left: -8px;
              top: 18px;
              background: url("assets/images/icons/logic/tips-arrow.svg") center center no-repeat;
            }
          }
          &:hover {
            .type__help--text {
              display: block;
            }
          }
        }
      }
    }
    &__types {
      .type {
        &__dropdown {
          position: relative;
          width: 120px;
              .type {
                &__button {
                  &--border{
                    border: 1px solid #B2B9C3;
                    border-bottom: none;
                  }
                }
              }
        }
        &__button {
          display: flex;
          align-items: center;
          font-size: 22px;
          height: 50px;
          justify-content: center;
          padding: 10px 0;
          &--top{
            display: flex;
            align-items: center;
          }
        }
        &__plus {
          &--text {
            margin-right: 10px;
            color: #333333;
          }
        }

        &__items {
          position: absolute;
          background: white;
          top: 50px;
          right: 0px;
          border: 1px solid #B2B9C3;
          border-top: none;
          width: 100%;
          /*display: none;*/
          padding-bottom: 10px;
          z-index: 100;
        }
        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          cursor: pointer;
          margin: 2px 3px;
          &:hover {
            background-color: #eef0f2;
          }
          &--right{
            height: 15px;
          }
        }
        &__text {
          display: flex;
          align-items: center;
          color: #666666;
          &--color{
            color:#00afec !important;
          }
        }
        &__help {
          margin-left: 8px;
          position: relative;
          &--text {
            display: none;
            position: absolute;
            left: 30px;
            top: -20px;
            width: 248px;
            background-color: #4C4C4C;
            color: white;
            font-size: 12px;
            line-height: 20px;
            padding: 8px 12px;
            border-radius: 2px;
            &:before {
              display: block;
              content: "";
              width: 9px;
              height: 16px;
              position: absolute;
              left: -8px;
              top: 18px;
              background: url("assets/images/icons/logic/tips-arrow.svg") center center no-repeat;
            }
          }
          &:hover {
            .type__help--text {
              display: block;
            }
          }
        }
      }
    }

    &__main {
      padding-bottom: 25px;
      @import "works-edit/logic";
    }
    &__footer {
      padding-bottom: 250px;
    }
  }
  input[type=radio]{
    width:16px;
    height: 16px;
  }
  input[type=checkbox]{
    width:16px;
    height: 16px;
  }
}
.newCheckbox_check{
  position:absolute;
  left:406px;
  top:60px;
}
.newCheck_div{
  width:363px;
  height:42px;
  border:1px dashed #ccc;
  text-align:center;
  line-height:42px;
  margin-top:12px;
}
.check_opt_div{
  width:170px;
  height:93px;
  margin-left:16px;
  .check_opt_checkunit{
    margin-top:13px;
    font-size:12px;
  }
  .check_opt_checkAccount{
    margin-top:13px;
  }
  .check_opt_change{
    margin-top:20px;
  }
}
.field_type_box{
  width:170px;
  height:100px;
  margin:0 auto;
  .field_type_div{
    line-height:19px;
    margin-top:30px;
    margin-bottom:10px;
  }
  .field_type_input{
    width:170px;
    font-size:12px;
    height:16px;
    margin-bottom: 8px;
    .field_type_checkbox{
      margin-right:5px;
    }
    .field_type_span{
      margin-top:1px;
      margin-right:40px;
    }
  }
}
.field_num_div{
  margin-top:10px;
  .field_num_text{
    width:25px;
  }
}
.field_cut_div{
  margin-top:20px;
}