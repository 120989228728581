.transform {
  &__group {
    display: flex;
    width: 100%;
    font-size: 12px;
  }
  &__item {
    border-top: 1px solid #c9c9c9;
    border-bottom: 1px solid #c9c9c9;
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    flex: 1;
    &:hover,
    &--active {
      background-color: #40b5ff;
      color: white;
      border-color: #40b5ff;
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid #c9c9c9;
      border-right-color: transparent;
      &:hover,
      &.transform__item--active {
        border-color: #40b5ff;
        border-right-color: transparent;
      }
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid #c9c9c9;
      border-left-color: transparent;
      &:hover,
      &.transform__item--active {
        border-color: #40b5ff;
        border-left-color: transparent;
      }
    }
  }
}