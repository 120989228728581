.account {
  display: flex;
  flex-wrap: wrap;
  margin-top: $works-nav-height;
  &__nav {
    width: 140px;
    min-height: 100vh;
    background: #f8f9fb;
    padding: 0 10px;
    &--item {
      border-bottom: solid 1px #e4e4e4;
      height: 72px;
      line-height: 72px;
      padding-left: 20px;
      cursor: pointer;
      a {
        color: #666;
        &:hover {
          color: #40b5ff;
        }
      }
      .account__row--active{
        color: #40b5ff;
      }
    }
  }
  .order__item{
    margin: auto;
    font-size: 14px;
    color: #666666;
    .order__btn{
      border: none;
      background: #ffac3b;
      padding: 5px 10px;
      border-radius: 4px;
    }
    .order__color{
      color: #666666;
    }
    &--row{
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .order__icon{
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
      .order__title{
        &--status{
          font-size: 16px;
          font-weight:500;
        }
        &--color{
          color: #ffac3b;
        }
      }
    }
  }
  &__main {
    padding: 42px;
    flex: 1;
    .main{
      &__title{
        font-size: 22px;
        color: #333;
        width: 100%;
      }
      &__content {
        width: 100%;
        /*margin-top: 19px;*/
        margin-top: 16px;
        display: flex;
        .main__nav {
          .group__list--row{
            &:hover{
              cursor: pointer;
              background-color: #3ec1ff;
              & .main__nav--item{
                width: 115px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .main__nav--edit{
                display: block;
              }


            }
          }
          width: 160px;
          min-height: 633px;
          background: #fafafa;
          border: solid 1px #f0f1f3;
          padding-bottom: 20px;

          &--item {
            width: 158px;
            height: 44px;
            line-height: 44px;
            padding-left: 15px;
            color: #555;
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

          }
          &--click {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 160px;
            height: 44px;
            line-height: 44px;
            color: #555;
            background-color: #3ec1ff;
            cursor: pointer;

          }
          &--edit{
            display: none;
            width: 160px;
            height: 44px;
            line-height: 44px;
            margin-top: -44px;
            padding-left: 119px;
            .edit{
              &:hover {
                color: #5D6A7D;
              }
            }
            .delete{
              padding-left: 2px;
              display: inline-block;
              vertical-align: middle;
              cursor: pointer;
              &:hover {
                color: #5D6A7D;
              }
            }
          }
          &--add {
            width: 80px;
            height: 30px;
            border-radius: 4px;
            background-color: #ffffff;
            border: solid 1px #40b5ff;
            cursor: pointer;
            margin: 15px auto 0;
            display: block;
            color: #40b5ff
          }
        }
        .content__table {
          flex: 1;
          width: 100%;
          min-height: 633px;
          background:#fff;
          margin-left: 22px;
          .row {
            display: flex;
            justify-content: space-between;
            &__left {
              display: flex;
              .add {
                width: 106px;
                height: 40px;
                border-radius: 4px;
                cursor: pointer;
                color: #fff;
                font-size: 14px;
                border: none;
                display: inline-block;
                padding: 0 0;
              }
              .import {
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                color: #40b5ff;
                margin-left: 24px;
                cursor: pointer;
              }
            }
            &__right {
              input {
                width: 171px;
                height: 34px;
                background-color: #ffffff;
                border: solid 1px #d6d6d6;
                padding-left: 10px;
                font-size: 14px;
              }
            }
          }
          .contact__table {
            width: 100%;
            border-collapse: collapse;
            color: #666;
            margin-top: 15px;
            font-size: 14px;
            thead {
              background-color: #e5e7e9;
            }
            tbody {
              background: #fff;
              tr {
                border-bottom: 1px solid #e5e7e9;
              }
            }
            tr {
              height: 52px;
              line-height: 52px;
              text-align: center;
              td:last-child{
                display: flex;
                justify-content: space-around;
              }
            }
          }
          .works__page{
            position: absolute;
            width: 70%;
            bottom: -10px;
            margin-top: 20px;
            display: flex;
            margin-bottom: 8px;
            color: #90979b;
            & .pagination{
              margin: auto;
            }
          }
        }
      }
    }
  }
  &__management {
    padding: 0 120px;
    flex: 1;
    .title {
      font-size: 22px;
      color: #333;
      margin-top: 45px;
      margin-bottom: 20px;
    }
    .user {
      margin-top: 50px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-content: space-between;
      color: #666;
      &__item {
        &--down {
          margin-top: 30px;
          .primary {
            padding: 5px 10px;
            background: #40b5ff;
            border-radius: 4px;
            color: #fff;
            border: none;
            cursor: pointer;
            margin-left: 20px;
          }
          .warnning {
            padding: 5px 10px;
            background: #ffac3b;
            border-radius: 4px;
            color: #fff;
            border: none;
            cursor: pointer;
            margin-right: 20px;
            position: relative;
            &__span{
              position: absolute;
              top: -9px;
              right: -7px;
              width: 18px;
              height: 18px;
              border-radius: 18px;
              background: red;
            }
          }
        }
      }
    }
    .m-table {
      width: 100%;
      color: #666;
      border-collapse: collapse;
      text-align: center;
      thead {
        text-align: center;
        background: #ebeef0;
      }
      tbody {
        tr {
          border-bottom: 1px solid #e5e7e9;
        }
      }
      tr{
        height: 45px;
        line-height: 45px;
      }
      .using {
        color: #666;
      }
      .unused {
        color: #b0b0b0;
      }
    }

  }
  &__messages {
    padding: 0 120px;
    flex: 1;
    .title {
      font-size: 22px;
      color: #333;
      margin-top: 45px;
    }
    .m-table {
      width: 100%;
      color: #666;
      border-collapse: collapse;
      text-align: center;
      margin-top: 20px;
      thead {
        text-align: center;
        background: #ebeef0;
      }
      tbody {
        tr {
          border-bottom: 1px solid #e5e7e9;
        }
      }
      tr{
        height: 45px;
        line-height: 45px;
      }
    }
  }
  &__profile {
    flex: 1;
    padding: 0 100px;
    .title {
      padding-bottom: 12px;
      font-size: 22px;
      color: #333;
      margin-top: 45px;
    }
    .title2{
      font-size: 16px;
      color: #333333;
      margin-top: 10px;
      &__info{
        margin-left: 30px;
        font-size: 14px;
      }
    }
    .profile {
      //margin-top: 35px;
      /*margin-left: 49px;*/
      margin-top: 10px;
      tr {
        line-height: 55px;

      }
      .info {
        color: #666;
        margin-left: 10px;
        min-width: 170px;
        display: inline-block;
        &__img{
          width: 57px;
          height: 84px;
          display: block;
          margin-left: 10px;
        }
      }
      .oprate {
        color: #40b5ff;
        cursor: pointer;
        margin-left: 20px;
        &__img{
          display: flex;
        }
      }
      .item {
        display: inline-block;
        width: 100px;
        color: #363636;
        margin-left: 20px;
        font-weight: 500;
      }
      .item1{
        width: 105px;
        display: inline-block;
      }
      .item2{
        width: 105px;
        display: inline-block;
      }
      .item3{
        width: 105px;
        display: inline-block;
      }
      .account__title{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        &--record{
          margin-left: 20px;
        }
        &--btn{
          margin-left: 20px;
          color: #00afec;
          background: none;
          border: none;
          cursor: pointer;
        }
      }
      .color__yellow{
        color: #ffac3b;
      }
      .color__gray{
        color: #888;
        margin-left: 5px;
        font-size: 12px;
      }
      .color__green{
        color: #10cca9;
      }
      .sub__table{
        margin-bottom: 25px;
        th,td{
          text-align: center;
        }
      }
    }
  }
  &__records {
    flex: 1;
    padding: 0 50px;
    .title {
      font-size: 22px;
      color: #333;
      margin-top: 45px;
    }
    .tabs {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #e4e4e4;
      .tab__item {
        margin-right: 48px;
        font-size: 16px;
        line-height: 45px;
        cursor: pointer;
        color: #888;
        &--active {
          color: #299feb;
          border-bottom: 3px solid #299feb;
        }
      }
    }
    .m-table {
      width: 100%;
      color: #666;
      border-collapse: collapse;
      text-align: center;
      margin-top: 20px;
      thead {
        text-align: center;
        background: #ebeef0;
      }
      tbody {
        tr {
          border-bottom: 1px solid #e5e7e9;
        }
      }
      tr{
        height: 45px;
        line-height: 45px;
      }
    }
  }
  /*底部分页样式*/
  & .works__page{
    /*position: absolute;*/
    width: 74%;
    /*bottom: 10px;*/
    margin-top: 20px;
    display: flex;
    & .pagination{
      margin: auto;
    }
  }
 &  .account-table{
   min-height: 600px;
  padding-bottom: 80px;
   position: relative;
   .m-table{
     .thead__font{
       tr{
         line-height: 52px;
       }
       th{

         font-weight: 100;
       }
     }
   }
 }
  .account__row--active{
    color: #40b5ff;
  }
}



