.invate__page{
  width: 650px;
  margin: auto;
  .copyrights{
    width: 650px;
    background-color: #2a70f3;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    padding: 6px 0px;
  }
}