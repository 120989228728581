.p-confirm {
  width: 400px;
  .confirm {
    &__message {
      padding: 32px 0 36px 0;
      word-break: break-all;
      color: #666666;
      text-align: center;
    }
  }
  .confirm{
    &__text{
      text-align: center;
      margin-top: 20px;
    }
    &__text2{
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

.p-multi-qtn{
  .table-qtn{
    margin-top: 10px;
    margin-bottom: 20px;
    tr{
      line-height: 25px;
    }
    td,th{
      text-align: center;
      padding: 0 10px;

    }
    .qtn__thead{
      display: block;
    }
    .qtn__tbody{
      display: block;
      height: 275px;
      overflow: auto;
    }
    .qtn__input{
      margin-right: 5px;
    }
  }
}

.p-multi-logic{
  width: 500px;
  padding: 10px 20px 40px;
  align-items: center;
  color: #666666;
  .message__p1{
    padding-top: 10px;
  }
  .logic__label{
    display: flex;
    margin-top: 20px;
    &--name{
      width: 70px;
    }
    &--input{
      width: 245px;
      height: 30px;
    }
  }
}
.perfect-info-confirm{
  padding: 0 32px;
  width: 440px;
  .register__row{
    position: relative;
    margin-top: 18px;
    color: #666666;
    width: 100%;
    display: flex;
    align-items: center;
    .register__input{
      display: block;
      width: 86%;
      border: solid 1px #d6d6d6;
      padding: 0 12px;
      height: 46px;
      font-size: 14px;
      margin-right: 5px;
    }
    .code{
      position: absolute;
      top: 0px;
      right: 68px;
      z-index: 1;
      line-height: 46px;
      font-size: 14px;
      color: #1f1f1f;
      cursor: pointer;
    }
  }
  .modal__footer{
    margin-top: 25px;
  }
}