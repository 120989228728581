.p-copy-link {
  padding: 23px 0 0 0;
  text-align: center;
  .link {
    &__text {
      margin-bottom: 25px;
      font-size: 14px;
      color: #666666;
    }
  }
}