@import "variables";
.ant-notification {
  position: fixed;
  z-index: $zindex-notification;
  width: 335px;
  margin-right: 24px;
}
.ant-notification-notice {
  padding: 16px 40px 16px 16px;
  border-radius: 4px;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, .1);
  border: 1px solid #f4f4f4;
  background: rgba(0,0,0,0.8);
  line-height: 1.5;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.ant-notification-notice-content-message {
  font-size: 14px;
  color: #666666;
  margin-bottom: 4px;
}
.ant-notification-notice-content-description {
  font-size: 14px;
  color: #5D6A7D;
}
.ant-notification-notice-content-icon {
  position: relative;
}
.ant-notification-notice-content-icon-message {
  font-size: 14px;
  color: white;
  margin-bottom: 4px;
}
.ant-notification-notice-content-icon-description {
  margin-left: 51px;
  font-size: 14px;
  color: #5D6A7D;
}
.ant-notification-notice-content-icon-icon {
  position: absolute;
  left: 6px;
  top: 50%;
  margin-top: -15px;
  font-size: 16px;
}
.ant-notification-notice-content-icon-icon-success {
  color: #20BB39;
}
.ant-notification-notice-content-icon-icon-info {
  color: #2db7f5;
}
.ant-notification-notice-content-icon-icon-warn {
  color: #fac450;
}
.ant-notification-notice-content-icon-icon-error {
  color: #ff6600;
}

.ant-notification-notice-close-x {
  cursor: pointer;
  font-size: 14px;
  display: none;
}

.ant-notification-notice-close {
  position: absolute;
  right: 16px;
  top: 10px;
  color: #5D6A7D;
  outline: none;
}
.ant-notification-notice-content-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification-fade-enter {
  left: 335px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active {
  opacity: 1;
  left: 0;
}
.ant-notification-fade-leave {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
