.p-rich-text {
  padding: 13px 20px 0 20px;
  width: 540px;
  .rich {
    &__editor {
      min-height: 200px;
      max-height: 400px;
      margin-bottom: 20px;
      overflow-y: auto;
      border-top: none !important;
    }
  }
}