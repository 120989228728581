.p-upgrade {
  background: #fff;
  padding-top: $works-nav-height;
  .block {
    background-color: white;
    padding: 20px 30px;
    margin-bottom: 10px;
    margin-top: 25px;
    &__header {
      line-height: 30px;
      font-size: 22px;
      color: #333;
    }
    &__table {
      width: 100%;
      margin-top: 20px;
      border-bottom: 1px solid #ccc;
      border-spacing: 0;
      color: #666;
      th,
      td {
        text-align: center;

      }
      .th__back{
        line-height: 52px;
        background-color: #e5e7e9;
      }
      th:last-child {
        width: 300px;
      }
      td {
        line-height: 52px;
      }
    }
    &__group {
      display: flex;
     justify-content: flex-end;
    }
    &__action {
      margin-right: 10px;
      width: 90px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      color: white;
      background-color: #40b5ff;
      font-weight: 600;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background-color: #666666;
      }
    }
    &__change {
      margin-right: 10px;
      width: 90px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      color: #40b5ff;
      background-color: #fff;
      font-weight: 600;
      text-align: center;
      font-size: 14px;
      border: 1px solid #40b5ff;
      cursor: pointer;
      &:hover {
        background-color: #40b5ff;
        color: #fff;
      }
    }
    &__upgrade {
      margin-right: 10px;
      width: 90px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      color: white;
      background-color: #ffac3b;
      font-weight: 600;
      text-align: center;
      font-size: 14px;
    };
  }
}
.coupon{
  p{
    line-height: 26px;
  }
  .coupon__title{
    border-bottom: 1px solid #cccccc;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding: 15px 0px;
  }
  .coupon__main--title{
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }
}




