.login_div{
  position: relative;
  height: 100vh;
  background: url(assets/images/icons/home/loginBg.jpg) center center/cover;
  width: 100%;
  .p1-login {
    width: 400px;
    max-height: 430px;
    padding: 40px;
    max-width: 400px;
    margin: auto;
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 1000;
    background: inherit;
    background-color: rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(191, 210, 233, 1);
    border-radius: 10px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    .login {
      &__logo{
        margin-top: 10px;
      }
      &__close{
        position: absolute;
        right: 10px;
        top:10px;
        cursor: pointer;
      }
      &__logo{
        .logo__epanel{
        }
        .ip__epanel{
          font-size: 16px;
          letter-spacing: 2px;
          text-align: center;
          color: #888888;
        }
      }
      &__row {
        margin-top: 23px;
        position: relative;
        .forget_password{
          position: absolute;
          top:0px;
          right: 8px;
          line-height: 46px;
          font-size: 14px;
          color: #888888;
          &:hover{
            color: #5086f8;
          }
        }
      }
      &__remember{
        height: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        color: #888888;
        input{
          margin-right:5px;
        }
      }
      &__num{
        color: #666666;
        &--reg{
          color: #1c1b1b;
        }
      }
      &__input {
        display: block;
        width: 100%;
        padding: 0 12px;
        height: 46px;
        border: solid 1px #d6d6d6;
        font-size: 14px;
        &::placeholder {
          color: #888888;
        }
      }
      &__submit {
        text-align: center;
        line-height: 44px;
        //margin-top: 20px;
        cursor: pointer;
        height: 44px;
        border-radius: 4px;
        background-color: #5086f8;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        border: none;
        width: 100%;
        &:hover{
          background-color: rgba(80,134,248,0.9);
        }
      }
      &__footer {
        text-align: center;
        margin-top: 10px;
      }
      &__login {
        font-weight: 600;
        color: black;
      }
    }
    & .login--logo{
      margin: auto;
    }
  }
}
.choose_login_method{
  p{
    text-align: center;
    color: #666666;
    line-height: 32px;
  }
  .login-choose{
    display: flex;
    margin:0 auto;
    justify-content:space-around;
    .choose_qq{
      display: block;
      width:26px;
      height:26px;
      cursor: pointer;
      //margin: 0px 25px 0px 25px;
      background: url(assets/images/icons/home/qq.svg) center center/contain no-repeat;
    }
    .choose_weichat{
      display: block;
      width:30px;
      height:30px;
      cursor: pointer;
      //margin: 0px 25px 0px 25px;
      background: url(assets/images/icons/home/weiChat.svg) center center/contain no-repeat;
    }
    .choose_blog{
      display: block;
      width:30px;
      height:30px;
      cursor: pointer;
      //margin: 0px 25px 0px 25px;
      background: url(assets/images/icons/home/microBlog.svg) center center/contain no-repeat;
    }
    .choose_nail{
      display: block;
      width:33px;
      height:33px;
      cursor: pointer;
      //margin: 0px 25px 0px 25px;
      background: url(assets/images/icons/home/nail.svg) center center/contain no-repeat;
    }
  }
}
