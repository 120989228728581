.btn {
  &:hover {
    background-color:rgba(64,181,255,0.9)
  }
  padding: 0 16px;
  line-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #40b5ff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border: none;
  &.btn-primary{
    background-color: #ffac3b;
    color: white;
    &:hover{
      background-color:rgba(255,172,59,0.9)
    }
  }
  &.btn-secondary {
    background-color: #dbdbdb;
    color: #666666;
    &:hover{
      background-color: rgba(219,219,219,0.9);
    }
  }
  &.btn-s {
    height: 30px;
    font-size: 14px;
    padding: 0 17px;
  }

  &[disabled] {
    background-color: #dbdbdb;
    color: #666666;
  }
  &.btn__back--none{
    padding: 0px !important;
    background: none;
    color: #ffac3b;
    cursor: default;
    font-size: 14px;
    margin-right: 3px;
  }
  &.btn-green {
    background-color: #10cca9;
    color: #ffffff;
    &:hover{
      background-color:rgba(16,204,169,0.9)
    }
  }

}

.input {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  height: 36px;
  cursor: text;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  color: #999999;
  font-family: "PingFang SC", Microsoft Yahei,Helvetica, Arial, sans-serif !important;
  &-sm{
    height:27px;
  }
  &-block {
    width: 100%;
  }
  &-lg {
    height: 40px;
  }
}

input::placeholder{
  color:#999999;
}

input:focus {
  border: 1px solid #6dbbff !important;
}

.Select-input{
  input:focus {
    border: 1px solid red !important;
  }
}