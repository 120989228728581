/*添加联系人弹窗*/
.p-multi-contacts{
  padding: 13px 20px 0 20px;
  width: 360px;
  color: #666666;
  .multi{
    &__input{
      flex: 1;
      margin-left: 10px;
      color: #888888;
    }
    &__row{
      position: relative;
      margin: 10px 0;
      display: flex;
      align-items: center;
      .data__export {
        flex: 1;
        margin-left: 10px;
        & .Select-control{
          height: 38px;
          & .Select-value{
            line-height: 36px;
            padding-left: 8px;
            & .Select-value-label{
              color: #666666 !important;
            }

          }
        }
      }
    }
    &__code{
      position: absolute;
      right: 8px;
      top: 9px;
      cursor: pointer;
    }
    &__label{
      width: 50px;
      display: block;
    }
  }
}
/*短信购买弹窗*/
.p-multi-buysms{
  padding: 13px 10px 0 10px;
  width: 432px;
  .multi-buysms--row{
    display: flex;
    .account__bordercolor{
      border: solid 1px #40b5ff;
    }
  }
  .multi__block{
    &:hover{
      border: solid 1px #40b5ff;
    }
    display: inline-block;
    width: 124px;
    height: 94px;
    border: solid 1px #e7ebef;
    text-align: center;
    margin-right: 20px;
    &:last-child{
      margin-right: 0px;
    }
    &--center{
      margin: 0 72px;
    }
    &--total{
      width: 122px;
      height: 44px;
      line-height: 44px;
      background-color: #40b5ff;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
    }
    &--one{
      line-height: 50px;
      font-size: 10px;
      color: #888888;
      .multi__one--color{
        font-size: 20px;
        color: #ffac3b;
      }
    }

  }
  .buy__num{
    font-size: 14px;
    color: #888888;
    margin-top: 20px;
    padding-bottom: 14px;
    border-bottom: solid 1px #aeb5bb;
  }
  .pay__money{
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #666666;
    &--color{
      font-size: 20px;
      color: #ff7a11;
    }
  }
}

/*充值弹窗*/
.p-multi-recharge{
  padding: 13px 20px 0 20px;
  width: 460px;
  .multi__row{
    font-size: 14px;
    color: #666666;
    margin-top: 25px;
    &--underline{
      padding-bottom: 27px;
      border-bottom: solid 0.5px #aeb5bb;
    }
    .multi__input{
      width: 120px;
      height: 34px;
      border: solid 1px #d2d8dd;
      margin-right: 10px;
    }
  }
  .pay{
    &__way{
      font-size: 14px;
      color: #666666;
      margin-top: 17px;
      margin-bottom: 14px;
      &--wrap{
        margin-bottom: 20px;
        display: flex;
      }
    }
    &__block{
      width: 118px;
      height: 44px;
      border: solid 1px #ffa01e;
      display: flex;
      margin-right: 10px;
      font-size: 14px;
      line-height: 44px;
      color: #666666;
      padding-left: 15px;
      align-items: center;
    }
  }

}
/*配额设置弹窗*/
.p-multi-quota{
  input{
    padding-left: 5px;
  }
  position: relative;
  padding: 13px 20px 0 20px;
  width: 645px;
  height: 400px;
  & .modal__footer{
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
  }
  .quota{
    &__list{
      margin-bottom: 20px;
    }
    &__title{
      float: left;
      width: 112px;
      height: 34px;
      background-color: #ffffff;
      border: solid 1px #dddddd;
      font-size: 14px;
      line-height: 34px;
      text-align: center;
      color: #888888;
      &--color{
        background-color: #40b5ff;
        color: #ffffff;
      }
      &--none{
        border-right: none;
      }
    }
    &__qtn{
      flex: 1;
      .Select{
        &-control{
          height: 38px !important;
        }
        &-value{
          line-height: 38px !important;
          &-label{
            color: #888888 !important;
          }
        }
      }
    }
    &__table{
      table-layout: fixed;
      margin-bottom: 20px;
      .qtn{
        &__title{
          margin-top: 10px;
          width: 400px;
          display: inline-block;
          font-size: 14px;
          line-height: 38px;
          text-align: left;
          color: #888888;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .qtn__title--none{
            display: block;
            width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &__input{
          margin-top: 10px;
          width: 100px;
          height: 38px;
          background-color: #ffffff;
          border: solid 1px #dddddd;
        }
      }
    }
    &__one{
      .quota__opt{
        height: 220px;
        overflow: auto;
      }
    }
    &__two{
      margin-bottom: 15px;
      & th,td{
        color: #888888;
      }
      &--select{
        display: flex;
        .Select{
          &-control{
            height: 38px !important;
          }
          &-value{
            line-height: 38px !important;
            &-label{
              color: #888888 !important;
            }
          }
        }
      }
      &--table{
        table-layout: fixed;
        display: block;
        margin-top: 15px;
        width: 583px;
        height: 225px;
        overflow: auto;
      }
      &--qtn{
        flex: 1;
        height: 38px;
        color: #888888;
      }
      .quota{
        &__join{
          padding: 0 8px;
          line-height: 38px;
        }
        &__td{
          width: 90px;
          font-size: 14px;
          line-height: 54px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          &--top{
            width: 100px;
            height: 38px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
          }
        }
        &__td2{
          width: 100px;
          height: 38px;
        }
        &__input{
          width: 100px;
          height: 38px;
          border: solid 1px #dddddd;
        }
      }
    }
    &__more{
      display: flex;
      color: #888888;
      height: 280px;
      overflow: auto;
      &--row{
        display: flex;
        margin-bottom: 20px;
        .Select{
          &-control{
            height: 38px !important;
          }
          &-placeholder{
            line-height: 38px !important;
            color: #888888 !important;
          }
          &-value{
            line-height: 38px !important;
            &-label{
              color: #888888 !important;
            }
          }
        }
        .logic{
          &__c1{
            width: 45px;
            line-height: 36px;
          }
          &__c2{
            width: 110px;
            margin: 0 11px;
          }
          &__c--item{
            width: 110px;
            margin: 0 11px;
          }
          &__c3{
            display: flex;
            height: 38px;
            align-items: center;
            &--add{
              background: url(assets/images/icons/data/add.svg) center center/contain no-repeat;
            }
            &--delete{
              margin: 0 10px;
              background: url(assets/images/icons/data/delete.svg) center center/contain no-repeat;
            }
          }
        }
      }
    &--qtn{
      color: #888888;
      width: 96px;
      height: 38px;
      margin: 0 10px;
    }
    &--check{
      width: 64px;
      height: 38px;
      border: solid 1px #dddddd;
      color: #888888;
    }
    &--opt{
      width: 37px;
      height: 38px;
      border: solid 1px #dddddd;
      color: #888888;
    }
    &--width{
      width: 37px;
      display: inline-block;
      text-align: center;
    }
    & .quota{
      &__delete{
        display: inline-block;
        width: 22px;
        height: 22px;
        border: 1px solid #c9cdd4;
        border-radius: 22px;
        text-align: center;
        color: #888888;
        margin-right: 8px;
      }
      &__num--input{
        width: 64px;
        height: 38px;
        border: solid 1px #dddddd;
        margin-left: 10px;
      }
    }
  }
  }
}
/*确认删除弹窗*/
.p-multi-deleted{
  padding: 13px 20px 0 20px;
  width: 360px;
  & .deleted__text{
    margin-top: 20px;
    font-size: 14px;
    color: #666666;
    margin-bottom: 40px;
  }
}

.p-multi-template{
  padding:13px 20px 0 20px;
  color: #666666;
  .template{
    &__title{
      font-size: 16px;
      margin-bottom: 10px;
    }
    &__input{
      width: 370px;
      margin-bottom: 10px;
      height: 38px;
      border: 1px solid #dddddd;
      padding-left: 10px;
    }
  }
  .works__categories{
    &--label{
      display: inline-block;
      width: 33%;
      line-height: 28px;
      input{
        margin-right: 10px;
      }
    }
  }
  .multi__info{
    margin-top: 10px;
  }
  .modal__footer{
    margin-top: 30px;
  }

}
.p-multi-usetemplate{
  padding: 10px;
  color: #666666;
  width: 700px;
  max-height: 500px;
  height: 500px;
  overflow: auto;
}

