.p-agreement {
  margin-top: 45px;
  padding-bottom: 26px;
  font-size: 12px;
  color: #666666;
  .agreement {
    &__title {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
      color: #444444;
      font-weight: 500;
    }
  }
}

.copyright{
  width: 100%;
  min-height: 50px;
  background-color: #2a70f3;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  line-height: 50px;
  &-agreement{
    position: fixed;
    bottom: 0px;
  }
}
@media screen and (min-width: 320px) and (max-width: 640px) {
  .copyright{
    min-height: 50px;
    line-height: 25px;
  }
}
 .email__main{
   width: 800px;
   margin: auto;
   margin-top: 50px;
   p{
     padding-bottom: 30px;
   }
   &--p1{
     text-indent: 2px;
   }
 }

@media screen and (min-width: 320px) and (max-width: 640px) {
  .email__main{
    width: 90% !important;
  }
}