.p-works-preview {
  .preview {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      background-color: #2a70f3;
      color: white;
      position: fixed;
      width: 100%;
      top: 0px;
      z-index: 100;
      &--set{
        justify-content: flex-start !important;
      }
      .qtn__status{
      padding: 2px 5px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      background: #20BB39;
      color: #ffffff;
      padding-right: 15px;
        position: absolute;
        right: 0px;
        top: 15px;
    }
      .header {
        &__left {
          padding-left: 25px;
          width: 600px;
        }

        &__notice {
          padding: 5px 6px;
          background-color: #ffea7a;
          font-size: 12px;
          color: #ff5f0a;
        }

        &__right {
          display: flex;
          align-items: center;
          padding-right: 25px;
          width: 470px;
          height: 100%;
          .action {
            &__item {
              padding: 0 10px;
              height: 100%;
              display: flex;
              align-items: center;
              &--copy {
                display: flex;
                align-items: center;
                cursor: pointer;
                .icon {
                  margin-right: 5px;
                }
              }
              &--pages {
                width: 120px;//页码为两位数时，windows下也不会出现换行情况
                > .Select {
                  width: 100%;
                }
              }
              &--active {
                background-color: #235DCA;
              }
              &--pc,
              &--mobile
              {
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    &__main {
      background-color: #dfe0e2;
      padding: 65px 0 0 0;
      min-height: 100vh;
      .template__btn{
        margin: auto;
        margin-top: 10px;
        /*position: absolute;
        top: 52px;
        right: 50px;*/
      }
      .header__notice{
        padding: 5px 6px;
        background-color: #ffea7a;
        font-size: 12px;
        color: #ff5f0a;
        width: 265px;
        margin: auto;
        margin-bottom: 10px;
      }
      .qtn__status{
        position: fixed;
        left: 252px;
        top: 75px;
        background: #20BB39;
        padding: 2px 5px;
        display: flex;
        align-items: center;
        z-index: 1;
        color: #ffffff;
      }
      .main {
        &__mobile {
          width: 358px;
          height: 728px;
          margin: auto;
          background: url("assets/images/mobile_style.png") center center no-repeat;
          background-size: cover;
          padding-top: 76px;
          .header__notice{
            margin-bottom: 0px !important;
            margin-top: 5px;
            width: 320px;
            text-align: center;
          }
        }
        &__pc {
          width: 920px;
          margin: 15px auto 0 auto;
          background: white;
          padding: 52px 116px;
          min-height: 720px;
        }
      }
    }
  }
  .template__return{
    padding: 5px 20px;
    background: #40b5ff;
    cursor: pointer;
    border-radius: 4px;
    color: #ffffff;
    margin-right: 90px;
    font-size: 16px;
    &--btn{
      background: #c0c1c5 !important;
      color: #ffffff;
      &:hover{
      background: #a2a3a7 !important;
      color: #ffffff;
    }
    }
  }
  .btn__template{
    background: #ffffff !important;
    border: 1px solid #40b5ff;
    color: #40b5ff !important;
    height: 35px;
    &:hover{
      background: #40b5ff !important;
      border: none;
      color: #ffffff !important;
    }
  }

  @import "works-edit/setting";
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .btn__template{
    display: none;
  }
}
@media screen and (max-width: 320px){
  .btn__template{
    display: none;
  }
}
