.theme {
  &__editor {
    .editor {
      &__swiper {
        .swiper-slide {
          width: 35px;
          height: 35px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .swiper {
          &--1 {
            background-color: #7BC6B9;
          }
          &--2 {
            background-color: #BEBFD5;
          }
          &--3 {
            background-color: #E6BA98;
          }
          &--4 {
            background-color: #9DBCE8;
          }
          &--5 {
            background-color: #BD10E0;
          }
          &--6 {
            background-color: white;
          }
        }
      }
    }
  }
}