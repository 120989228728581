.subs {
  &--rank {
    .sub {
      &__item {
        &--row {
          display: flex;
          align-items: center;
        }
      }
      &__col {
        &:not(:first-child) {
          margin-left: 10px;
        }
      }
      &__edit {
        cursor: pointer;
      }

      &__opts {
        &--hide {
          display: none;
        }
      }
    }
  }
}