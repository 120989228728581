.works-nav {
  position: relative;
  align-items: center;
  margin: auto;
  &--height{
    height: 50px;
  }
  &--wrap {
    display: flex;
    .nav {
      &__logo {
        margin-top: 14px;
        margin-left: 25px;
      }
      &__item {
        height: 50px;
        line-height: 49px;
        padding: 0 10px;
        color: white;
        position: relative;
        font-size: 16px;
        min-width: 140px;
        text-align: center;
        display: inline-block;
        &--active,
        &:hover {
          font-weight: 500;
          font-size: 18px;
        }
        &--triangle {
          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -1px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 12px;
            height: 5px;
            background: url("assets/images/icons/triangle-top.svg") 0 0 no-repeat;
          }
        }
      }
      &__profile {
        position: relative;
        width: 157px;
        display: flex;
        align-items: center;
        .profile {
          &__avatar {
            margin-left: 25px;
          }
          &__logout {
            margin-left: 10px;
          }
          &__name {
            margin-left: 10px;
            max-width: 87px;
            display:block;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
            color: #ffffff;
          }
          &__item {
            margin-left: 5px;
            color: #ffffff;
            cursor: pointer;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            width: 65px;
          }
        }
        .account__nickname{
          display: flex;
          align-items: center;
        }
        .account__list{
          background: #ffffff;
          border: 1px solid #cccccc;
          position: absolute;
          top:50px;
          right: 5px;
          padding: 10px 0px;
          .account__row{
            & a{
              color: #666666;
            }
            cursor: pointer;
            padding: 0px 20px;
            text-align: center;
            line-height: 30px;
            color: #666666;
            &:hover{
              background: #cccccc;
            }
          }
        }
        .account__list--none{
          display: none;
        }
      }
    }
  }
  &--fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: $works-nav-index;
    .nav__return{
      line-height: 50px;
      margin-left: 30px;
      a{
        color: #ffffff;
        font-size: 16px;
      }
    }
  }
  &--absolute {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: $works-nav-index;
  }
  &--color {
    background-color: #2a70f3;
    .qtn__status{
      position: absolute;
      top: 10px;
      right: 0px;
      padding: 2px 5px;
      display: flex;
      align-items: center;
      background: #20BB39;
      color: #ffffff;
      padding-right: 15px;
    }
  }
}
.p-works {
  .works-header-row{
    border-bottom: 0.5px solid #dbdfe3;
  }
  .works-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 53px 0 15px 0;
    .header {
      &__left {
        display: flex;
        align-items: center;
        .Select-value-label{
          color: #666666 !important;
        }
      }
      &__right{
        display: flex;
        .icon__deleted{
          display: inline-block;
          margin-right: 5px;
        }
        &--deleted{
          display: flex;
          align-items: center;
          margin-right: 10px;
          color: #666666;
        }
      }
      &__add {
        width: 100px;
        height: 30px;
        line-height: 30px;
        border-radius: 5px;
        background-color: #999999;
        color: white;
        font-weight: 600;
        text-align: center;
        cursor: pointer;
        margin-right: 20px;
        &:hover {
          background-color: #666666;
        }
      }
      &__search {
        position: relative;
      }
      &__keyword {
        width: 250px;
        height: 34px;
      }
      &__submit {
        position: absolute;
        right: 10px;
        top: 6px;
        cursor: pointer;
      }
      &__title {
        font-size: 28px;
        color: #333;
      }
      &__choose {
        margin-left: 45px;
        font-size: 16px;
        color: #666;
        display: flex;
        align-items: center;
        margin-top: 10px;
        &--status {
          width: 100px;
        }
        &--time {
          width: 105px;
        }
        &--name{
          width: 190px;
        }
        .icon {
          width: 10px;
          height: 5px;
          border: 1px solid #333;
        }
        .icon__deleted{
          margin-right: 5px;
        }
        .icon_sanjiao{
          margin-left: 10px;
        }
      }
      &__list {
        margin-left: 45px;
        font-size: 16px;
        color: #666;
      }
    }

  }
  .works__time{
    display: flex;
    padding-bottom: 15px;
    align-items: center;
    color: #666666;
    &--row{
      margin-right: 10px;
      .rdtTimeToggle{
        display: none;
      }
    }
  }
  .works {
    &__top {
      min-width: 1000px;
      height: 299px;
      background: url("assets/images/works_header_bg.jpg") center center no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .block {
        &__item {
          display: block;
          width: 180px;
          height: 118px;
          border-radius: 4px;
          background-color: #2b9dff;
          box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.17);
          margin: 0 50px;
          color: white;
          .icon {
            margin: 20px auto auto;
          }
          &:hover {
            opacity: 0.9;
          }
        }
        &__name {
          text-align: center;
          font-size: 18px;
          margin-top: 16px;
        }
      }
    }
  }
  .works-list {
    background-color: white;
    padding-bottom: 76px;
    min-height: 600px;
    position: relative;
    .qtn__name--td{
      .qtn__edit{
        display: none;
      }
      &:hover{
        .qtn__edit{display: block}

      }
    }
    .qtn__type--td{
      .qtn__edit{
        display: none;
      }
      &:hover{
        .qtn__edit{display: block}

      }
    }
    .Select-value-label{
      color: #888888 !important;
    }
    .works {
      &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .works__action{
          color: #333333;
          .Select-placeholder{
            color: #333333;
          }
        }

      }
      &__action {
        margin-right: 10px;
        width: 75px;
        display: block;
        color: black;
        text-align: center;
        &--more {
          width: 80px;
          .Select-placeholder {
            color: black;
          }
        }
      }
      &__page {
        width: 100%;
        padding: 0 30px 0 0;
        margin-top: 25px;
        color: #90979b;

      }
    }
    .table {
      tr {
        border: solid 0.5px #cdd2d6;
        height: 52px;
      }
      thead {
        color: #666;
        font-size: 16px;
        th{
          font-weight: 100;
        }
      }
      tbody {
        color: #888;
      }
    }
    .icon_sanjiao{
      margin-left: 10px;
    }
  }
}
.p-works-new {
  width: 1200px;
  margin: auto;
  padding-top: $works-nav-height;
  .project__new--nav{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    ul{
      display: flex;
      li{
        padding: 0 10px;
        color: #999999;
        display: flex;
        align-items: center;
        .li__nav{
          width:28px;
          height: 28px;
          background-color: #d7d9db;
          display: inline-block;
          border-radius: 28px;
          line-height: 28px;
          margin: auto;
          text-align: center;
          margin-right: 5px;
          color: #ffffff;
          font-size: 20px;
          &--active{
            background-color: #999999;
          }
          &--actives{
            color: #444444;
          }
          &--line{
            width: 50px;
            height: 2px;
            background: #d7d9db;
            margin-left: 10px;
            display: inline-block;
          }
        }
      }
    }
  }
  .works {
    &__blank {
      background-color: white;
      padding: 48px 30px;
      line-height: 30px;
      min-height: 700px;
      &--next{
        display: flex;
        justify-content: center;
        margin-top: 20px;
        .btn{
          margin: 0 10px;
          &__back{
            background: #cccccc;
          }
        }
      }
      .work {
        &__submit {
          width: 149px;
          height: 44px;
          line-height: 44px;
          text-align: center;
          border-radius: 4px;
          background-color: #ffac3b;
          color: #fff;
          font-size: 20px;
          font-weight: 500;
          margin: 35px auto auto 20px;
          cursor: pointer;
          &:hover{
            background-color:rgba(255,172,59,0.9)
          }
        }
        &__title--label {
          font-size: 20px;
          color: #333;
        }
        &__title {
          width: 550px;
          height: 50px;
          background-color: #ffffff;
          border: solid 1px #d6d6d6;
          margin-top: 20px;
          margin-bottom: 46px;
          padding: 10px;
          font-size: 16px;
        }
      }
      .create__table{
        color: #666666;
      }
    }
    &__dump {
      .dump {
        &__header {
          border-bottom: 1px solid #999999;
          background-color: white;
        }

        &__text {
          display: flex;
          //margin-top: $works-edit-tabs-height;
          padding: 30px 30px 20px 30px;
          .text {
            &__left {
              width: 40%;
              background-color: white;
              margin-right: 30px;
              margin-top: 9px;
              .left {
                &__label {
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  color: #666666;
                }
                &__input {
                  width: 100%;
                  min-height: 612px;
                  background-color: #f8f9fa;
                  border: solid 1px #dbdee0;
                  /*margin: 15px auto;*/
                  margin: 17px auto;
                  box-sizing: border-box;
                  padding: 5px;
                  color: #666666;

                }
                &__footer {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-top: 20px;
                  .footer {
                    &__left {
                      display: flex;
                      align-items: center;
                      span {
                        color: #999999;
                        font-size: 12px;
                        margin-left: 9px;
                      }
                    }
                    &__upload{
                      font-size: 14px;
                      padding: 0 12px;
                      height: 30px;
                      line-height: 30px;
                    }
                    &__right {
                      color: #40b5ff;
                      font-size: 12px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            &__right {
              flex: 1;
              background-color: white;
              .right {
                &__header {
                  text-align: center;
                  position: relative;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .btn{
                    height: 30px;
                    line-height: 30px;
                    font-size: 14px;
                  }
                  .right {
                    &__preview {
                      position: absolute;
                      top: 0;
                      left: 0px;
                      line-height: 30px;
                      font-size: 14px;
                      height: 30px !important;
                      padding: 0 17px !important;


                    }
                    &__title {
                      color: #666;
                      font-size: 14px;
                      margin-right: 10px;
                    }
                  }
                }
                &__main {
                  width: 100%;
                  min-height: 612px;
                  background-color: #ffffff;
                  border: solid 1px #c0c3c6;
                  box-sizing: border-box;
                  margin-top: 15px;
                  overflow: auto;
                  color: #666666;
                }
              }
            }
          }
        }
        &__epanel {
          padding: 30px;
          background-color: white;
          margin-top: $works-edit-tabs-height;
          min-height: 900px;
          .epanel {
            &__title {
              line-height: 30px;
              display: flex;
              align-items: center;
              margin-top: 20px;
              color: #333;  
              font-size: 20px;
            }
            &__download {
              margin-left: 30px;
              font-size: 12px;
              color: #666;              
            }
            &__input {
              width: 420px;
              height: 40px;
              padding: 10px;
              box-sizing: border-box;
              border: solid 1px #d6d6d6;
              margin-top: 30px; 
              font-size: 14px;

            }
            &__create {
              width: 149px;
              height: 44px;
              border-radius: 4px;
              background-color: #ffad3b;
              color: #fff;
              font-size: 20px;
              line-height: 44px;
              text-align: center;
              margin-left: 20px;
              margin-top: 40px;
            }
            &__upload {
              display: inline-block;
              width: 94px;
              height: 36px;
              border-radius: 4px;
              background-color: #40b5ff;
              color: #fff;
              vertical-align: middle;
              line-height: 36px;
              text-align: center;
              margin-left: 15px;
            }
          }
        }
      }
    }
    &__copy {
      background-color: white;
      padding: 48px 30px;
      min-height: 900px;
      .create__plan--title{
        font-size: 20px;
        color: #333333;
        margin-top: 40px;
        margin-bottom: 20px;
      }
      .copy__input--wrap{
        margin-top: 20px;
        margin-bottom: 46px;
        width: 550px;
        .Select-control{
          width:550px;
          height: 50px;
          .Select-value{
            line-height: 50px;
            &-label{
              color: #999999 !important;
              font-size: 16px;
            }
          }
        }
      }
      .copy {
        &__title {
          line-height: 30px;
          display: flex;
          align-items: center;
          color: #333;
          font-size: 20px;
        }
        &__input {
          width: 550px;
          height: 50px;
          background-color: #ffffff;
          border: solid 1px #d6d6d6;
          padding: 5px;
          color: #666666;
          font-size: 16px;
        }
        &__create {
          color: white;
          display: inline-block;
          margin-top: 35px;
          width: 149px;
          height: 44px;
          border-radius: 4px;
          background-color: #ffad3b;
          text-align: center;
          line-height: 44px;
          &:hover{
            background-color:rgba(255,172,59,0.9)
          }
        }
      }
    }
    &__refer {
      background-color: white;
      padding: 48px 30px;
      min-height: 1200px;
    }
    // shared
    &__categories {  
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 20px;
      margin-top: 15px;
      label {
        font-size: 14px;
        color: #666;
        display: block;
        width: 20%; 
        line-height: 40px;
        input {
          width: 20px;
          height: 20px;
          background-color: #ffffff;
          border: solid 1px #d5d5d5;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }
}
.p-works-refer{
  background-color: white;
  margin-top: 50px;
  .works__refer{
    display: flex;
    .works-list{
      flex: 1;
      padding: 10px 50px;
      color: #666666;
    }
    .account__nav--item{
      padding-left: 0px;
      text-align: center;
    }
    .table{
      padding-bottom: 50px;
      &__span{
        padding: 0 10px;
        text-align: end;
      }
    }
  }
}
