.p-login {
  height: 400px;
  padding: 40px;
  background-color: #ffffff;
  width: 400px;
  margin: auto;
  border-radius: 4px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
  .login {
    &__logo{
      margin-top: 10px;
    }
    &__close{
      position: absolute;
      right: 10px;
      top:10px;
      cursor: pointer;
    }
    &__logo{
      .logo__epanel{
      }
      .ip__epanel{
        font-size: 16px;
        letter-spacing: 2px;
        text-align: center;
        color: #888888;
      }
    }
    &__row {
      margin-top: 23px;
      position: relative;
      .forget_password{
        position: absolute;
        top:0px;
        right: 8px;
        line-height: 46px;
        font-size: 14px;
        color: #888888;
        &:hover{
          color: #5086f8;
        }
      }
    }
    &__num{
      color: #666666;
      &--reg{
        color: #1c1b1b;
      }
    }
    &__input {
      display: block;
      width: 100%;
      padding: 0 12px;
      height: 46px;
      border: solid 1px #d6d6d6;
      font-size: 14px;
      &::placeholder {
        color: #888888;
      }
    }
    &__submit {
      text-align: center;
      line-height: 44px;
      margin-top: 20px;
      cursor: pointer;
      height: 44px;
      border-radius: 4px;
      background-color: #5086f8;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      border: none;
      width: 100%;
      &:hover{
        background-color: rgba(80,134,248,0.9);
      }
    }
    &__footer {
      text-align: center;
      margin-top: 10px;
    }
    &__login {
      font-weight: 600;
      color: black;
    }
  }
  & .login--logo{
    margin: auto;
  }
}
@media screen and (max-width: 320px){
  .p-login{
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 640px) {
  .p-login{
    width: 100%;
  }
}

