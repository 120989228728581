.table {
  border-spacing: 0;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  td,th {
    padding: 0 10px;
    text-align: left;
    height: 52px;
    border-bottom: 1px solid #dbdfe3;
  }
}

.table-center {
  td,th {
    padding: 0 10px;
    text-align: center;
    height: 52px;
    border-bottom: 1px solid #dbdfe3;
  }
}

$border-color: #D5D5D5;
.matrix-table {
  border-spacing: 0;
  background-color: white;
  th,td{
    color: #666666;
  }
  &--wrap {
    max-width: 720px;
    overflow-x: auto;
    position: relative;
    .matrix {
      &__drag {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        .drag {
          &__item {
            position: absolute;
            top: 0;
            height: 100%;
            width: 6px;
            cursor: col-resize;
          }
        }
      }
    }
  }
  &--view {
    overflow-x: scroll;
  }
  td {
    border: 1px solid $border-color;
    border-left: none;
    border-top: none;
    height: 34px;
    &:first-child {
      border-left: 1px solid $border-color;
    }
  }
  thead > tr > td {
    padding: 0px;
    border-top: 1px solid $border-color;
  }

  .matrix {
    &__head {
      text-align: center;
      word-break: break-word;
      img{
        max-width: 100%;
      }
      .editor{
        padding: 3px 0px !important;
      }
    }
    &__td {
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      &--input{
        width: 18px;
        height: 18px;
      }
    }
    &__input {
      width: 60px;
      height: 24px;
      margin-left: 8px;
    }
  }
}