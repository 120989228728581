.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #90979b;
  .page {
    &__center {
      margin: 0 auto;
    }
    &__right {
      display: flex;
      align-items: center;
    }
    &__size {
      width: 60px;
    }
    &__item {
      background-color: #f8f8f8;
      padding: 5px 10px;
      border: 1px solid #ebebeb;
      float: left;
      margin: 0 2px;
      border-radius: 3px;
      cursor: pointer;
      &.disabled {
        cursor: auto;
        border-color: white;
        background-color: #40b5ff !important;
      }
    }
    &__summary{
      color: #888888 !important;
    }
    &__right{
      color: #888888 !important;
    }
    &__item{
      background-color: #f8f8f8;
    }
    .disabled{
      background-color: #40b5ff !important;
    }
  }
  .Select-value-label{
    color: #888888 !important;
  }


}