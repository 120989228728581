.p-multi-scale{
  width: 535px;
  border-top: 1px solid #dbdfe3;
  border-left: 1px solid #dbdfe3;
  margin-top: 20px;
  margin-bottom: 20px;
  th,td{
    height: 35px !important;
    border-right: 1px solid #dbdfe3;
  }
  input{
    border: none;
    outline:none;

  }
  input:focus {
    border: none !important;
  }
}