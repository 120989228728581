.sms{
  &__invition{
    color: #666666;
    .sms__content--row{
      display: flex;
    }
    .content{
      &__titile{
        font-size: 14px;
        line-height: 1.71;
        color: #666666;
        margin-top: 14px;
      }
      &__titile2{
        margin-top: 17px;
        margin-bottom: 7px;
      }
      &__sendtime{
        font-size: 12px;
        line-height: 2;
        color: #888888;
        margin-right: 45px;
      }
      &__sendcount{
        font-size: 12px;
        line-height: 2;
        color: #888888;
      }
      &__contact{
        width: 432.8px;
        height: 80px;
        background-color: #ffffff;
        border: solid 1px #dddddd;
        color: #888888;
      }
      &__smsContent{
        width: 432.8px;
        height: 80px;
        background-color: #ffffff;
        border: solid 1px #dddddd;
        margin-top: 7px;
        color: #888888;
      }
    }
  }
  &__statistics{
    th,td{
      text-align: center;
      font-weight: 100;
    }
    .sms{
      &__count{
        &--title{
          font-size: 12px;
          color: #666666;
          margin-top: 14px;
          margin-bottom: 10px;
        }
        &--title2{
          margin-top: 22px;
          color: #666666;
        }
        &--row{
          display: flex;
          margin-left: 20px;
          color: #888888;
          font-size: 12px;
        }
        &--row2{
          margin-top: 8px;
          margin-bottom: 10px;
          color: #888888;
          font-size: 12px;
          margin-top:20px;
          .sms{
            &__sum{
              float: left;
              margin-right: 13px;
            }
            &__select{
              margin-right: 19px;
              float: left;
            }
            &__btn{
              border-radius: 4px;
              border: solid 1px #40b5ff;
              background: #ffffff;
              font-size: 12px;
              color: #1fa8ff;
              height: 26px;
              float: left;
            }
            &__btn2{
              float: right;
              padding: 0px 7px;
              border-radius: 4px;
              background-color: #40b5ff;
              color: #ffffff;
              font-size: 12px;
              line-height: 26px;
              &:hover {
                background-color:rgba(64,181,255,0.9)
              }
            }
          }
        }
        &--list{
          margin-right: 70px;
        }
        &--number{
        }
      }
      &__progress{
        width: 100px;
        height: 10px;
        background-color: #e3e3e3;
        margin-top: 3px;
        margin-bottom: 9px;
        &--color{
          background-color: #a4dd76;
          height: 10px;
        }
      }
    }
    width: 785px;
    min-height: 400px;
    max-height: 400px;
    overflow: auto;
    .statistics{
      &__tr{
        width: 720px;
        height: 52px;
        &__title{
          background-color: #e5e7e9;
          .status__select{
            .Select-value-label{
              margin-left: -17px;
            }
          }
        }
        &__data{
          background-color: #ffffff;
        }
      }
      &__td{
        width: 48px;
        height: 24px;
        font-family: PingFang-SC;
        font-size: 12px;
        line-height: 2;
        text-align: center;
        &__ok{
          color: #10cca9;
        }
        &__no{
          color: #a9a9a9;
        }
      }
    }
    .invition__page{
      width: 100%;
      padding: 0 30px 0 0;
      margin-top: 25px;
    }
    .table__input{

    }
    .error__info{
      //width: 280px;
    }
    .check__width{
      width: 33px;
    }
    .seq__width{
      width: 48px;
    }
  }
}
.sms_checkbox{
  margin:5px 5px 0 10px;
}
.sms__count__div{
  font-size:12px;
}
.sms__count__btn{
  border-radius: 4px;
  border: solid 1px #40b5ff;
  background: #ffffff;
  font-size: 12px;
  color: #1fa8ff;
  height: 26px;
  margin-left:10px;
}