.p-multi-answer{
  padding: 13px 20px 0 20px;
  width: 745px;
  max-height: 400px;
  overflow: auto;
  & .epanel__info{
    border-bottom: solid 0.5px #cdd2d6;
    padding-bottom: 15px;
    &--title{
      font-size: 14px;
      line-height: 1.71;
      text-align: left;
      color: #666666;
    }
    &--list{
      display: inline-block;
    }
    &--row{
      font-size: 12px;
      line-height: 28px;
      color: #888888;
    }
    &--right{
      margin-right: 90px;
    }
  }
  & .answer__info{
    margin-top: 15px;
    margin-bottom: 20px;
    &--title{
      font-size: 14px;
      text-align: left;
      color: #666666;
    }
    & .answer__qtn{
      font-size: 12px;
      color: #888888;
      margin-top: 8px;
      & .qtn__opt{
        margin-left: 10px;
        line-height: 24px;
        display: inline-block;
      }
    }
  }
}