.sortable-item {
  background-color: #ffffff;
  border: solid 1px #e5e7e9;
  margin-top: 10px;
  padding: 7px 11px 7px 15px;
  display: flex;
  align-items: center;
  &--active {
    border-color: #40B5FF;
    .icon-answer-drag {
      background: url("assets/images/icons/qtn/hand1.png") center center /cover no-repeat;
    }
  }
  .sort {
    &__top {
      display: flex;
      align-items: center;
      min-height: 28px;
      flex: 1;
    }
    &__sn {
      font-size: 20px;
      font-weight: 600;
      color: #40B5FF;
      opacity: 0.6;
      padding-right: 10px;
    }
    &__label {
      flex: 1;
      word-break: break-all;
      color: #666666;
    }
    &__drag {
      display: flex;
      width: 32px;
      margin-left: 10px;
      cursor: pointer;
      .icon-large{
        width: 32px;
        height: 32px;
      }
    }
  }
}