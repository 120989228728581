.p-works-analyze{
  padding: $works-nav-height + $works-edit-tabs-height 0 0px 0;
  button{
    cursor: pointer;
  }
  .table{
    table-layout: fixed;
    .td-center{
      text-align: center;
    }
    & thead,tbody{
      font-size: 14px;
      color: #666666;
      .sms__td--gree{
        color: #10cca9;
      }
      .sms__td--orange{
        color: #ffac3b;
      }
    }
    & th{
      background-color: #e5e7e9;
      border:none;
      padding: 0 15px;
    }
    & th,td{
      line-height: 52px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .answering{
      color: #ffac3b;
    }
    .finish{
      color: #10cca9;
    }
    .other{
      color: #bbbbbb;
    }
  }

/*回收进度样式*/
  .tab-progress--wrap{
    .progress{
      &__list{
        margin-top: 60px;
        border-bottom: 1px solid #ebeef0;
        padding-bottom: 30px;
        &--one{
          float: left;
          margin-right: 20px;
          width: 204px;
          height: 152px;
          border: solid 1px #40b5ff;
          margin-bottom: 20px;
        }
      }
      &__left{
        width: 50%;
        float: left;
      }
      &__title{
        &--list{
          font-size: 14px;
          color: #666666;
          margin: 12px 0px 27px 15px;
        }
        &--quota{
         /* margin-top: 60px;*/
          margin-top: 30px;
          margin-bottom: 20px;
          font-size: 22px;
          letter-spacing: 0.9px;
          color: #333333;
        }
      }
      &__num{
        font-size: 30px;
        text-align: center;
        color: #24aaff;
        &--small{
          font-size: 18px;
        }
      }
      &__right{
        width: 50%;
        float: right;
      }
      &__chart {
        height: 322px;
      }
      &__center {
        font-size: 20px;
        text-align: center;
        margin-top: 143px;
        color: #666666;
      }
      &__td{
        &--color{
          color: #bbbbbb;
        }&--finish{
           color: #10cca9;
         }
      }
    }
  }
/*样本数据样式*/
.tab-data--wrap{
  .tab-data--bottom{
    padding-bottom: 50px;
    border-bottom: 1px solid #ebeef0;
  }
.data{
  &__top{
    margin-top: 55px;
    .Select{
      &-control{
        height: 36px;
      }
      &-value-label{
        line-height: 36px;
      }
    }
  }
  &__title{
    float: left;
    font-size: 20px;
    letter-spacing: 0.8px;
    color: #333333;
    &--small{
      font-size: 14px;
      letter-spacing: 0.6px;
      color: #999999;
    }
  }
  &__export{
    float: right;
    width: 170px;
    height: 36px;
  }
  &__filter{
    &--top{
      margin-top: 44px;
    }
    &--top1{
      margin-top: 34px;
    }
    .time__input{
      width: 143px;
      height: 36px;
      border: solid 1px #d6d6d6;
    }
    /*margin-top: 30px;*/
    margin-top: 30px;
    &:first-child {
      margin-top: 48px;
    }
    font-size: 14px;
    color: #888888;
    display: flex;
    .filter__title{
      margin-right: 8px;
      line-height: 36px;
      &--float{
        float: left;
      }
    }
    & .answer-select{
      width: 143px;
      height: 36px;
      float: left;
      .Select-value-label{
        color: #888888 !important;
      }
      .Select{
       &-control{
         height: 36px;
       }
      &-value{
        line-height: 36px;
      }
      }
    }
    .filter__select{
      color: #888888;
      width: 143px;
      height: 36px;
    }
    .filter__padding{
      padding: 0 8px;
      margin-top: 7px;
    }
    .filter__btn{
      width: 110px;
      border-radius: 4px;
      height: 34px;
      font-size: 14px;
      color: #ffffff;
      border: none;
      display: inline-block;
      padding: 0 0;
    }
  }
  &__answer{
    margin-top: 29px;
    margin-bottom: 20px;
    & .answer__title{
      float: left;
      font-size: 22px;
      letter-spacing: 0.9px;
      color: #333333;
    }
    & .answer__search{
      float: right;
      position: relative;
      & .search__input{
        width: 315px;
        height: 34px;
        border: solid 1px #d6d6d6;
        font-size: 14px;
        line-height: 34px;
        color: #999999;
        padding-left: 8px;
      }
      & .icon__search{
        position: absolute;
        right: 6px;
        top: 0px;
        bottom: 0px;
        margin: auto;
      }
    }
  }
  &__table{
    min-height: 505px;
    position: relative;
    .table__btn{
      border: none;
      color: #333333;
      background: none;
      font-size: 14px;
      &--hand{
        cursor: pointer;
      }
    }
    .table__td--orange{
      color: #ffac3b;
    }
    .table__td--green{
      color: #10cca9;
    }
  }
}
}

/*图表分析样式*/
  .tab-chart--wrap{
    & .chart__filter--top{
      width: 1000px;
      padding-bottom: 50px;
      border-bottom: 1px solid #ebeef0;
    }
    .chart__export{
      float: right;
      width: 157px;
      border-radius: 4px;
      border: solid 1px #40b5ff;
      font-size: 14px;
      line-height: 36px;
      color: #26abff;
      background: none;
    }
    & .filter-table{
      margin-top: 30px;
      & .qtn__list{
        margin-top: 20px;
      }
      & .count__qtn{
        font-size: 16px;
        line-height: 34px;
        color: #666666;
        width: 700px;
        float: left;
        margin-bottom: 20px;
      }
      & .chart__qtn{
        font-size: 14px;
        color: #666666;
        float: right;
        width: 120px;
        .Select{
          &-control{
            height: 36px;
          }
          &-value{
            line-height: 36px;
          }
        }
      }
      & .answer__num{
        font-size: 14px;
        line-height: 52px;
        text-align: left;
        color: #666666;
        box-shadow: 0 1px 0 0 #e5e7e9;
      }
      & .count-table{
        & tbody tr{
          &:hover{
            background-color: #e5e7e9;
          }
        }
      }
    }
  }
  & .works__page{
    width: 100%;
    padding: 0 30px 0 0;
    margin-top: 31px;
    margin-bottom: 76px;
    color: #90979b;
    & .pagination{
      margin: auto;
    }
  }
.pick__arrange{
  display: inline-block;
  margin-left: 13px;
}
.tab-code--wrap{
  margin-bottom: 30px;
    .code__qtn{
      margin-top: 55px;
      &--title{
        font-size: 16px;
        line-height: 34px;
        color: #666666;
        width: 700px;
        float: left;
        margin-bottom: 20px;
      }
      .input__code{
        width: 100%;
        height: 30px;
        border: 1px solid #cccccc;
        color: #666666;
        padding-left: 10px;
      }
    }
  .table{
    border-left: 1px solid #dbdfe3;
    border-top: 1px solid #dbdfe3;
    td{
      border-right: 1px solid #dbdfe3;
    }
  }
  }
  .code__buy{
    width: 320px;
    height: 300px;
    border: 1px solid #cccccc;
    margin: auto;
    padding: 20px 30px;
    border-radius: 8px;
    margin-top: 80px;
    &--btn{
      text-align: center;
      margin: auto;
      margin-top: 20px;
    }
    &--back{
      width: 100px;
      height: 100px;
      background: #e8e8e7;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      margin-bottom: 20px;
    }
  }
  .code__status{
    display: flex;
    width: 100%;
    background: red;
    padding: 8px 10px;
    background-color: rgba(255, 235, 174, 1);
    align-items: center;
    margin-top: 35px;
    position: relative;
    .icon-close{
      position: absolute;
      right: 10px;
      top: 0px;
      bottom: 0px;
      margin: auto;
    }
    .btn{
      margin-left: 20px;
    }
  }
}