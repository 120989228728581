.p-reset_pass {
  background-color: white;
  padding: 30px;
  width: 100%;
  margin: 60px auto;
  font-family: PingFang-SC;
  .pass {
    &__title {
      font-family: PingFang-SC;
      font-size: 26px;
      letter-spacing: 1px;
      text-align: center;
      color: #777777;
      margin: 23px auto;
    }
    &__row {
       position: relative;
      margin-bottom: 30px;
       .message--box {
         position: absolute;
         top: 13px;
         left: 51%;
         transform: translateX(50px);
         color: #1f1f1f;
         cursor: pointer;
         width: 90px;
         text-align: right;
         &__show {
           margin-left: 5px;
         }
       }
     }
    &__input {
      display: block;
      width: 320px;
      background-color: #ffffff;
      border: 1px solid #d6d6d6;
      padding: 0 10px;
      height: 46px;
      line-height: 46px;
      margin: 0 auto;
      color: #888;
      font-size: 14px;
    }
    &__save {
      width: 320px;
      height: 46px;
      line-height: 46px;
      border-radius: 4px;
      background-color: #ffac3b;
      color: white;
      text-align: center;
      margin: 0 auto;
      font-size: 20px;
      cursor: pointer;
      &:hover{
        background-color:rgba(255,172,59,0.9)
      }
    }
    &__login {
      width: 320px;
      height: 46px;
      line-height: 46px;
      border-radius: 4px;
      background-color: #5186f8;
      color: white;
      text-align: center;
      margin: 36px auto 0;
      font-size: 20px;
      cursor: pointer;
      &:hover{
        background-color: rgba(81,134,248,0.9);
      }
    }
  }
  .success {
    &__info {
      /*width: 300px;*/
      position: relative;
      padding-top: 150px;
      text-align: center;
      margin: auto;
      &--title {
        padding-left: 20px;
        font-size: 26px;
        color: #666;
      }
      &--prompt {
        font-size: 14px;
        color: #666;
        margin-top: 10px;
      }
      &--text{
        padding: 0 60px;
      }
      &--company{
        width: 625px;
        padding-top: 75px !important;
      }
      .over__warn--icon{
        width: 36px;
        height: 36px;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .p-reset_pass{
    color: red;
    .success__info--company{
      width: 320px !important;
      .register-pass-position{
        left: -10px;
      }
    }
  }
}