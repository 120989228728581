.p-multi-project{
  width: 640px;
  padding: 20px;
  .multi__project{
    &--list{
      margin-bottom: 30px;
    }
    &--li{
      float: left;
      width: 300px;
      line-height: 30px;
      & span{
        margin-left: 5px;
      }
    }
  }
}

.p-multi-team{
  padding: 0px 20px;
  width: 430px;
  height: 307px;
  & input{
    padding-left: 10px;
  }
  .multi__team--main{
    height: 230px;
  }
  .multi__team{
    &--list{
      margin-left: 45px;
      margin-top: 20px;
    }
    &--row{
      line-height: 50px;
      input{
        width: 220px;
        height: 38px;
        margin-left: 10px;
        border: 1px solid #dddddd;
      }
    }
  }
  .save__btn{
    float: right;
  }
  .add__btn{
    width: 75px;
    line-height: 30px;
    cursor: pointer;
    margin-right: 15px;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
    display: inline-block;
    color: #ffffff;
    font-size: 16px;

  }
  .multi__add--search{
    margin-top: 30px;
    text-align: center;
    height: 154px;
    input{
      width: 220px;
      height: 38px;
      border: 1px solid #dddddd;
    }
    .search__btn{
      width: 50px;
      height: 38px;
      border: none;
      background-color: #ffac3b;
      color: #ffffff;
    }
    .search__result{
      color: #666666;
      margin-top: 15px;
      padding: 0 60px;
      text-align: left;
    }
    .add__contacts{
      margin-top: 100px;
      text-align: right;
      float: right;
    }
  }
  .multi__add--show{
    display: none;
  }
}
.p-multi-create{
  padding: 0 20px;
  width: 500px;
  .create{
    &__select{
      width: 280px;
      margin-top: 15px;
      .Select{
        &-placeholder{
          line-height: 30px;
        }
        &-control{
          height: 30px;
        }
        &-clear{
          display: none;
        }
      }
      &--row{
        margin-top: 20px;
      }
      &--title{
        font-size: 20px;
      }
    }
    &__label{
      margin-top: 20px;
      &--title{
        font-size: 20px;
      }
      &--row{
        line-height: 30px;
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
      &--rows{
        display: flex;
      }
    }
    &__task{
      padding-bottom: 20px;
      tr{
        line-height: 30px;
      }
      td{
        padding-right: 25px;
      }
      &--title{
        font-size: 20px;
        margin-top: 15px;
      }
    }
    &__table{
      margin-top: 20px;
      .td__back{
        background: #ffac3b;
        padding: 3px 10px;
        position: relative;
        .deleted__icon{
          position: absolute;
          display: block;
          right: -10px;
          top: -8px;
        }
      }
    }
  }
}
.p-multi-type{
  width: 650px;
  padding: 0 20px;
  .multi__label{
    padding-top: 20px;
    font-size: 16px;

  }
  .works__categories{
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;
    &--label{
      width: 25%;
      line-height: 40px;
    }
  }
}
.p-multi-abutment{
  padding: 0 20px;
  .abutment__table{
    margin-top: 20px;
    border: 1px solid #dbdfe3;
    border-right: none;
    border-bottom: none;
    tr{
      line-height: 40px;
    }
    td{
      border-right: 1px solid #dbdfe3;
      height: 40px;
    }
  }
}
.p-multi-count{
  table{
    border-top: 1px solid #dbdfe3;
    border-left: 1px solid #dbdfe3;
    margin-top: 20px;
  }
  td{
    border-right: 1px solid #dbdfe3;
  }
  .Select{
    &-placeholder{
      line-height: 30px;
    }
    &-control{
      height: 30px;
    }
    &-clear{
      display: none;
    }
  }
  width: 700px;
  margin: 0 20px;
  color: #666666;
  .count{
   &__title{
     font-size: 20px;
     margin-top: 20px;
   }
    &__row{
      display: flex;
      align-items: center;
      margin-top: 20px;
      .btn__right{
        float: right;
        height: 30px;
        line-height: 30px;
        margin-left: 240px;
      }
      .btn__send{
        background: none;
        color: #00afec;
        margin-left: 20px;
        border: none;
        cursor: pointer;
      }
    }
    &__select{
      width: 100px;
    }
    &__num{
      margin-left: 10px;
    }
  }
}
.p-multi-hand{
  padding: 0 20px;
  height: 400px;
  overflow-y: auto;
}
.p-multi-handsms{
  padding: 20px;
}