.check_target_div{
  width:1274px;
  height:396px;
  margin:126px auto;
  .check_target_box{
    /* position: absolute;
     left: 0px;
     top: 0px;*/
    width: 1274px;
    height: 51px;
    margin:0 auto;
    background: inherit;
    background-color: rgba(242, 242, 242, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    border-radius: 0px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    .check_target_span{
      position: absolute;
      left:1000px;
      top:15px;
      .check_target_span1{
        color:#40b5ff;
      }
    }
  }
  .check_target_table{
    width:100%;
    text-align: center;
    border-spacing: 0;
    .check_target_tr{
      border-bottom: 1px solid #ccc;
      height:59px;
      line-geight:59px;
    }
  }
}
