.modal {
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    pointer-events: auto;
    z-index: 110;
  }
  &__content {
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 1px 15px 0 rgba(199, 199, 199, 0.5);
    border-radius: 0px;
    padding: 0 20px 30px 20px;
    outline: 0;
    color: #666666;
  }
  &__title {
    height: 44px;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    color: #333333;
    border-bottom: solid 1px #cdd2d6;
  }
  &__close {
    position: absolute;
    color: #666;
    font-size: 22px;
    top: 14px;
    right: 0px;
    cursor: pointer;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      &:not(:first-child) {
        margin-left: 21px;
      }
    }
    &--left {
      justify-content: flex-start;
      .btn {
        &:first-child {
          margin-left: 10px;
        }
      }
    }
  }
}
.p-confirm1{
  width:680px;
  height:400px;
  .message{
    line-height:50px;
    color:red;
  }
  .Audit_div{
    width:662px;
    .Audit_table{
      width: 662px;
      height: 44px;
      background: inherit;
      background-color: rgba(242, 242, 242, 1);
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(228, 228, 228, 1);
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .Audit_table1{
      width: 662px;
      background: inherit;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(228, 228, 228, 1);
      border-left: 0px;
      border-top: 0px;
      border-right: 0px;
      border-radius: 0px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-collapse: collapse;
      tr td{
        height:40px;
        border-bottom: 1px solid #ccc;
        text-align:center;
      }
    }
  }
  .confirm__message{
    position:absolute;
    left:243px;
    top:393px;
  }
}
.SaveQtn_table{
  margin:10px auto;
  width:100%;
  border: 1px solid #ccc;
  border-collapse:collapse;
  tr{
    width:100%;
  }
tr td{
  border: 1px solid #ccc;
  text-align:center;
}
}
.updata_box{
  width:649px;
  height:507px;
  .updata_div{
  width:628px;
  margin:10px auto;
    .upload_text{
      position: absolute;
      left: 151px;
      top: 48px;
      width: 244px;
      height: 35px;
      font-family: 'Arial Normal', 'Arial';
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
      text-decoration: none;
      color: #000000;
      text-align: left;
      border:1px solid #ccc;
      line-height:35px;
    }
    .upload_data{
      position: absolute;
      left: 407px;
      top: 47px;
      width: 83px;
      height: 35px;
      line-height:35px;
      background: inherit;
      background-color: inherit;
      background-color: rgba(255, 153, 0, 1);
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(255, 255, 255, 1);
      border-radius: 5px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-align:center;
    }
}
  .updata_tabs{
    width:628px;
    margin:10px auto;
  }
  .modal__footer{
    position:absolute;
    left:256px;
    top:478px;
  }
}
.Complaints_Box{
    margin-left: 88px;
    visibility: visible;
    z-index: 1006;
  .complaints_div{
      width: 320px;
      font-size:12px;
      cursor: pointer;
      padding: 3px 8px 3px 8px;
      margin: 5px;
      background: #EEE none repeat scroll 0% 0%;
      border: 1px solid #AAA;
      border-radius: 3px;
    }
}
.AuditFeedBack_table{
  width:650px;
  border: 1px solid #ccc;
  border-collapse:collapse;
  tr td{
    border: 1px solid #ccc;
    text-align:center;
  }
}
.upload_image{
/* z-index: 1000;
  position: absolute;
  left: 161px;
  top: 6px;*/
  width: 16px;
  height: 16px;
}
.input_checkbox{
 margin-left:5px;
  z-index:1000;
  position:absolute;
  width:15px;
  height:15px;
}