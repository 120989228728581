.p-multi-copy{
  .question{
    &__copy{
      &--title{
        font-size: 20px;
        margin-top: 20px;
      }
      &--input{
        width: 320px;
        height: 35px;
        margin-top: 10px;
        padding-left: 5px;
        border: 1px solid #d6d6d6;
        color: #666666;
      }
      &--select{
        font-size: 20px;
        margin-top: 25px;
      }
    }
  }
  .works__categories{
    width: 500px;
    margin-top: 8px;
    margin-bottom: 20px;
    &--label{
      width: 33%;
      line-height: 30px;
      display: inline-block;
      color: #666666;
      & input{
        margin-right: 10px;
      }
    }
  }

}