@import "variables";

body {
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-family: "PingFang SC", Microsoft Yahei,Helvetica, Arial, sans-serif !important;
  position: relative;
  z-index: $body-index;
  background-color: white;
}

h1, h4, h5, h6, p, ul, li, textarea, input{
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: inherit;
  font-family: "PingFang SC", Microsoft Yahei,Helvetica, Arial, sans-serif !important;
}
h2,
h3 {
  orphans: 3;
  widows: 3;
}
h2,
h3 {
  page-break-after: avoid;
}
h2,
h3 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

header, section, footer, nav {
  display: block;
}

img {
  border: none
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: $baby-blue-color;
}
blockquote {
  page-break-inside: avoid;
}
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}
ul {
  list-style: none
}

* {
  box-sizing: border-box
}

textarea {
  resize: none;
}

input:focus,
select:focus,
textarea:focus,
[contenteditable]:focus,
button:focus {
  outline: none;
}

.ReactModal__Body--open {
  overflow: hidden
}

.e-opt {
  fill: rgba(0,0,0,0);
  cursor: move;
}

.clear-fix:after{
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
button{
  font-family: "PingFang SC", Microsoft Yahei,Helvetica, Arial, sans-serif !important;
}
textarea { -webkit-appearance:none;border-radius:0;}
.data_set_answer{
  margin-top:47px;
}