//图片热点题画布
.hot-canvas {
  position: relative;
  .canvas {
    &__img {
      position: absolute;
      right: -26px;
      padding: 0 0 20px 10px;
      top: 0;
      display: none;
      .icon-editor-img {
        input {
          display: none;
        }
      }
    }
    &__label {
      position: absolute;
      display: flex;
      background-color: #2a70f3;
      color: white;
      padding-left: 3px;
      width: 248px;
      .label {
        &__text {
          width: 44px;
        }
        &__input {
          border: 1px solid #ccc;
          width: 100%;
          &--wrap {
            width: 100px;
          }
        }
        &__mutex {
          display: flex;
          input{
            margin-top: 3px;
          }
        }
      }
    }
  }
  &:hover {
    .canvas {
      &__img {
        display: block;
      }
    }
  }
}