
.editor {
  border: solid 1px transparent;
  padding: 3px 5px 3px 5px;
  position: relative;
  color: #666666;
  z-index: 1;
  word-break: break-all;
  .ql-indent{
    &-1{
      padding-left: 3em;
    }
    &-2{
      padding-left: 6em;
    }
    &-3{
      padding-left: 9em;
    }
    &-4{
      padding-left: 12em;
    }
    &-5{
      padding-left: 15em;
    }
    &-6{
      padding-left: 18em;
    }
    &-7{
      padding-left: 21em;
    }
    &-8{
      padding-left: 24em;
    }
  }
  &-m{
    padding: 6px 5px;
    &:hover{
      padding: 6px 5px;
    }
  }
  &-border{
    border: 1px solid #ccc;
  }
  &:hover,
  &.editing {
    border-color: #6dbbff;
  }

  &.editing {
    z-index: 10;
    background: #ffffff;
  }

  .toolbar {
    position: absolute;
    top: -36px;
    height: 36px;
    background-color: #ffffff;
    border: solid 1px #b2b9c4;
    display: flex;
    align-items: center;
    padding: 0 16px;
    .icon {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }

    &__bottom {
      bottom: -36px;
      top: auto;
    }
    &__right {
      right: 5px;
    }

    &__more {
      margin-left: 10px;
    }
    .more {
      &__wrap {
        position: absolute;
        left: -1px;
        top: 34px;
        width: 180px;
        border: 1px solid #b2b9c4;
        background-color: white;
        padding: 10px;
      }
      &__row {
        display: flex;
        align-items: center;
        &--indent {
          padding-left: 10px;
        }
        &:not(:first-child) {
          margin-top: 7px;
        }
      }
      &__checkbox {
        margin-right: 8px;
      }
      &__label {
        font-size: 12px;
        color: #999999;
      }
    }
  }

  .icon-editor-img {
    input[type=file] {
      display: none;
    }
  }

  img {
    max-width: 200px;
  }
}