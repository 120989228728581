// layout related css, for responsive pages

.clearfix:after {
  content: "";
  clear: both;
  height: 0;
  display: block;
  zoom: 1
}

* html .clearfix {
  height: 1%;
}

.container {
  width: 1200px;
  margin: 0 auto;
  &__project{
    width: 1200px;
    margin: 0 auto;
  }
  &__txt{
    width: 100%;
  }
}