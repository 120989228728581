.p-multi-preview{
  max-height: 400px;
  overflow: auto;
  color: #666666;
}
.p-multi-home{
  display: none;
  width: 590px;
  padding: 20px 20px 0px 20px;
  background: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  margin: auto;
  bottom: 0px;
  right: 0px;
  height: 230px;
  .back__modal--show{
    display: block;
  }
  .multi__title{
    border-bottom: 1px solid #cccccc;
    padding-bottom: 10px;
  }
  .close__modal{
    position: absolute;
    top: 15px;
    right: 18px;
    font-size: 18px;
  }
  .multi{
    &__home--title{
      text-align: center;
      line-height: 24px;
      margin-top: 10px;
    }
    &__home--icon{
      display: flex;
      margin-top: 15px;
      .icon__size{
        width: 90px;
        height: 90px;
        background-size: cover;
        display: block;
        margin: 0px 10px;
      }
    }
  }
}
.p-multi-url{
  padding: 20px;
}