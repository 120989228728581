.p-works-data{
  padding: $works-nav-height + $works-edit-tabs-height 0 50px 0;
  .container__left{
    &--sms{
      margin: 0px auto auto 30px;
    }
    th{
      font-weight: 100;
    }
    width:1000px;
    margin: 0px auto;
    min-height: 600px;
    .container__left--bottom{
      padding-bottom: 30px;
      border-bottom: 1px solid #ebeef0;
      .quota__set--row{
        margin-top: 25px;
        color: #666666;
        display: flex;
        align-items: center;
        .num__input--more{
          width: 300px;
        }
        .label__textarea{
          min-width: 300px;
          min-height: 100px !important;
          color: #999999;
          padding: 5px 10px;
          border: 1px solid #d6d6d6;
        }
        .num__input--spac{
          padding: 0 8px;
          line-height: 36px;
        }
        .limit__num{
          line-height: 36px;
        }
        .hand__row--span{
          margin-right: 30px;
          display: flex;
          align-items: center;
          input{
            margin-right: 5px;
          }
        }
      }
    }
    .sms__edit{
      margin-left: 10%;
    }
    .sms__order{
      margin-left: 10%;
    }
  }
  button{
    cursor: pointer;
  }
  input{
    padding-left: 8px;
  }
  .sms__nav--none{
    display: none !important;
  }
  .sms{
    &__nav{
      display: flex;
      ul{
        margin: 10px auto 47px;
      }
      .Offline__title{
        display: flex;
        margin-left: 210px;
        &:hover{
          .Offline__code{
            display: block;
          }
        }
        &--row{
          position: relative;
          display: flex;
          padding-bottom: 20px;
          .link__title--small{
          border: 1px solid #ccc;
          height: 40px;
          line-height: 40px;
          padding: 0px 10px;
        }
          .Offline__code{
            position: absolute;
            top: 0px;
            right: -120px;
            display: none;
            z-index: 10;
            padding-left: 20px;
          }
        }
        &--span{
          line-height: 40px;
          color: #40b5ff;
        }
      }
    }
    &__li{
      color: #999999;
      float: left;
      padding: 0 5px;
      font-size: 14px;
      line-height: 28px;
      display: flex;
      align-items: center;
      &--active{
        color: #444444;
      }
      &--line{
        width: 50px;
        height: 2px;
        background: #d7d9db;
        margin-left: 10px;
      }
    }
    &__num{
      display: inline-block;
      width: 28px;
      height: 28px;
      background-color: #d7d9db;
      border-radius: 28px;
      font-size: 20px;
      letter-spacing: 0.8px;
      color: #ffffff;
      line-height: 28px;
      margin-right: 9px;
      text-align: center;
      &--active{
        background-color: #999999;
      }
    }
    &__left{
      width: 160px;
      min-height: 650px;
      background-color: #f4f7f8;
      .group{
        &__title{
          width: 160px;
          height: 41px;
          line-height: 41px;
          background-color: #e5e7e9;
          .contact{
            width: 42px;
            height: 24px;
            font-size: 14px;
            line-height: 1.71;
            text-align: left;
            color: #666b73;
            margin-left: 32px;
            margin-top: 10px;
          }
          .manage{
            height: 24px;
            font-size: 14px;
            color: #40b5ff;
            float: right;
            margin-right: 15px;
          }
        }
        &__names{
          width: 160px;
          padding-left: 15px;
          padding-right: 20px;
          padding-top: 6px;
          table-layout: fixed;
          .sms__name{
            width: 100px;
          }
          & tr{
            line-height: 30px;
            td{
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: #555555;
            }
            input{
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  .table{
    table-layout: fixed;
    & thead,tbody{
      font-size: 14px;
      color: #666666;
      .sms__td{
        &--gree{
          color: #10cca9;
        }
        &--yellow{
          color: #ffac3b;
        }
        &--gray{
          color: #bbbbbb;
        }
      }
      .sms__td--handle{
        overflow: inherit;
      }
      .sms__td--orange{
        color: #ffac3b;
      }
    }
    & th{
      background-color: #e5e7e9;
      border:none;
    }
    & th,td{
      line-height: 52px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 52px;
      color: #666666;
    }
  }
  .data__set--list{
    & .ip__input--height{
      height:40px;
    }
    position: fixed;
    right: 0px;
    top: 122px;
  }
.data__set--top{
  .icon__set{
    float: left;
    width: 42px;
    height: 42px;
    background-color: #eff2f4;
  }
  .set {
    &__title {
      float: left;
      width: 220px;
      height: 42px;
      background-color: #c4c9cf;
      font-size: 14px;
      line-height: 42px;
      text-align: center;
      color: #666b73;
      &--width {
        width: 120px;
      }
    }
  }
}
  .works__set--none{
    display: none;
  }
  .works__data--set{
    transition-delay: 2s;
    width: 220px;
    height: 650px;
    background-color: #f4f7f8;
    .data__set{
      position: relative;
    }
    .icon__set{
      position: absolute;
      left: -42px;
      width: 42px;
      height: 42px;
      background-color: #eff2f4;
    }
    .set{
      &__title{
        width: 220px;
        height: 42px;
        background-color: #c4c9cf;
        font-size: 14px;
        line-height: 42px;
        text-align: center;
        color: #666b73;
        &--width{
          width: 120px;
        }
      }
      &__list{
        font-size: 14px;
        color: #555555;
        margin-top: 10px;
        margin-left: 43px;
        &--top{
          margin-top: 30px;
          margin-bottom: 20px;
        }
        & .ip__input{
          width: 60px;
          height: 30px;
        }
      }
      &__btn{
        width: 73px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 30px;
        color: #ffffff;
        margin-top: 27px;
        border: none;
        margin-left: 43px;
      }
    }
  }

  .btn__next{
    width: 100px;
    height: 36px;
    border-radius: 4px;
    background-color: #40b5ff;
    font-size: 16px;
    line-height: 36px;
    color: #ffffff;
    border: none;
    cursor: pointer;
    &--right{
      float: right;
    }
    &--left{
      background-color: #ffffff;
      color: #333333;
      width: 87px;
    }
    &--forbid{
      float: right;
      background-color: #dbdbdb;
    }
    &--color{
      background-color: #ffffff;
      color: #333333;
      margin-left: 500px;
      margin-top: 30px;
    }
    &--top{
      margin-top: 30px;
    }
    &--top2{
      margin-top: 30px;
      background-color: #dbdbdb;
    }
  }
  input{
    vertical-align: middle;
  }
  .tab{
    &__collect{
      display: block;
      margin-top: 140px;
      text-align: center;
      font-size: 22px;
      letter-spacing: 0.9px;
      color: #666666;
      &--template{
        margin-top: 40px !important;
      }
      .btn{
        &__collect{
          width: 119px;
          height: 46px;
          border-radius: 4px;
          background-color: #ffac3b;
          border: none;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
          margin: 0 10px;
          &:hover{
            background-color:rgba(255,172,59,0.9)
          }
          &--template{
            background: #10cca9;
            &:hover{
              background-color:rgba(16,204,169,0.9)
            }
          }
        }
      }
    }
  }
  /*问卷链接style*/
  .tab-link--wrap {
    margin-top: 47px;
    .link {
      &__main {
        margin-top: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebeef0;
        padding-bottom: 42px;
      }
      &__title {
        font-size: 20px;
        letter-spacing: 0.8px;
        color: #333333;
        &--small {
          font-size: 14px;
          line-height: 1.71;
          color: #666666;
        }
      }
      &__text {
        display: inline-block;
        width: 438px;
        height: 50px;
        background-color: #ffffff;
        border: solid 1px #d6d6d6;
        line-height: 50px;
        font-size: 14px;
        text-indent: 14px;
        color: #888888;
      }
      &__shared {
        display: inline-block;
        margin-left: 28px;
      }

    }
    .btn__copy {
      display: inline-block;
      width: 73px;
      height: 36px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 36px;
      color: #ffffff;
      margin-left: 15px;
      margin-right: 28px;
      border: none;
    }
    .code {
      &__qtn {
        margin-top: 36px;
      }
      &__title {
        font-size: 22px;
        letter-spacing: 0.9px;
        color: #333333;
      }
      &__patent{
        margin-top: 25px;
      }
      &__icon--patent{
        background: url("assets/images/icons/data/patent.png") center center /contain no-repeat;
        width: 45px;
        height: 42px;
        display: block;
        float: left;
        margin-right: 10px;
        margin-top: 3px;
      }
      &__patent--text{
        color: #666666;
        line-height: 24px;
      }
      &__link{
        color: #666666;
        &:hover{
          text-decoration: underline;
        }
      }

      &__img {
        float: left;
        margin-top: 25px;
        &--size {
          width: 220px;
          height: 220px;
          padding: 20px;
          border: solid 1px #d6d6d6;
        }
      }
      &__right {
        width: 520px;
        float: left;
        margin-top: 45px;
        margin-left: 45px;
      }
      &__introduce {
        font-size: 14px;
        line-height: 2.29;
        color: #666666;
      }
      &__download {
        width: 110px;
        height: 36px;
        border-radius: 4px;
        border: none;
        margin-top: 52px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.6px;
        color: #ffffff;
      }
    }
  }
  /*短信邀请style*/
  .tab-sms--wrap{
    margin-top: 41px;
    display: flex;
    .sms{
      &__table{
        min-height: 400px;
        input{
          width: 16px;
          height: 16px;
        }
      }
      &__btn{
        padding: 8px 15px;
        background:  #ffac3b;
        border: none;
        margin-right: 15px;
        border-radius: 4px;
        color: #ffffff;
        margin-bottom: 20px;
        font-size: 14px;
        &--hand{
          cursor: pointer;
        }
      }
      &__table{
        .table th{
          background-color: #e5e7e9;
          border: none;
        }
      }
      &__footer{
        margin-top: 35px;
        margin-bottom: 25px;
        .footer{
          &__num{
            float: left;
            font-size: 14px;
            line-height: 1.71;
            color: #999999;
          }
          &__page{
            float: left;
            text-align: center;
            .page{
              &__num{
                display: inline-block;
                padding: 0 10px;
              }
              &__item{
                display: inline-block;
              }
            }
          }
          &__name{
            float: right;
          }
        }
      }
      &__edit{
        .edit{
          &__title{
            font-size: 20px;
            letter-spacing: 0.8px;
            color: #333333;
            &--size{
              margin-left: 16px;
              font-size: 12px;
              color: #888888;
            }
          }
          &__text{
            width: 699px;
            height: 150px;
            background-color: #ffffff;
            border: solid 1px #d6d6d6;
            margin-top: 15px;
            font-size: 14px;
            line-height: 1.71;
            padding-left: 10px;
            color: #888888;
            padding-top: 10px;
          }
          &__agree{
            margin-top: 16px;
            .sms__agree{
              font-size: 14px;
              color: #666666;
              margin-left: 7px;
            }
          }
          &__explain{
            color: #888888;
            font-size: 12px;
          }
        }

      }
      .explain{

      }
      &__order{
        .order__title{
          font-size: 20px;
          letter-spacing: 0.8px;
          text-align: left;
          color: #333333;
        }
        .invite{
          &__list{
            margin-left: 30px;
            margin-top: 20px;
          }
          &__li{
            font-size: 14px;
            padding-bottom: 25px;
            color: #666666;
            &--color{
              font-size: 20px;
              color: #ff7a11;
            }
          }
          &__btn{
            width: 60px;
            height: 26px;
            border-radius: 4px;
            background-color: #ffffff;
            border: solid 1px #ffa72e;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #ffa01f;
            margin-left: 10px;
          }
        }
        .order__btn{
          margin-top: 200px;
        }
      }
      &__submit{
        text-align: center;
        .submit{
          &__ok{
            font-size: 26px;
            letter-spacing: 1px;
            color: #666666;
            margin-top: 120px;
            display: flex;
            justify-content: center;
            .icon__ok{
              margin-right: 15px;
            }
          }
          &__info{
            margin-top: 10px;
            font-size: 14px;
            color: #999999;
          }
          &__btn{
            margin-top: 25px;
            width: 120px;
            border-radius: 4px;
            background-color: #40b5ff;
            font-size: 14px;
            line-height: 30px;
            color: #ffffff;
            border: none;
          }
        }

      }
      &__record{
        .record__title{
          margin-top: 6px;
          font-size: 20px;
          letter-spacing: 0.8px;
          color: #333333;
          margin-bottom: 20px;
        }
        .table{
          th,td{
            text-align: center !important;
          }
        }
        .set__btn{
          float: right;
          width: 120px;
          border-radius: 4px;
          background-color: #40b5ff;
          font-size: 14px;
          line-height: 30px;
          color: #ffffff;
          border: none;
          cursor: pointer;
        }


      }
      &__action{
        color: #333333;
        padding: 0 7px;
        &--hand{
          cursor: pointer;
        }
      }
    }

  }

  /*电子邮件样式*/
  .tab-email--wrap{
    .title__text{
      width: 699px;
      height: 50px;
      background-color: #ffffff;
      border: solid 1px #d6d6d6;
      font-size: 14px;
      line-height: 1.71;
      text-align: left;
      color: #888888;
      margin-top: 15px;
    }
    .main__title{
      font-size: 20px;
      letter-spacing: 0.8px;
      color: #333333;
      margin-top: 35px;
    }
    .edit__info{
      font-size: 12px;
      color: #888888;
      margin-top: 10px;
    }
  }

  /*有偿采集样式*/
  .tab-collect--wrap{
    margin-top: 50px;
    .collect{
      &__title{
        font-size: 20px;
        letter-spacing: 0.8px;
        color: #333333;
      }
      &__text{
        font-size: 14px;
        color: #888888;
        line-height: 38px;
        &--top{
          margin-bottom: 5px;
          margin-top: 5px;
        }
        &--list{
          color: #333333;
          cursor: pointer;
        }
        & input{
          width: 200px;
          height: 30px;
        }
      }
      &__list{
        margin-top: 48px;
      }
      &__li{
        margin-bottom: 24px;
        font-size: 14px;
        color: #666666;
        &--color{
          font-size: 20px;
          color: #ff7a11;
        }
        &--link{
          margin-left: 10px;
          font-size: 14px;
          color: #40b5ff;
        }
        .recharge__btn{
          width: 60px;
          border-radius: 4px;
          background-color: #ffffff;
          border: solid 1px #ffa72e;
          font-size: 14px;
          letter-spacing: 0.6px;
          color: #ffa01f;
          line-height: 26px;
          margin-left: 20px;
        }

      }
    }
    .pay__btn{
      width: 100px;
      border-radius: 4px;
      line-height: 36px;
      font-size: 16px;
      color: #ffffff;
      border: none;
      margin-top: 24px;
      display: inline-block;
    }
  }
  /*样本配额样式*/
  .tab-quota--wrap{
    margin-top: 47px;
    .quota{
      &__title{
        font-size: 20px;
        letter-spacing: 0.8px;
        color: #333333;
        margin-bottom: 5px;
        &--more{
          margin-top: 20px;
        }
      }
    }
    .set__table{
      tr{
        line-height: 57px;
      }
      td{
        height: 40px;
      }
      .num__input{
        width: 140px;
        &--size{
          width: 140px;
          height: 36px;
          border: solid 1px #d6d6d6;
        }
        &--spac{
          padding-left: 5px;
        }
      }
      > tr{
        line-height: 45px;
      }
      & td{
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #666666;
      }
    }
    .save__btn{
      width: 90px;
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.6px;
      color: #ffffff;
      border: none;
      margin-left: 93px;
      margin-top: 16px;
      height: 32px;
    }
    .quota__table{
      /*margin-top: 47px;*/
      margin-top: 31px;
      .count__table--center{
        text-align: center;
      }
      .main__title{
        font-size: 22px;
        letter-spacing: 0.9px;
        color: #333333;
        margin-bottom: 20px;
      }
      .add__btn{
        width: 110px;
        border-radius: 4px;
        height: 32px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.6px;
        color: #ffffff;
        margin-left: 35px;
        border: none;
        display: inline-block;
      }
      .table__btn{
        border: none;
        background: none;
        cursor: pointer;
        color: #333333;
        &--color{
          color: #cccccc;
        }
      }
    }
  }
  & .data-label{
    padding: 0 8px;
    line-height: 36px;
  }
  .p-project-wx{
    margin-top: 47px;
    color: #666666;
    .wx__row{
      display: flex;
      color: #333333;
      font-size: 20px;
      letter-spacing: 0.8px;
      margin-bottom: 20px;
      align-items: center;
      .weixin__help{
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        &:hover{
          .help__text{
            display: block;
          }
        }
        .help__text{
          position: absolute;
          left: 20px;
          top: -23px;
          display: none;
          background: #4C4C4C;
          width: 320px;
          color: #ffffff;
          font-size: 12px;
          line-height: 20px;
          padding: 8px 12px;
          border-radius: 2px;
          margin-left: 5px;
        }
      }
      .icon-help{
        margin-left: 5px;
      }
    }
    .weixin__add{
      margin-top: 20px;
      &--btn{
        cursor: pointer;
      }
    }
    .weixin__table{
      margin-top: 20px;
      td,th{
        text-align: center;
      }
    }
    .weixin__list{
      display: flex;
      align-items: center;
      margin-top: 50px;
      .sent__btn{
        margin-left: 650px;
      }
      &--color{
        color: #ffac3b;
        margin-left: 20px;
      }
    }

    .list__record{
      &--title{
        font-size: 20px;
        display: flex;
        align-items: center;
      }
      &--update{
        font-size: 16px;
        color: #40b5ff;
        margin-left: 35px;
        cursor: pointer;
      }
      &--resend{
        color: #40b5ff;
        margin-left: 30px;
        cursor: pointer;
      }
      &--row{
        margin-top: 20px;
        .sent__status{
          margin-left: 30px;
        }
        .color__status{
          &--success{
            color: #20BB39;
          }
          &--send{
            color: #ffac3b;
          }
          &--fail{
            color: #666666;
          }
        }
        li{
          line-height: 24px;
          display: flex;
        }
      }
    }
  }
}
.p-project-money{
  width: 1000px;
  margin: auto;
  margin-top: 47px;
  td{
    text-align: center;
  }
  .money__title{
    font-size: 20px;
    color: #333333;
  }
  .money__btn{
    margin-left: 900px;
  }
  .td__span{
    padding: 0 5px;
    cursor: pointer;
  }
  .prize__set{
    display: flex;
    justify-content: center;
    margin-top: 70px;
    &--row{
      margin: 20px 60px;
      width: 300px;
      padding: 20px;
      height: 290px;
      border: 1px solid #dddddd;
      box-shadow: darkgrey 0px 1px 16px 0px;
      border-radius: 5px;
      text-align: center;
    }
    &--color{
      background: #dddddd;
      width: 110px;
      height: 110px;
      display: flex;
      border-radius: 100px;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-bottom: 20px;
    }
    .prize__btn{
      margin: auto;
      margin-top: 20px;
      border: 1px solid #40b5ff !important;
      background: #ffffff !important;
      color: #40b5ff;
      &:hover{
        background: #40b5ff !important;
        color: #ffffff !important;
      }
    }
    &--info{
      background-color: rgba(255, 251, 242, 1);
      padding: 8px 10px;
      display: flex;
      align-items: center;
      & span{
        margin-left: 5px;
      }
    }
  }
  .prize__title--row{
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 18px;
    .prize__title{
      flex: 1;
    }
  }
  .prize__table{
    margin-bottom: 30px;
    td,th {
      text-align: center;
    }
    thead{
      background-color: rgba(228, 228, 228, 1);
    }
    .table__td2{
      display: none;
      text-align: center;
      border: 1px dashed #00afec;
      cursor: pointer;
    }
    .table__tr{
      &:hover{
        .table__td2{
          display: table-cell;
        }
        .table__td{
          display: none;
        }
      }
    }
    .table__span{
      padding: 0 5px;
      &--cursor{
        cursor: pointer;
      }
    }
  }
  .save__btn{
    margin: auto;
  }

}
.tab-weChatCollect--wrap{
  margin-top: 47px;
  color: #666666;
  .weChatCollect{
    &__title{
      font-size: 20px;
      letter-spacing: 0.8px;
      color: #333333;
      margin-bottom: 15px;
    }
    &__p1{
      margin-top: 10px;
    }
    &__label{
      display: flex;
      align-items: center;
      margin-top: 20px;
      &--name{
        width: 90px;
      }
      &--input{
        width: 250px;
        height: 35px;
        margin-right: 10px;
      }
    }
  }
  .order__btn{
    margin: auto;
    margin-top: 60px;
  }
}
.random_div{
  margin-top:15px;
  margin-left:30px;
}
.random_password{
  margin-left:15px;
}
.random_password_input{
  width:47px;
  height:26px;
}
