.p-register {
  background-color: white;
  display: flex;
  justify-content: center;
  margin: 120px auto 150px;
  &--type{
  }
  &--main{
    width: 320px;
  }
  &--m{
    margin: 0px auto 150px;
    .p-register--main{
      margin-top: 25px;
    }
  }
  .register {
    &__title{
      text-align: center;
      font-size: 26px;
      letter-spacing: 1px;
      color: #777777;
      &--row{
        display: flex;
        justify-content: center;
      }
      &--active{
        color: #40b5ff;
      }
      &--line{
        padding: 0 10px;
      }
    }
    &__rows{
      display: flex;
      align-items: center;
      .img__delete{
        margin-left: 20px;
        border: none;
        background: none;
      }
    }
    &__row {
      position: relative;
      margin-top: 18px;
      color: #666666;
      width: 100%;
      .icon-position{
        position: absolute;
        right: -24px;
        top: 13px;
      }
      .Select{
        &-control{
          height: 46px;
        }
        &-value{
          line-height: 46px !important;
          &-label{
            color: #888888 !important;
          }
        }
      }
      &--img{
        width: 85px;
        height: 168px;
      }
      .upload{
        max-width: 100%;
      }
      .image-selector{
        width: 100%;
        height: 46px;
        color: #888888;
        line-height: 46px;
      }
      &--agree {
        display: flex;
        align-items: center;
        margin-top: 17px;
        .agree{
          color: #888888;
          font-size: 14px;
          margin-left: 10px;
        }
        .agreement{
          color: #1e1d1d;
          font-size: 14px;
        }
      }
      .code{
        position: absolute;
        top:0px;
        right: 8px;
        z-index: 1;
        line-height: 46px;
        font-size: 14px;
        color: #1f1f1f;
        cursor: pointer;
      }
      &--span{
        position: absolute;
        top: 8px;
        right: -193px;
        background: #40b5ff;
        padding: 5px 10px;
        color: #ffffff;
        border-radius: 2px;
        cursor: pointer;
      }
      &--info{
        right: -318px;
        background: #ffffff;
        color: red;
        margin-top: 10px;
        font-size: 12px;
        display: block;
        width: 300px;
        top:-13px;
      }
      &--title{
        font-size: 24px;
        color: #40b5ff;
        text-align: center;

      }


    }
    &__input {
      display: block;
      width: 100%;
      border: solid 1px #d6d6d6;
      padding: 0 12px;
      height: 46px;
      font-size: 14px;
      &::placeholder {
        color: #888888;
      }
    }

    &__submit {
      text-align: center;
      margin-top: 20px;
      cursor: pointer;
      height: 46px;
      border-radius: 4px;
      background-color: #ffac3b;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 46px;
      &:hover{
        background-color: rgba(255,172,59,0.9);
      }
    }
    &__footer {
      text-align: center;
      margin-top: 15px;
    }
    &__login {
      color: #333333;
      font-size: 14px;
      cursor: pointer;
    }
    &__num{
      color: #888888;
      font-size: 14px;
    }
  }
  &-company{
    width: 610px !important;
  }
}
.register_color{
  color:#01b0ec;
}
.p-register-header{
  display: flex;
  .register-org-input{
    display: block;
    width: 325px;
    border: solid 1px #666;
    margin: 20px 12px;
    padding: 0 12px;
    height: 46px;
    font-size: 14px;
  }
  .register-num{
    margin-top: 30px;
  }
}
