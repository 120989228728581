.image-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background-color: #ffffff;
  border: solid 1px #d1d1d1;
  overflow: hidden;
  position: relative;
  img {

  }
  input[type="file"] {
    display: none;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}