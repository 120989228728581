/** 外观设置 */
.setting {
  &--wrap {
    position: fixed;
    background: #F4F6F7;
    height: calc(100vh - 75px);
    top: 75px;
    width: 220px;
    display: flex;
    flex-direction: column;
  }
  &__header {
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 14px;
    color: #666B73;
    background-color: #C4C9CF;
  }
  &__main {
    padding: 20px 15px 60px 15px;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    .block {
      &__title {
        line-height: 24px;
        margin-bottom: 16px;
        color: #555555;
      }
      &__main {
        @import "../../mixin/transform-group";
        .font-group {
          display: flex;
          flex-wrap: wrap;
          width: 130px;
          align-items: center;
        }
      }
      &__row {
        display: flex;
        font-size: 12px;
        margin-bottom: 5px;
      }
      &__label {
        width: 60px;
        color: #555555;
      }
      &__actions {
        display: flex;
        align-items: center;
        .action {
          &__link {
            color: #40B5FF;
            cursor: pointer;
          }
        }
        .Select-value-label {
          color: #999999 !important;
        }
      }
    }
  }
  &__block {
    margin-bottom: 20px;
  }
  &__folder {
    background-color: #DFE0E1;
    color: #666B73;
    font-size: 12px;
    height: 26px;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .folder {
      &__text {
        margin-right: 4px;
      }
    }
  }
  &__more {
    display: none;
    &--show {
      display: block;
    }
  }
  &__footer {
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-size: 16px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
  }
}