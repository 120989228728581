.p-help{
  background-color: #ffffff;
  height: 100vh;
  padding-top: $works-nav-height;
  .search {
    &__container {
      width: 50%;
      margin: auto;
    }
    &__top{
      width: 100%;
      height: 90px;
      background: #cccccc;
    }
    &__input {
      position: relative;
      width: 100%;
      float: left;
      margin: 25px 0;
      &--text{
        padding-left: 5px;
        width: 100%;
        height: 40px;
        border: 1px solid #dddddd;
      }
    }
    &__btn{
      position: absolute;
      right: 3px;
      top: 0px;
      margin: auto;
      bottom: 0px;
      height: 33px;
      background: #999999;
      border-radius: 4px;
      border: none;
      padding: 8px 20px;
      color: #ffffff;
    }
  }
  .problem{
    margin-top: 15px;
    &__title{
      font-size: 14px;
      padding: 10px 0;
      border-bottom: 1px solid #cccccc;
    }
    &__content{
      &--list{
        width: 33.3%;
        float: left;
        margin-top: 20px;
      }
    }
    &__li{
      list-style-type: disc;
      color: #999999;
      line-height: 30px;
      list-style-position: inside;
      & .link{
        color: #999999;
        font-size: 12px;
        &:hover{
          color: #5b5b5b;
          cursor: pointer;
        }
        &__line{
          text-decoration: underline;
        }
      }
      &--gray{
        list-style: none;
        color: #666666;
      }
    }
  }
  }
.p-template{
  background: #eeeeee;
  position: relative;
  .header{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10;
  }
  .account__row--active{
    border-bottom: 2px solid #40b5ff;
    padding-bottom: 25px;
  }
  .account__nav{
    &--row{
      display: flex;
      justify-content: center;
      background: #ffffff;
    }
    &--item{
      border-bottom: none;
      padding: 0 25px;
    }
  }
  .template__main{
    width: 1200px;
    margin: auto;
    display: block;
    &--list{
      width: 80%;
      height: 180px;
      margin: 10px 23px ;
      border: 1px solid #dddddd;
      background: #ffffff;
      padding: 10px 20px;
      float: left;
      box-shadow: darkgrey 0px 1px 16px 0px
    }
    .qtn__title{
      text-align: center;
      margin-top: 10px;
      margin-bottom: 15px;
      overflow:hidden;
      text-overflow:ellipsis;
      white-space:nowrap;
      a{
        color: #666666;
        cursor: pointer;
      }
    }
    .qtn__text{
      overflow:hidden;
      text-overflow:ellipsis;
      display:-webkit-box;
      -webkit-line-clamp:3;
      -moz-line-clamp:3;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      autoprefixer: off;
      /*-webkit-box-orient: vertical;
      -moz--box-orient: vertical;*/
      autoprefixer: on;
      a{
        color: #666666;
        cursor: pointer;
      }
    }
    .qtn__describe{
      margin-top: 20px;
      color: #999999;
      display: flex;
      font-size: 12px;
      &--span{
        margin-right: 5px;
      }
      a{
        color: #999999;
        cursor: pointer;
      }
    }
  }
  .container{
    flex-direction: inherit !important;
  }
  .p-home--banner{
    height: auto !important;
  }
  .swiper-pagination-bullet{
    width: 12px !important;
    height: 12px !important;
    background: #ffffff;
    opacity: 1;
  }
  .swiper-pagination-bullet{
    margin: 0 6px !important;
  }
  .slider__p{
    position: absolute;
    top: 217px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 100;
    color: #ffffff;
    font-size: 34px;
    text-align: center;
  }
  .swiper-pagination-bullet-active{
    background: #007aff !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 640px) {
  .p-template{
    .header__mobile{
      background: #000000;
    }
    .account__nav--row{
      padding-top: 30px;
    }
    .template__main{
      width: 100% !important;
    }
    .template__main--row{
      width: 50% !important;
    }
    .template__main--list{
      width: 100% !important;
      margin: 10px 0px !important;
      .qtn__text{
        display: none;
      }
      .qtn__text--none{
        display: block !important;
      }
    }
    .account__nav--item{
      display: none;
    }
    .account__nav--first{
      display: block;
    }
    .banner__none{
      display: none;
    }
  }
}

@media screen and (max-width: 320px){
  .p-template{
    .header__mobile{
      background: #000000;
    }
    .account__nav--row{
      padding-top: 30px;
    }
    .template__main{
      width: 100% !important;
    }
    .template__main--row{
      width: 50% !important;
    }
    .template__main--list{
      width: 100% !important;
      margin: 10px 0px !important;
      .qtn__text{
        display: none;
      }
      .qtn__text--none{
        display: block !important;
      }
    }
    .account__nav--item{
      display: none;
    }
    .account__nav--first{
      display: block;
    }
    .banner__none{
      display: none;
    }
  }
}

.p-aboutUs{
  background: #eeeeee;
  min-height: 100vh;
  .content{
    min-height: calc(100vh - 135px);
    padding-bottom: 30px;
  }
  .header{
    background-color: #2a70f3;
    height: 55px;
    .nav__logo{
      margin-top: 0px !important;
    }
  }
  .about{
    &__main{
      display: flex;
      margin-top: 30px;
    }
    &__left{
      width: 175px;
      height: 200px;
      background: #fff;
      text-align: center;
      margin-right: 30px;
      &--li{
        height: 50px;
        line-height: 50px;
        &:hover{
          background: #2a70f3;
          color: #ffffff;
          cursor: pointer;
        }
      }
      &--active{
        border-left: 2px solid #2a70f3;
      }
    }
    &__right{
      flex: 1;
      background: #ffffff;
      padding-bottom: 30px;
      &--border{
        border-bottom: 1px solid #cccccc;
        padding-bottom: 15px !important;
      }
      &--title{
        line-height: 50px;
        border-bottom: 1px solid #ccc;
        padding-left: 20px;
      }
      &--main{
        padding: 0px 20px;
        p{
          line-height: 25px;
        }
        .about__p{
          margin-top: 20px;
          &--title{
            font-weight: bold;
          }
        }
      }
    }
  }
}
.information{
  &__main {
    background: #ffffff;
    margin-top: 30px;
  }
  &__title{
    line-height: 48px;
    font-size: 16px;
    border-bottom: 1px solid #cccccc;
    padding-left: 20px;
  }
  &__list{
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    span{
      color: #3E87F4;
    }
  }
  &__info{
    flex: 1;


  }
}
.home__footer{
  text-align: center;
  font-size: 16px;
  color: #888888;
  padding: 15px;
  margin-top: 45px;
  &--row{
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }
  &--span{
    margin: 0 45px;
  }
}







