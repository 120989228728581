.p-home {
  .back__modal{
    display: none;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.5;
    position: fixed;
    top: 0px;
    z-index: 10;
    &--show{
      display: block;
    }
  }
  .header__logo{
    transition: All 0.4s ease-in-out;
    -webkit-transition: All 0.4s ease-in-out;
    -moz-transition: All 0.4s ease-in-out;
    -o-transition: All 0.4s ease-in-out;
    &:hover{
      transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
      -ms-transform: scale(1.2);
    }
  }
  &--banner{
    position: relative;
    height: 100vh;
    background: url(assets/images/icons/home/home1.jpg) center center /cover;
    width: 100%;
    .header__info{
      padding: 5px 6px;
      background-color: #ffea7a;
      font-size: 12px;
      color: #ff5f0a;
      position: relative;
      text-align: center;
      .icon-close{
        position: absolute;
        right: 5px;
        top: 5px;
      }
      a{
        color: #40b5ff;
      }
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .backstage{
      padding-right: 25px;
    }
    .main {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        color: white;
        font-size: 20px;
      }
    }

    .footer {
      display: flex;
      color: white;
      padding-bottom: 30px;
      &__item {
        &--about {
          flex: 2
        }
        &--contact {
          flex: 2;
          margin-left: 80px;
        }
        &--wechat {
          flex: 1;
          margin-left: 80px;
        }
      }
    }
    .home__one--main{
      .banner{
        &__title{
          margin-top: 20%;
          font-size: 72px;
          font-weight: 500;
          text-align: center;
          color: #ffffff;
          text-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
          &--small{
            font-size: 24px;
            margin-top: 14px;
          }
        }
        &__btn{
          padding: 13px 90px;
          border-radius: 6px;
          background-color: #ffffff;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.6);
          border: none;
          text-align: center;
          margin: auto;
          display: block;
          margin-top: 74px;
          color: #2b4ba1;
          font-size: 24px;
          width: 280px;
          &:hover{
            background-color: rgba(225, 225, 225, 0.5);
          }
        }
      }
    }
  }
  .home__two--main{
    margin-top: 107px;
    .home{
      &__two{
        &--title{

          width: 536px;
          height: 67px;
          margin: auto;
          margin-bottom: 31px;
        }
        &--text{
          font-size: 20px;
          line-height: 1.7;
          text-align: center;
          color: #3b3f42;
        }
        &--icons{
          margin-top: 94px;
          display: flex;
          justify-content: center;

        }
        &--icons2{
          margin-top: 0px;
        }
        &--icon{
          width: 300px;
          height: 300px;
          opacity: 0.97;
          background-color: #ffffff;
          box-shadow: 0 0 20px 0 rgba(172, 172, 172, 0.5);
          margin: 0 20px;
          padding-top: 50px;
        }
        &--icon2{
          margin-top: 60px !important;
        }
        &--icon3{
          margin-top: 120px !important;
        }
        &--icon4{
          margin-top: -60px !important;
        }
        &--icon6{
          margin-top: 60px !important;
        }

      }
      &__icon{
        &--text{
          font-size: 28px;
          line-height: 1.07;
          text-align: center;
          color: #3b3f42;
          margin-top: 32px;
        }
        &--text2{
          font-size: 16px;
          color: #4a4e51;
          margin-top: 5px;
        }

      }
    }
  }
  .home__banner{
    background: url(assets/images/icons/home/home2.jpg) center center /cover;
    width: 100%;
    height: 500px;
    margin-top: 120px;
    &--title{
      font-size: 48px;
      text-align: center;
      color: #ffffff;
      padding-top: 162px;
      padding-bottom: 41px;
    }
    &--text{
      font-size: 20px;
      line-height: 1.7;
      text-align: center;
      color: #ffffff;
    }
  }
  .home__image--main{
    margin-top: 120px;
    .home{
      &__image{
        width: 300px;
        margin: 0 20px;
        &--row{
          display: flex;
          justify-content: center;
        }
        &--top{
          width:300px;
          height: 300px;
          & img{
            width:100%;
            height: 100%;
          }
        }
        &--bottom{
          opacity: 0.97;
          background-color: #ffffff;
          box-shadow: 0 0 20px 0 rgba(172, 172, 172, 0.5);
          padding: 32px 44px 40px;
          .home__image--icon{
            display: flex;
            margin-bottom: 13px;
          }
        }
        &--text{
          font-size: 24px;
          line-height: 1.42;
          color: #343434;
          margin-left: 13px;
        }
        &--info{
          font-size: 16px;
          color: #343434;
        }
      }
      &__image1{
        margin-top: 120px !important;
      }
      &__image2{
        margin-top: 60px !important;
      }
      &__image4{
        margin-top: 194px !important;
      }
      &__image5{
        margin-top: 124px !important;
      }
      &__image6{
        margin-top: 54px !important;
      }

    }
  }
  .home__banner2{
    background: url(assets/images/icons/home/home3.jpg) center center /cover;
    width: 100%;
    height: 500px;
    margin-top: 120px;
  }
  .home__logo{
    margin-top: 150px;
    .logo{
      float: left;
      margin: 0px 11px 42px 0px;
    }
  }
  .home__banner3{
    background: url(assets/images/icons/home/home4.jpg) center center /cover;
    width: 100%;
    height: 222px;
    margin-top: 132px;
    &--bottom{
      display: flex;
      .home__bottom{
        &--text{
          font-size: 40px;
          text-align: left;
          color: #ffffff;
          margin-top: 61px;
        }
        &--text2{
          font-size: 20px;
          line-height: 1.7;
          text-align: left;
          color: #ffffff;
          margin-top: 11px;
        }
      }
      .btn__buttom{
        padding: 0px 72px;
        border-radius: 6px;
        border: 1px solid #ffffff;
        height: 60px;
        line-height: 60px;
        background: none;
        margin-top: 81px;
        margin-left: 150px;
        font-size: 24px;
        color: #ffffff;
        display: block;
        &:hover{
          background-color: #ffffff;
          color: #2b4ba1;
        }
      }
    }
  }
  .home__footer{
    text-align: center;
    margin: 42px 0;
    font-size: 16px;
    color: #888888;
    &--row{
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }
    &--span{
      margin: 0 45px;
    }
  }
  .home__return--top{
    background: url(assets/images/icons/home/return__top.png) center center /cover;
    width: 70px;
    height: 78px;
    position: fixed;
    bottom:20px;
    right: 20px;
    cursor: pointer;
  }
  .header__mobile--block{
    display: none;
  }

}
@media screen and (max-width: 320px){
  .back__modal{display: none!important;}
  .p-multi-home{display: none!important;}
  .logo{
    width: 135px !important;
    height: 55px !important;
  }
  .home__image{
    margin: 0 3px !important;
  }
  .header__mobile--block{
    display: block !important;
  }
  .header__mobile{
    display: none;
  }
  .header__mobile{
    padding: 10px 10px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 640px) {
  .nav__logo--home{
    margin-top: 0px !important;
    margin-left: -5px !important;
  }
  .banner__btn{
    padding: 13px 0px !important;
  }
  .header__mobile--block{
    display: block !important;
  }
  .header__mobile{
    padding: 10px 10px !important;
  }
  .header__mobile{
    display: none;
  }
  .back__modal{display: none!important;}
  .p-multi-home{display: none!important;}
  .p-home--banner{
    padding: 0 10px;
    .backstage{
      padding-right: 0px !important;
      .header__btn{
        width: 70px !important;
      }
    }
    .header{
      padding: 10px 0px;
      &__btn{
        padding: 0px 3px;
        height: 32px;
        line-height: 32px;
        &--register{
          margin-left: 10px;
        }
      }
      &__btns{
        width:40px;
      }
    }
    .container{
      width: 100%;
      .banner{
        &__title{
          font-size: 34px;
          &:first-child{
            margin-top: 45%;
          }
          &--small{
            font-size: 20px;
          }
        }
      }
    }
  }
  .home__two--main{
    padding: 0 20px;
    .home__two--title{
      width: 270px !important;
      height: 28px !important;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .container{
      width: 100%;
      .home__two{
        &--title{
          font-size: 36px;
        }
        &--text{
          font-size: 15px;
        }
        &--icons{
          width: 100%;
        }
        &--icon{
          margin: 0 0;
          width: 30%;
          height: 245px;
          margin-right: 5%;
          padding-top: 35px;
          &:last-child{
            margin-right: 0px;
          }
          .icon{
            width: 80px !important;
            height: 80px !important;
          }
          .home__icon{
            &--text{
              font-size: 20px;
              padding: 0 5px;
            }
            &--text2{
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .home__banner{
    padding: 0 20px;
    .home__banner{
      &--title{
        font-size: 32px;
      }
      &--text{
        font-size: 16px;
      }
    }
  }
  .home__image--main{
    width: 100%;
    padding: 0 20px;
    .container{
      width: 100%;
      .home__image{
        &--row{
          width: 100%;
        }
        width: 100px !important;
        margin: 0 10px;
        &:first-child{
        }
        &--top{
          width: 100px;
          height: 100px;
        }
        &--bottom{
          padding: 32px 10px 40px;
          width: 100px;
          .home__image{
            &--text{
              margin-left: 5px;
              font-size: 20px;
            }
            &--info{
              font-size: 14px;
            }
          }
        }
      }
    }


  }
  .home__banner2{
    width: 100%;
    padding: 0 20px;
    .home__banner{
      &--title{
        font-size: 32px;
      }
      &--text{
        font-size: 16px;
      }
    }
  }
  .home__logo{
    width: 100%;
    padding: 0 20px;
    margin-top: 70px !important;
    .container{
      width: 100%;
      .logo{
        width: 145px;
        height: 60px;
        margin: 0 0 20px 0px;
        &:nth-child(2n+1) {
          float: right;
          margin-right: 0px;
        }
      }
    }
  }
  .home__banner3{
    width: 100%;
    padding: 0 20px;
    margin-top: 50px !important;
    .container{
      width: 100%;
      .home__banner3{
        &--left{
          width: 72%;
          .home__bottom{
            &--text{
              font-size: 20px;
            }
            &--text2{
              font-size: 16px;
            }
          }
        }

      }
      .btn__buttom{
        padding: 0 10px;
        margin-left: 0px;
        height: 35px;
        line-height: 35px;
        font-size: 14px;
      }
    }
  }
  .home__footer{
    width: 100%;
    padding: 0 15px;
    font-size: 12px !important;
    .container{
      width: 100%;
      .home__footer{
        &--span{
          margin: 0 5px;
        }
      }
    }
  }
}
@media screen and (min-width: 640px) and (max-width: 768px) {
  .back__modal{display: none!important;}
  .p-multi-home{display: none!important;}
  .container{
    width: 100% !important;
    .home__image{
      width: 225px !important;
      margin: 0 15px !important;
    }
    .home__image--top{
      width: 225px !important;
      height: 225px !important;
    }
    .home__banner3--left{
      width: 65%;
    }

  }
  .btn__buttom{
    margin-left: 0px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .back__modal{display: none!important;}
  .p-multi-home{display: none!important;}
  .home__banner3--left{
    visibility: visible !important;
  }

}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  width: 100%;
  .nav__bj{
    position: fixed;
    background-color:rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 1000;
    .nav__mobile{
      position: fixed;
      right: 0px;
      height: 100%;
      width: 120px;
      background: #fff;
      z-index: 1000;
      &--bottom{
        position: fixed;
        bottom: 0px;
        width: 120px;
        margin-bottom: 15px;
        z-index: 100;
        .nav__btn{
          border: 1px solid #00afec;
          color: #00afec;
          display: block;
          width: 100px;
          border-radius: 20px;
          margin: auto;
          line-height: 27px;
          text-align: center;
          margin-top: 10px;
        }
      }
      .nav__row{
        color: #666666;
        line-height: 40px;
        border-bottom: 1px solid #ccc;
        text-align: center;
        display: block;
        height: 40px;
        a{
          color: #666666;
        }
        .close{
          font-size: 20px;
          font-weight: bold;
          position: absolute;
          left: 10px;
          top: 0px;
        }
      }


    }
  }
  .nav__main{
    display: flex;
    flex: 1;
    &--left{
      flex: 1;
      text-align: center;
    }
  }
  .template__nav{
    color: #ffffff;
    padding-bottom: 15px;
    font-size: 16px;
    padding: 0 20px;
    &--active{
      color: #00afec !important;
    }
    &:hover{
      border-bottom: 2px solid #ffffff;
    }
  }
  &__right {
    display: flex;
    .header__mobile--block{
      display: none;
    }
  }
  &__hide {
    display : none;
  }
  &__btn {
    color: #ffffff;
    padding: 0 5px;
    cursor: pointer;
    &:hover{
      color: #ffffff;
    }
/*    &--register {
      margin-left: 20px;
      &:hover {
        font-weight: 600;
      }
    }*/
    &--hover{
      position: relative;
      .icon__hot{
        position: absolute;
        top: -2px;
        right: -5px;
      }
      /*         &:hover{
                 .header__btn--info{
                   display: block;
                 }
               }*/
    }
    &--info{
      position: absolute;
      top: 62px;
      left: 0px;
      background: #fff;
      padding: 5px;
      width: 150px;
      z-index: 1000;

    }
    &--none{
      display: none;
    }
    &--block{
      display: block;
    }
    &--color{
      color: #00afec;
    }
  }
  &__back{
    background: #000000;
    top: 0px !important;
  }
}