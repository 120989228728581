
.icon {
  display: block;
  cursor: pointer;
}
.icon-s {
  width: 16px;
  height: 16px;
}
.icon-m {
  width: 20px;
  height: 20px;
}
.icon-l {
  width: 32px;
  height: 32px;
}
.icon-hide {
  display: none;
}
.icon-logo {
  background: url("assets/images/logo.png") no-repeat center center;
  width: 158px;
  height: 20px;
}
 .icon-tishi {
   background: url("assets/images/tishi.png") no-repeat center center;
   width: 25px;
   height: 25px;
   display: inline-block;
   background-size: 100%;
   margin-right: 5px;
 }
.icon-redpacket {
  background: url("assets/images/redpacket.jpg") no-repeat center center;
  display: inline-block;
  background-size: 100%;
  width: 170px;
  height: 215px;
  margin-top: 15px;
}
.icon-redpacket2 {
  background: url("assets/images/redpacket2.png") no-repeat center center;
  display: inline-block;
  background-size: 100%;
  width: 170px;
  height: 140px;
  margin-top: 15px;
}
.icon-redpacket3 {
  background: url("assets/images/redpacket3.png") no-repeat center center;
  background-size: 100%;
  width: 156px;
  height: 156px;
  margin-top: 15px;
}
.icon-code {
  background: url("assets/images/icons/answer/code.png") no-repeat center center;
  background-size: 100%;
  width: 55px;
  height: 55px;
}

.icon-redpacket4 {
  background: url("assets/images/redpacket4.png") no-repeat center center;
  background-size: 100%;
  width: 328px;
  height: 56px;
  margin-top: 15px;
}
.icon-yipai {
  background: url("assets/images/yipai__icon.png") no-repeat center center;
  background-size: 100%;
  width: 108px;
  height: 108px;
}
.icon-sorry {
  background: url("assets/images/sorry.png") no-repeat center center;
  display: inline-block;
  background-size: 100%;
  width: 140px;
  height: 140px;
  margin-top: 15px;
}
.icon-jiantou {
  background: url("assets/images/jiantou.png") no-repeat center center;
  background-size: 100%;
  width: 60px;
  height: 60px;
}
.icon-tishi1 {
  background: url("assets/images/tishi1.png") no-repeat center center;
  width: 25px;
  height: 25px;
  display: inline-block;
  background-size: 100%;
  margin-right: 5px;
}
.icon-tishi2 {
  background: url("assets/images/tishi2.png") no-repeat center center;
  width: 25px;
  height: 25px;
  display: inline-block;
  background-size: 100%;
  margin-right: 5px;
}

.icon-logo-blue {
  background: url("assets/images/logo-blue.png") no-repeat center center;
  width: 158px;
  height: 20px;
  display: block;
}
.icon-search {
  width: 20px;
  height: 20px;
  background: url("assets/images/icons/search.png") no-repeat center center;
  background-size: cover;
}
.icon-create-empty-qtn {
  width: 44px;
  height: 44px;
  background: url("assets/images/icons/qtn/create-empty-qtn.svg");
}
.icon-refer-qtn {
  width: 44px;
  height: 44px;
  background: url("assets/images/icons/qtn/icon-refer-qtn.svg");
}
.icon-copy-qtn {
  width: 44px;
  height: 44px;
  background: url("assets/images/icons/qtn/copy-qtn.svg");
}
.icon-dump-qtn {
  width: 44px;
  height: 44px;
  background: url("assets/images/icons/qtn/dump-qtn.svg");
}
.icon-avatar {
  width: 26px;
  height: 26px;
  background: url("assets/images/avatar.png");
}
.icon-message {
  width: 31px;
  height: 31px;
  background: url("assets/images/message.png");
}
.icon-message-none {
  width: 28px;
  height: 28px;
  background: url("assets/images/message-none.png");
}

/** logic edit **/
.icon-triangle-down {
  width: 10px;
  height: 5px;
  background: url("assets/images/icons/triangle-down.svg") center center no-repeat;
}
.icon-help {
  width: 14px;
  height: 14px;
  background: url("assets/images/icons/help.svg") center center no-repeat;
}
.icon-arrow-up {
  width: 11px;
  height: 7px;
  background: url("assets/images/icons/arrow-up.svg") center center no-repeat;
}
.icon-arrow-down {
  width: 11px;
  height: 7px;
  background: url("assets/images/icons/arrow-down.svg") center center no-repeat;
}
.icon-target {
  width: 40px;
  height: 24px;
  background: url("assets/images/icons/logic/target.svg") center center no-repeat;
}
$logic-icons: (
        'delete',
        'add-row',
        'delete-row',
        'add-row2'
);
@each $icon in $logic-icons {
  .icon-logic-#{$icon} {
    background: url("assets/images/icons/logic/#{$icon}.svg") center center /contain no-repeat;
  }
}

/** answer icons **/
.icon-choice-selected {
  background: url("assets/images/icons/answer/choice-selected.png") center center /cover no-repeat;
}
.icon-choice-selected-no {
  background: url("assets/images/icons/answer/choice-selected-no.png") center center /cover no-repeat;
}
.icon-choice-multi-selected {
  background: url("assets/images/icons/answer/choice-multi-selected2.png") center center /cover no-repeat;
}
.icon-choice-multi-selected-no {
  background: url("assets/images/icons/answer/choice-multi-selected-no.png") center center /cover no-repeat;
}
.icon-answer-over-success {
  background: url("assets/images/icons/over-success.svg") center center /cover no-repeat;
}
.icon-answer-over-warn {
  background: url("assets/images/icons/answer/over-warn.svg") center center /cover no-repeat;
  width: 36px;
  height: 36px;
}
.icon-answer-over-error {
  background: url("assets/images/icons/answer/over-error.svg") center center /cover no-repeat;
}
.icon-answer-over-screen {
  background: url("assets/images/icons/answer/over-error.svg") center center /cover no-repeat;
}
.icon-answer-copy-link {
  background: url("assets/images/icons/answer/copy-link.svg") center center /cover no-repeat;
}
.icon-answer-drag {
  background: url("assets/images/icons/qtn/hand.png") center center /cover no-repeat;
}
.icon-answer-drag2 {
  background: url("assets/images/icons/qtn/hand2.png") center center /cover no-repeat;
}
/*.icon-answer-drag2 {
  background: url("assets/images/icons/qtn/hand2.png") center center /cover no-repeat;
}*/

/** qtn **/
.icon-choice-single {
  background: url("assets/images/icons/qtn/choice-single.svg") center center /cover no-repeat;
}
.icon-qtn-category {
  background: url("assets/images/icons/qtn/qtn-category.svg") center center /cover no-repeat;
}
.icon-qtn-category2 {
  background: url("assets/images/icons/qtn/qtn-category.svg") center center /cover no-repeat;
  margin-left: 5px;
  transform:rotate(180deg);
  -ms-transform:rotate(180deg);
  -moz-transform:rotate(180deg);
  -webkit-transform:rotate(180deg);
  -o-transform:rotate(180deg);
}
$qtn-types: (
        "1-0",
        "1-1",
        "1-8",
        "1-9",
        "1-11",
        "2-1",
        "2-2",
        "3-0",
        "3-1",
        "4-0",
        "6-1",
        "7-1",
        "8-2",
        "8-4",
        "page",
        "birthday",
        "city",
        "location"
);
@each $type in $qtn-types {
  .icon-#{$type} {
    background: url("assets/images/icons/qtn/qtn-#{$type}.svg") center center /contain no-repeat;
  }
}
.icon-9-1{
  background: url("assets/images/icons/qtn/target-logo.png") center center /contain no-repeat;
}

$editor-icons: (
        'qt-copy',
        'qt-up',
        'qt-down',
        'qt-delete',
        'text',
        'img',
        'setting',
        'delete',
        'opt-up',
        'opt-down',
        'opt-left',
        'opt-right',
        'opt-add',
        'opt-multi-add',
);
@each $icon in $editor-icons {
  .icon-editor-#{$icon} {
    background: url("assets/images/icons/editor/#{$icon}.svg") center center /contain no-repeat;
  }
}

.icon-radio-select {
  background: url("assets/images/icons/qtn/radio-select.png") center center /contain no-repeat;
  
}

.icon-score{
  background: url("assets/images/icons/qtn/score.png") center center /contain no-repeat;
}



$icons: (
        'close'
);
@each $icon in $icons {
  .icon-#{$icon} {
    background: url("assets/images/icons/#{$icon}.svg") center center /contain no-repeat;
  }
}

/** account icons **/
$account-icons: (
        'email',
        'password',
        'phone',
        'user'
);
@each $icon in $account-icons {
  .icon-account-#{$icon} {
    background: url("assets/images/icons/account/#{$icon}.svg") center center /contain no-repeat;
  }
}

/** registerdata icons **/
.icon-data-set {
  background: url("assets/images/icons/data/data-set.svg") center center /contain no-repeat;
  margin: 12px;
  width: 18px;
  height: 18px;
}
.icon-data-sets {
  background: url("assets/images/icons/data/data-set-off.svg") center center /contain no-repeat;
  margin: 12px;
  width:18px;
  height: 18px;
}
.icon-prize {
  background: url("assets/images/icons/data/prize.png") center center /contain no-repeat;
  width:65px;
  height: 65px;
}
.icon-prize-info {
  background: url("assets/images/icons/data/tips.png") center center /contain no-repeat;
  width:16px;
  height: 16px;
}
.icon-redpacket5 {
  background: url("assets/images/icons/data/redpacket.png") center center /contain no-repeat;
  width:70px;
  height: 70px;
}
.icon-login-close {
  background: url("assets/images/icons/account/login-close.svg") center center /contain no-repeat;
  width:14px;
  height: 14px;
  margin: 5px;
}
.reset-pass-ok {
  background: url("assets/images/icons/account/reset-pass-ok.svg") center center /contain no-repeat;
  width: 36px;
  height: 36px;
}

.over-warn {
  background: url("assets/images/icons/account/over-warn.svg") center center /contain no-repeat;
  width: 36px;
  height: 36px;
}

.hot {
  background: url("assets/images/icons/hot2.png") center center /contain no-repeat;
  width: 110px;
  height: 41px;
}
.hot2 {
  background: url("assets/images/icons/hot.png") center center /contain no-repeat;
  width: 21px;
  height: 28px;
}
.editor-account {
  background: url("assets/images/icons/account/editor.svg") center center /contain no-repeat;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 20px;

}
.deleted-account {
  background: url("assets/images/icons/account/deleted.svg") center center /contain no-repeat;
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.register-pass-position{
  position: absolute;
  left: 21px;
}

.reset-pass-position{
  position: absolute;
  left: 38px;
}
.return-qtn {
  position: absolute;
  background: url("assets/images/icons/qtn/return-qtn.svg") center center /contain no-repeat;
  width: 12px;
  height: 10px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
}
.home-icon1 {
  background: url("assets/images/icons/home/icon1.svg") center center /contain no-repeat;
  width: 110px;
  height: 110px;
  margin: auto;
}
.nav-hide {
  background: url("assets/images/icons/home/icon__hide.png") center center /contain no-repeat;
  width: 25px;
  height: 25px;
}
.home-icon2 {
  background: url("assets/images/icons/home/icon2.svg") center center /contain no-repeat;
  width: 110px;
  height: 110px;
  margin: auto;
}
.home-icon3 {
  background: url("assets/images/icons/home/icon3.svg") center center /contain no-repeat;
  width: 110px;
  height: 110px;
  margin: auto;
}
.home-icon4 {
  background: url("assets/images/icons/home/icon4.svg") center center /contain no-repeat;
  width: 110px;
  height: 110px;
  margin: auto;
}
.home-icon5 {
  background: url("assets/images/icons/home/icon5.svg") center center /contain no-repeat;
  width: 110px;
  height: 110px;
  margin: auto;
}
.home-icon6 {
  background: url("assets/images/icons/home/icon6.svg") center center /contain no-repeat;
  width: 110px;
  height: 110px;
  margin: auto;
}
.home-icon-1 {
  background: url("assets/images/icons/home/icon-1.svg") center center /contain no-repeat;
  width: 40px;
  height: 36px;
}
.home-icon-2 {
  background: url("assets/images/icons/home/icon-2.svg") center center /contain no-repeat;
  width: 36px;
  height: 37px;
}
.home-icon-3 {
  background: url("assets/images/icons/home/icon-3.svg") center center /contain no-repeat;
  width: 36px;
  height: 36px;
}
.home-icon-4 {
  background: url("assets/images/icons/home/icon-4.svg") center center /contain no-repeat;
  width: 42px;
  height: 42px;
}
.home-icon-5 {
  background: url("assets/images/icons/home/icon-5.svg") center center /contain no-repeat;
  width: 36px;
  height: 39px;
}
.home-icon-6 {
  background: url("assets/images/icons/home/icon-6.svg") center center /contain no-repeat;
  width: 40px;
  height: 36px;
}


$home-logo: (
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18"
);
@each $logo in $home-logo {
  .logo-icon-#{$logo} {
    background: url("assets/images/icons/home/logo#{$logo}.png") center center /contain no-repeat;
    width: 153px;
    height: 64px;
  }
}

.icon-pay {
  width: 22px;
  height: 22px;
  background: url("assets/images/icons/account/pay.svg");
  margin-right: 8px;
}
.icon-wechart {
  width: 29px;
  height: 23px;
  background: url("assets/images/icons/account/wechart.svg");
  margin-right: 8px;
}

.icon-news {
  width: 22px;
  height: 20px;
  background: url("assets/images/icons/account/news.png");
  margin-left: 20px;
}

.icon-news2 {
  width: 22px;
  height: 22px;
  background: url("assets/images/icons/account/news2.png");
  margin-left: 20px;
}

.icon-sanjiao {
  border-color: transparent transparent #999 ;
  border-style: solid;
  border-width: 0px 5px 5px;
  display: inline-block;
  height: 0;
  width: 0;
}

.icon-sanjiao-dowm {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0.5px;
  display: inline-block;
  height: 0;
  width: 0;
  margin-top: 5px;
}

.icon-top {
  width: 22px;
  height: 22px;
  background: url("assets/images/icons/qtn/down.png") center center /contain no-repeat;
}

.icon-down {
  width: 22px;
  height: 22px;
  background: url("assets/images/icons/qtn/top.png") center center /contain no-repeat;
}








