.page__notfind{
  width: 150px;
  height: 120px;
  border: 5px solid #999999;
  border-radius: 8px 8px 6px 6px;
  margin: auto;
  margin-top: 250px;
  &--bottom{
    border-bottom: 5px solid #999999;
    width: 100%;
    height: 20px;
  }
  &--span{
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background: #999999;
    display: inline-block;
    margin: 5px 2px;
  }
  &--text{
    text-align: center;
    font-size: 44px;
    color: #999999;
    line-height: 90px;
  }
  &--texts{
    text-align: center;
    font-size: 20px;
    color: #666666;
    margin-top: 20px;
    & a{
      color: #00afec;
      cursor: pointer;
    }
  }
}