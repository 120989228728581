.p-project{
  padding-top: 51px;
  .project{
    &__top{
      margin-bottom: 15px;
      margin-top: 45px;
      color: #666666;
      &--left{
        float: left;
        display: flex;
        align-items: center;
        .icon_sanjiao{
          margin-left: 10px;
          margin-top: 10px;
        }
      }
    }
    &__time{
      display: flex;
      border-bottom: 0.5px solid #dbdfe3;
      padding-bottom: 15px;
      align-items: center;
      color: #666666;
      &--row{
        margin-right: 10px;
        .rdtTimeToggle{
          display: none;
        }
      }
    }
    &__btn{
      float: left;
      text-align: center;
      display: block;
      color: #ffffff;
      border-radius: 4px;
      font-weight: 700;
    }
    &__select{
      float: left;
      margin-left: 20px;
      margin-top: 10px;
      width: 70px;
      &--type{
        width: 100px;
      }

      .Select{
        &-control{
          height: 30px !important;
          border: none;
        }
        &-value{
          line-height: 30px;
        }
        &-placeholder{
          line-height: 30px !important;
        }
        &-value-label{
          color: #666666 !important;
        }
      }
      &--name{
        width: 170px !important;
        float: left;
        margin-left: 20px;
        margin-top: 10px;
      }
    }
    &__select2{
      width: 85px !important;
    }

    &__search{
      float: right;
      position: relative;
      display: flex;
      align-items: center;
      .header__choose{
        display: flex;
        align-items: center;
        margin-right: 10px;
        .icon__deleted{
          margin-right: 5px;
        }
      }
      &--input{
        width: 280px;
        height: 34px;
        border: solid 1px #d6d6d6;
        border-radius: 2px;
        padding-left: 10px;
      }
      &--icon{
        position: absolute;
        right: 10px;
        top:6px;
        display: block;
      }
    }
    &__table{
      margin-top: 15px;
      color: #666666;
      .qtn__edit{
        display: none;
      }
      .qtn__name--td{
        &:hover{
          .qtn__edit{
            display: block;
          }
        }
      }
      .qtn__type--td{
        &:hover{
          .qtn__edit{
            display: inline-block;
          }
        }
      }
      .icon_sanjiao{
        margin-left: 10px;
      }
      a{
        color: #666666;
      }
      td{
        text-align: center;
      }
      .tr__hover--back{
        &:hover{
          background: #e5e7e9;
        }
      }
      .td_span{
        cursor: pointer;
        margin-left: 20px;
        &--color{
          color: #cccccc;
        }
      }
    }
  }
  .header__choose--name{
    width: 105px;
  }
  .pagination{
    margin-top: 25px;
  }
}
.p-project-new{
  padding-top: 51px;
  color: #666666;
  .project{
   &__title{
     margin-top: 30px;
     font-size: 20px;
   }
    &__new{
      &--title{
        margin-top: 52px;
        font-size: 20px;
      }
      &--name{
        width: 550px;
        height: 50px;
        background-color: #ffffff;
        border: solid 1px #d6d6d6;
        margin-top: 20px;
        padding: 10px;
        font-size: 16px;
      }
      &--btn{
        border:none;
        width: 120px;
        line-height: 44px;
        text-align: center;
        background: #ffac3b;
        margin-top: 40px;
        color: #ffffff;
        border-radius: 4px;
        font-size: 20px;
      }
      &--qtn{
        margin-top: 20px;
      }
    }
    &__type{
      &--title{
        font-size: 20px;
        color: #333333;
      }
      &--list{
        float: left;
        width: 900px;
      }
      &--row{
        float: left;
        width: 20%;
        line-height: 60px;
        display: flex;
        align-items: center;
        & input{
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
        &:first-child{
          margin-left: 20px;
        }
      }
    }
  }
}
.p-project-team{
  .project__team{
    margin-top: 51px;
    display: flex;
    &--right{
      flex: 1;
      padding: 0 30px;
      .add__btn{
        margin-top: 15px;
      }
    }
    &--title{
      margin-top: 45px;
      padding-bottom: 12px;
      font-size: 22px;
      color: #333;
    }
    &--table{
      margin-bottom: 25px;
      margin-top: 20px;
      td{
        color: #666666;
      }
      .td__span{
        cursor: pointer;
        margin-left: 15px;
      }
      .project__page{
        margin-top: 20px;
      }
    }
  }
}
.p-works-manage{
  .manage{
    &__nav{
      margin-top: 51px;
      display: flex;
    }
    &__title--left{
      line-height: 40px;
      color: #6c6c6c;
    }
    &__tabs--center{
      height: 40px;
      line-height: 40px;
      margin: auto;
      .tab__item{
        position: relative;
        margin-right: 35px;
        color: #6c6c6c;
        cursor: pointer;
        &:hover{
          color: #40b5ff;
        }
        &--active{
          color: #40b5ff;
          &:after{
            content: "";
            display: block;
            position: absolute;
            bottom: 0px;
            top: 0px;
            left: -8px;
            margin: auto;
            transform: translate(-50%, 0);
            width: 3px;
            height: 3px;
            background-color: #40b5ff;
            border-radius: 3px;
          }
        }
      }
    }
    &__top{
      margin-top: 30px;
      &--left{
        display: flex;
        float: left;
        align-items: center;
        .header__choose{
          width: 100px;
          margin-left: 20px;

        }
      }
      &--right{
        display: flex;
        float:right;
        align-items: center;
        .header__choose{
          display: flex;
          align-items: center;
          &--handel{
            cursor: pointer;
          }
        }
        .icon__deleted{
          display: block;
          margin-right: 5px;
        }

        .header__search{
          margin-left: 10px;
          position: relative;
          width: 280px;
          .input{
            width: 280px;
            height: 34px;
          }
          .header__submit{
            position: absolute;
            right: 10px;
            top: 6px;
          }
        }
      }
    }
    &__select{
      width: 105px;
      border: none;
      margin-left: 45px;
      margin-top: 5px;
      .Select{
        &-control{
          border:none;
          height: 33px;
        }
        &-placeholder{
          line-height: 35px;
        }
        &-value-label{
          line-height: 34px;
          color: #666666 !important;
        }
      }
    }
    &__tabs{
      margin-top: 30px;
      color: #666666;
      .profile__table{
        font-size: 20px;
        .info{
          font-size: 16px;
        }
      }
      .create__plan{
        margin-top: 15px;
        .create__table{
          td{
            text-align: center;
          }
        }
        &--title{
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
      tr{
        line-height: 40px;
      }
      .oprate{
        margin-left: 81px;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }
  .qtn-table{
    margin-top: 15px;
    color: #666666;
    .qtn__edit{
      cursor: pointer;
      display: none;
    }
    .qtn__name--td{
      &:hover{
        .qtn__edit{
          display: inline-block;
        }
      }
    }
    .project__actions{
      display: flex;
      justify-content: left;
      .Select{
        &-control{
          height: 30px;
          border: none;
          width: 100px;
          text-align: center;
        }
        &-placeholder{
          line-height: 28px;
          padding-left:0px;
        }
      }
      .project__action{
        width: 85px;
        padding: 0 0;
        height: 30px;
        color: #40b5ff;
      }
      &--row{
        position: relative;
        margin-right: 10px;
        line-height: 30px;
        text-align: center;
      }
      &--list{
        position: absolute;
        top: 33px;
        left: 0px;
        width: 90px;
        border: 1px solid #cccccc;
        z-index: 1;
        a{
          color: #666666;
        }
        & li{
          line-height: 24px;
        }
      }
      &--none{
        display: none;
      }
    }
  }
  .add__btn{
    font-weight: 700;
    height: 34px;
  }
  .qtn__page{
    margin-top: 25px;
  }

}
.p-create-new{
  .create{
    &__plan{
      color: #666666;
      &--title{
        font-size: 20px;
        color: #333333;
        margin-top: 40px;
      }
    }
    &__table{
      margin-top: 20px;
      td{
        text-align: center;
      }
      .td__btn{
        cursor: pointer;
      }
    }
  }
}
.p-works-task{
  color: #666666;
  .task{
    &__main{
      margin-top: 91px;
      tr{
        line-height: 40px;
      }
      td{
        font-size: 20px;
      }
      .oprate{
        font-size: 16px;
        margin-left: 100px;
        cursor: pointer;
      }
    }

  }
}
.prompt__success{
  color: #666666;
  text-align: center;
  margin-top: 300px;
  font-size: 20px;
  &--text{
    color: #40b5ff;
    margin-top: 10px;
    font-size: 16px;
  }
}
.p-project-abutment{
  color: #666666;
  .abutment{
    &__top{
      text-align: center;
      margin-top: 60px;
    }
    &__main{
      margin-top: 40px;
    }
    &__title{
      font-size: 20px;
    }
    &__select{
      display: flex;
      margin-top: 20px;
      align-items: center;
      .text__link{
        margin-left: 30px;
      }
      .Select{
        &-control{
          width: 250px;
          height: 30px;
        }
        &-placeholder{
          line-height: 28px;
        }
        &-value-label{
          color: #666666 !important;
        }
      }
      .info__btn{
        border: none;
        background: none;
        cursor: pointer;
        margin-left: 10px;
        color: #666666;
      }
    }
    &__label{
      display: flex;
      margin-top: 20px;
      align-items: center;
      &--row{
        margin-right: 20px;
        font-size: 20px;
        display: flex;
        align-items: center;
      }
      &--size{
        font-size: 14px;
      }
      &--input{
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
    &__input{
      display: flex;
      margin-top: 20px;
      & input{
        width: 250px;
        margin-right: 10px;
      }
    }
    &__upload{
      display: flex;
      margin-top: 20px;
    }
    &__table{
      margin-top: 20px;
      td{
        text-align: center;
      }
      .btn__deleted{
        background: none;
        cursor: pointer;
        border: none;
        color: #666666;
      }
    }
  }
}
.p-project-rent{
  color: #666666;
  .rent{
    &__title{
      font-size: 20px;
      margin-top: 47px;
      display: flex;
      .btn{
        margin-left: 20px;
      }
    }
    &__row{
      display: flex;
      align-items: center;
      margin-top: 15px;
      .input__other{
        height: 30px;
        margin-right: 20px;
      }
      .Select{
        &-control{
          height: 32px;
        }
        &-placeholder{
          line-height: 30px;
        }
      }
      &--name{
        width: 100px;
        display: flex;
        align-items: center;
        float: left;
        input{
          width: 16px;
          height: 16px;
        }
      }
      &--name2{
        margin-top: 18px;
      }
      &--list{
        float: left;

      }
      &--input{
        width: 80px;
        height: 30px;
      }
      &--email{
        width: 200px;
      }
      &--right{
        margin-left: 30px;
        display: flex;
        align-items: center;
        input{
          width: 16px;
          height: 16px;
        }
      }
      &--more{
        display: flex;
        align-items: center;
        margin-top: 10px;
        .btn__rent{
          margin: 0 10px 0px 20px;
        }
        .icon{
          margin-left: 10px;
        }
      }
      &--left{
        margin-left: 100px;
        .btn{
          margin-right: 20px;
        }
      }
    }
    &__select{
      width: 200px;
      margin-right: 20px;
    }
    &__input{
      &--link{
        width: 250px;
        height: 30px;
        margin-right: 20px;
      }
    }
    &__list{
      margin-top: 47px;
      .record__title{
        margin-top: 6px;
        font-size: 20px;
        letter-spacing: 0.8px;
        color: #333333;
        margin-bottom: 20px;
        .rent__add--btn{
          float: right;
        }
      }
      .btn__edit{
        color: #40b5ff;
        cursor: pointer;
      }
    }
  }
  .rent__main{
    &--list{
      display: flex;
      margin-top: 15px;
      align-items: center;
      img{
        width: 100px;
      }
    }
    &--label{
      width: 100px;
    }
    &--text{
      width: 200px;
      height: 30px;
      border: 1px solid #cccccc;
    }
    &--input{
      width: 250px;
      height: 30px;
    }
    &--title{
      font-size: 16px;
      font-weight: bold;
      margin-top: 20px;
    }
    &--input1{
      width: 60px;
      height: 30px;
      margin: 0 10px;
    }
    &--span{
      margin-right: 10px;
      color: #cccccc;
    }
    .rent__select{
      width: 250px;
    }
    .Select-control{
      width: 250px;
      height: 32px;
    }
    .Select-placeholder{
      line-height: 30px;
    }
  }

}
.p-project-hand{
  .Select{
    &-control{
      height: 32px !important;
    }
    &-value{
      line-height: 30px;
    }
    &-placeholder{
      line-height: 30px !important;
    }
    &-value-label{
      color: #666666 !important;
    }
  }
  color: #666666;
  .btn__submit{
    margin-top: 40px;
    margin-left: 0px !important;
    margin-right: 20px;
  }
  .hand{
    &__row{
      display: flex;
      align-items: center;
      margin-top: 20px;
      .goldNum__input{
        width: 100px;
        height: 32px;
      }
      &--input{
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }
      &--span{
        display: flex;
        align-items: center;
        margin-left: 20px;
      }
      .btn{
        line-height: 32px !important;
        margin-left: 20px;
      }
      .hand__time{
        display: flex;
        margin-left: 10px;
        .rent__select{
          width: 80px;
        }
      }
      .rent__select{
        width: 80px;
        margin-left: 10px;
      }
      .input{
        height: 32px !important;
      }
    }
    &__select{
      width: 100px;
    }
  }
  .hand{
    &__submit{
      text-align: center;
      .icon__ok{
        margin-right: 5px;
      }
    }
    &__ok{
      font-size: 26px;
      letter-spacing: 1px;
      color: #666666;
      margin-top: 120px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
    }
    &__info{
      margin-top: 10px;
      font-size: 14px;
      color: #999999;
    }
    &__btn{
      margin-top: 25px;
      width: 120px;
      border-radius: 4px;
      background-color: #40b5ff;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      border: none;
    }
    &__record{
      margin-top: 47px;
      &--span{
        padding: 0 5px;
      }
      .return__btn{
        margin-top: 100px;
      }
      .table__span{
        cursor: pointer;
        padding: 0 5px;
      }
      .record__title{
        margin-top: 6px;
        font-size: 20px;
        letter-spacing: 0.8px;
        color: #333333;
        margin-bottom: 20px;
      }
      .set__btn{
        float: right;
        width: 120px;
        border-radius: 4px;
        background-color: #40b5ff;
        font-size: 14px;
        line-height: 30px;
        color: #ffffff;
        border: none;
        cursor: pointer;
      }
    }
  }
  .hand__order{
    .order__title{
      font-size: 20px;
      letter-spacing: 0.8px;
      text-align: left;
      color: #333333;
    }
    .invite__list{
      margin-left: 30px;
      margin-top: 20px;
    }
    .invite__li{
      font-size: 14px;
      padding-bottom: 25px;
      color: #666666;
    }
    .invite__btn{
      width: 60px;
      height: 26px;
      border-radius: 4px;
      background-color: #ffffff;
      border: solid 1px #ffa72e;
      font-size: 14px;
      letter-spacing: 0.6px;
      color: #ffa01f;
      margin-left: 10px;
    }
  }
  .sms__nav{
    margin-top: 41px;
  }
}