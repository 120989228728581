.logic {
  &__list {}

  &__item {
    background-color: #f4f6f7;
    margin-top: 25px;
    color: #666666;
    &:first-child {
      margin-top: 10px;
    }
    .Select{
      &-placeholder{
        color: #888888 !important;
      }
      &-value-label{
        color: #888888 !important;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    height: 34px;
    background-color: #e5e8e9;
    padding: 0 20px;
    position: relative;
    color: #666666;
  }
  &__help {
    margin: 2px 10px 0 10px;
    &--wrap {
      position: relative;
      &:hover {
        .logic__help--text {
          display: block;
        }
      }
    }
    &--text {
      display: none;
      position: absolute;
      left: 39px;
      top: -50%;
      transform: translate(0px, -8px);
      width: 248px;
      background-color: #4C4C4C;
      color: white;
      font-size: 12px;
      line-height: 20px;
      padding: 8px 12px;
      border-radius: 2px;
      z-index: 100;
      &:before {
        display: block;
        content: "";
        width: 9px;
        height: 16px;
        position: absolute;
        left: -8px;
        top: 18px;
        background: url("assets/images/icons/logic/tips-arrow.svg") center center no-repeat;
      }
    }
  }
  &__toolbar {
    position: absolute;
    right: 20px;
    display: flex;
    width: 200px;
    justify-content: space-between;
    align-items: center;
  }
  &__mutex {
    border: solid 1px #e5e7e9;
    padding: 3px 5px 3px 5px;
    min-height: 28px;
    width: 200px;
    position: relative;
    color: #666666;
    z-index: 1;
    word-break: break-all;
    margin-left: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    img{
      width: 20px;
      height: 20px;
    }
  }

  &__main {
    padding: 15px 50px;
  }
  &__block {
    &:not(:last-child) {
      border-bottom: 1px solid #C5C9CB;
    }
    &--top{
      padding-bottom: 10px;
    }
    &--bottom{
      padding-top: 10px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    .logic__btn--mapping{
      width: 85px;
      line-height: 28px;
      padding: 0 0;
    }
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  &__read{
    display: flex;
    align-items: center;
    &--text{
      margin-left: 5px;
    }
  }

  &__c {
    &--item {
      margin-right: 10px;
    }
    &--disc {
      color: darkorange;
    }
  }
  &__c1 {
    width: 70px;
    display: flex;
    justify-content: flex-end;
  }
  &__c2 {
    width: 950px;
    margin: 0 15px 0 10px;
    display: flex;
    align-items: center;
    &--variable{
      width: 330px !important;
    }
    &--condition{
      width: 830px !important;
    }
    .variable__input{
      width: 330px;
      height: 27px;
      color: #666666;
      border: 1px solid #ccc;
      padding-left: 5px;
    }
    .logic {
      &__c21 {
        width: 480px;
      }
      &__c22 {
        width: 280px;
      }
      &__select--many{
        .Select--multi{
          .Select-value{
            max-height: 22px;
            width: 115px;
            overflow: hidden;
            margin-bottom: 2px;
            margin-top: 2px !important;
            background: #ffffff;
            display: flex;
            float: left;
            &-label{
            }
          }
        }

      }
      &__c23 {
        width: 120px;
      }
    }
  }
  &__c3 {
    flex: 1;
    display: flex;
    .icon {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}