/** 问卷答题页面 */
.p-answer {
  &--wrap {
    background-color: #dfe0e2;
    padding: 75px 0;
    height: 100vh;
    .data__manage--row{
      color: #666666;
      .data__info{
        position: relative;
        padding: 20px 20px;
        background: #ffffff;
        &--title{
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 10px;
        }
        &--list{
          margin-top: 10px;
          display: flex;
          .lebel__name{
            width: 75px;
            display: block;
          }
        }
        &--height{
          height: 200px;
          overflow: hidden;
          padding-bottom: 20px;
        }
        .icon__hide{
          position: absolute;
          bottom: 0px;
          left: 0px;
          right: 0px;
          margin: auto;
          z-index: 100;
        }
      }
      .data__manage{
        background: #ffffff;
        padding: 10px 20px;
        margin-top: 20px;
        &--top{
          display: flex;
          .manage__select{
            width: 100px;
            margin-left: 20px;
          }
          .data__time{
            margin-left: 20px;
          }
          .manage__upload{
            justify-content: flex-end;
          }
        }
        &--left{
          display: flex;
          flex: 1;
        }
        .manage__table{
          tr{
            line-height: 30px;
          }
          td,th{
            text-align: center;
          }
          .td__first{
            text-align: left;
          }
        }
      }
      .manage__tab{
        width: 100%;
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        margin: auto;
        .tab__item{
          padding: 10px 20px;
          border-top: 4px solid #c0c0c0;
          margin: 30px;
        }
        .active{
          border-top: 4px solid #40b5ff;
          color: #40b5ff;
        }
      }
    }
  }
  &--pc {
    max-width: 1000px;
    margin: 0 auto;
    background: white;
    padding: 52px 116px;
  }
}
.check_anser{
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 58px;
  background: inherit;
  background-color: rgba(42, 112, 243, 1);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px;
  .check_anser_box{
    font-size: 16px;
    margin-left: 30px;
    a{
      color: #ffffff;
    }
    .return-qtn1{
      background: url("assets/images/icons/return-qtn.svg") center center /contain no-repeat;
      width: 17px;
      height: 17px;
      top: 5px;
      left: -25px;
      bottom: 0px;
      margin: auto;
      background-size: 17px;
      background-position: 0 4px;
    }
  }
.check_anser_div{
  width:300px;
  margin:0 auto;
  text-align: center;
  .check_anser_span{
    font-size:17px;
    color:#fff;
    margin-right:20px;
  }
}
}
.check_anser_footer{
  margin:0 auto;
  width: 100%;
  height: 42px;
  line-height:42px;
  background: inherit;
  background-color: rgba(242, 242, 242, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(215, 215, 215, 1);
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  .check_anser_allbtn{
    width:400px;
    margin:2px auto;
    display:flex;
    justify-content:space-around;
    .check_anser_btn{
      width: 81px;
      height: 35px;
      background: inherit;
      background-color: rgba(18, 150, 219, 1);
      border: none;
      border-radius: 5px;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 14px;
      color: #FFFFFF;
      margin-right:20px;
      text-align:center;
    }
  }

}
.check_anser_midder{
  height:558px;
  overflow: auto;
}

