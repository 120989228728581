.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.lottery-prize{
  .demo {
    table{
      td{
        width: 14rem;
        height: 14rem;
        text-align: center;
        vertical-align: middle;
        font-size: 2.4rem;
        color: #333;
        font-index: -999;
      }
    }
  }

  .draw {
    button {
      border: none;
      display: block;
      width: 83px;
      height: 83px;
      border-radius: 12px;
      font-size: 17px;
      font-weight: 600;
      color: #f0ff00;
      background-color: #fe4135;
      cursor: pointer;
    }
  }

  .active__prize {
    width: 85px;
    height: 85px;
    background: url(assets/images/icons/data/bg2.png) center center/contain no-repeat;
    text-align: center;
  }

  .hidden {
    width: 85px;
    height: 85px;
    background: url(assets/images/icons/data/bg1.png) center center/contain no-repeat;
    text-align: center;
  }

  .scroll_div {
    height: 2rem;
    overflow: hidden;
    white-space: nowrap;
    width: 54rem;
    margin-left: 10px;
    background-color: #a9a9a9;
    border-radius: 0.4rem;
    position: absolute;
    left: 25rem;
    top: 50rem;
    color: #fff;
  }

  .scroll_div{
    span {
      padding-left: 2rem;
    }
  }

  .scroll_div span i {
    color: #f08080;
  }

  .scroll_begin,
  .scroll_end {
    display: inline;
  }

  .demo {
    width: 40rem;
    height: 40rem;
    margin: 2rem auto 0;
    background-size: cover;
  }
}

.lottery{
  width: 320px;
  height: 326px;
  margin: auto;
  background: url(assets/images/icons/data/bg3.png) center center/contain no-repeat;
  padding: 28px;
  margin-top: 10px;
}



