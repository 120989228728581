$preview_main_height: 556px;

.p-questionnaire {
  margin: 0 auto;
  background-color: white;
  position: relative;
  min-height: 100vh;
  padding-bottom: 30px;
  .qtn {
    &__header {
      position: relative;
      min-height: 30px;
      background-color: #999999;
      line-height: 30px;
      &--text{
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        margin: auto;
      }
    }
  }
  .answer__header--mobile{
    background-color: #ffea7a;
    color: #ff5f0a;
    font-size: 14px;
    text-align: center;
    line-height: 25px;
  }
  .answer {
    &__header {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      color: white;
      background: #2a70f3;
      &--moblie{
        background-color: #ffea7a;
      }
    }
    &__progress {
      height: 6px;
      background-color: #b6c1d6;
      &--current {
        height: 6px;
        background-color: #3ec1ff;
      }
    }
    &__footer {
      height: 50px;
      background-color: transparent;
      color: #666666;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      &--fixed {
        position: absolute;
        bottom: 0px;
        width: 100%;
        left: 0px;
        margin-bottom: 0px;
      }
    }
  }
  &--simulation {
    border: 1px solid #c0c0c0;
    // iphone 6
    height: $preview_main_height;
    width: 320px;
    overflow-y: scroll;
    position: relative;
    min-height: 0px;
  }
  &--pc {
    height: auto;
    .answer {
      &__header {
        background: none;
        color: black;
      }
      &__main {
        min-height: 720px;
      }
    }
  }
}